import React from 'react'
import { useParams } from 'react-router-dom'
import BabyForm from '../../container/baby/BabyForm'

const AddBaby = () => {
    const { id } = useParams()
    return (
        <div><BabyForm id={id} /></div>
    )
}

export default AddBaby
