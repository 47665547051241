// import { ADToBS } from 'bikram-sambat-js';
// import { isAfter } from 'date-fns';
import * as yup from 'yup';

let UserValidationSchema = yup.object().shape({
  Status: yup.boolean(),
  FullName: yup.string().max(20, 'Must be 20 characters or less').required('Required !'),
  Email: yup.string().email('Invalid email address').required('Required !'),
  UserName: yup.string().max(20, 'Must be 20 characters or less').required('Required !'),
  UserPass: yup.string().required('Required !'),
  AliasName: yup.string().max(20, 'Must be 20 characters or less'),
  Gender: yup.string().oneOf(
    ['Male', 'Female',],
    'Invalid  Gender'
  ).required('Required !'),
  // DOB: yup
  // .date()
  // .max(ADToBS(new Date()), 'Date must be in the past!')
  // .transform((value, originalValue) => {
  //   if (originalValue) {
  //     console.log("originalvalue",originalValue)
  //     console.log("first",ADToBS(new Date()))
  //     if (isAfter(originalValue, ADToBS(new Date()))) {
  //       return ADToBS(new Date()); // Replace with desired behavior, e.g. throw error or set default
  //     }
  //   }
  //   return value;
  // }),   
  Cellphone: yup.string(),
  Phone: yup.string(),
  Address: yup.string(),
  UserType: yup.string().required('Required !'),
  Role: yup.string().required('Required !'),
  ReferralUser: yup.string(),
  Remarks: yup.string(),
});


export default UserValidationSchema