import { ErrorMessage, Field } from "formik";
import React from "react";
import { TextError } from "../TextError";
import { useTranslation } from "react-i18next";

const FormikRadioField = ({ label, name, options, formik }) => {
  const { t } = useTranslation();

  return (
    <div className="mb-6">
      <div className="block mb-2 text-sm font-medium dark:text-gray-400">
        {t(label)}
      </div>
      {options?.map((item, index) => (
        <label key={`${item.label}-${index}`} className="mr-2">
          <Field type="radio" name={name} value={item.value} />
          <span className="ml-2 dark:text-gray-400">{t(item.label)}</span>
        </label>
      ))}
      <ErrorMessage name={name} component={TextError} />
    </div>
  );
};

export default FormikRadioField;
