import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import APIServices from "../../../httpServices/httpServices";
import HealthCenterForm from "../../container/healthCenter/HealthCenterForm";

const EditHealthCenter = () => {
  // The ID you get here is SLUG
  const { id } = useParams();
  const [healthCenterDataToEdit, setHealthCenterDataToEdit] = useState([]);
  useEffect(() => {
    if (id) getHealthCenterDetail();
    //eslint-disable-next-line
  }, [id]);

  const getHealthCenterDetail = async () => {
    const { data, success } = await new APIServices(
      `health-center/${id}`
    ).get();
    if (success) {
      let payload = {
        ...data,
        Status: data.Status === "Active" ? true : false,
        IsBirthingCenter: data.IsBirthingCenter === "Yes" ? true : false,
        IsEmergencyService: data.IsEmergencyService === "Yes" ? true : false,
      };
      setHealthCenterDataToEdit({ ...payload });
    }
  };

  return (
    <>
      <HealthCenterForm data={healthCenterDataToEdit} id={id} />
    </>
  );
};

export default EditHealthCenter;
