import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import FormikTextField from '../../common/form/FormikTextField';


const MobileModal = ({ data,close, handleMobile }) => {
    // eslint-disable-next-line
    const [initialValues, setInitialValues] = useState({
        Phone: "",
        Cellphone: "",
    })
    useEffect(() => {
        if (data)
            setInitialValues((prev) => ({
                ...prev,
                ...data,
            }));
    }, [data]);

    return (
        <>
            <Formik
                initialValues={initialValues}
                onSubmit={handleMobile}
                enableReinitialize
            >
                {
                    formik => (
                        <Form>
                            <div className='fixed inset-0 bg-gray-800/80 z-[98]' onClick={() => close(false)}>
                            </div>
                            <div id="popup-modal" className="fixed top-1/2 z-[99] -translate-x-1/2 -translate-y-1/2 max-w-2xl mx-auto
                             w-full left-1/2 z-50 p-4 overflow-x-hidden overflow-y-auto">
                                <div className="relative w-full h-full md:h-auto">
                                    <div className="relative bg-white p-6 rounded-lg shadow dark:bg-gray-700">
                                        <div className="flex items-center justify-between gap-x-4">
                                            <h2 className="text-lg font-bold"> Update Phone & Cellphone </h2>
                                            <button onClick={() => close(false)} className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white" data-modal-toggle="popup-modal">
                                                <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                                <span className="sr-only">Close modal</span>
                                            </button>
                                        </div>
                                        <FormikTextField label="Phone" name='Phone' type="number" formik={formik} />
                                        <FormikTextField label="Cellphone" name='Cellphone' type="number" formik={formik} />

                                        {/* Submit */}
                                        <div className="mt-4 modal-footer">
                                            <button type="submit" className="px-4 py-2 mr-2 text-xs text-white bg-green-600 rounded" id="save" > Update </button>
                                            <button className="px-4 py-2 text-xs text-white bg-red-600 rounded" onClick={() => close(false)}>Cancel </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </Form>
                    )
                }
            </Formik>
        </>

    )
}

export default MobileModal