import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import slugify from "slugify";
import APIServices from "../../../httpServices/httpServices";
import FormikImageField from "../../common/form/FormikImageField";
import FormikOnChangeField from "../../common/form/FormikOnChangeField";
import FormikSelectSingleField from "../../common/form/FormikSelectSingleField";
import FormikSwitchField from "../../common/form/FormikSwitchField";
import FormikTextAreaField from "../../common/form/FormikTextAreaField";
import FormikTextField from "../../common/form/FormikTextField";
import {
  emitErrorToast,
  emitSuccessToast,
} from "../../common/toast/ToastContainer";
import HealthCentreValidationSchema from "../../../validation/HealthCenterValidationSchema";
// import FarmerValidationSchema from "../../../validation/FarmersValidationSchema";

const HealthCenterForm = ({ data, id }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [provinceOptions, setProvinceOptions] = useState([]);
  const [districtOptions, setDistrictOptions] = useState([]);
  const [localBodiesOptions, setLocalBodiesOptionsptions] = useState([]);
  const [initialValues, setInitialValues] = useState({
    Name: "",
    DevnagariName: "",
    Slug: "",
    Country: "Nepal",
    Province: "",
    District: "",
    LocalBodies: "",
    WardNo: "",
    Tole: "",
    Address: "",
    Latitude: "",
    Longitude: "",
    Status: true,
    Image: "",
    MainPerson: {
      FullName: "",
      Image: "",
      Email: "",
      Phone: "",
      Address: ""
    },
    ResponsiblePerson: {
      FullName: "",
      Image: "",
      Email: "",
      Phone: "",
      Address: ""
    },
    IsBirthingCenter: false,
    BedCapacity: 0,
    BirthCenterCapacity: 0,
    IsEmergencyService: false,
    Detail: "",
    ContactNo: "",
    // Services: [],
    // Speciality: [],
    SeoTitle: "",
    SeoDescription: ""
  });

  useEffect(() => {
    if (data) {
      getDistrictByProvince(data.Province);
      getLocalBodiesByDistrict(data.District);
    }
  }, [data]);

  useEffect(() => {
    getFormSelectFieldValues();
  }, []);

  useEffect(() => {
    if (data)
      setInitialValues((prev) => ({
        ...prev,
        ...data,
      }));
  }, [data]);

  const handleFormikFormSubmit = async (values, actions) => {
    // let bool = await validateSlug(values?.Slug);
    let { setSubmitting, resetForm } = actions;
    let payload = {
      ...values,
      Status: values.Status ? "Active" : "Inactive",
      IsBirthingCenter: values.IsBirthingCenter ? "Yes" : "No",
      IsEmergencyService: values.IsEmergencyService ? "Yes" : "No",
    };
    const { MainPerson, ResponsiblePerson, ...rest } = payload;
    let formData = new FormData();
    formData.append("MainPerson", JSON.stringify(MainPerson));
    formData.append("ResponsiblePerson", JSON.stringify(ResponsiblePerson));

    // Append image files
    formData.append('MainPerson.Image', MainPerson.Image);
    formData.append('ResponsiblePerson.Image', ResponsiblePerson.Image);
    Object.keys(rest).forEach((key, index) => {
      formData.append(key, rest[key]);
    });
    const { success, data, message } = !!!id
      ? await new APIServices(`health-center`).post(formData)
      : await new APIServices(`health-center/${id}`).put(formData);
    if (success) {
      setSubmitting(false);
      resetForm();
      emitSuccessToast(message);
      navigate(`/health-center/view/${data._id}`, { replace: true });
    } else {
      emitErrorToast(message);
    }
  };

  const getFormSelectFieldValues = async () => {
    const [provinceObj] = await Promise.all([
      new APIServices("province/list").post({}),
    ]);
    if (provinceObj.success) {
      let provinceData = provinceObj.data.map((item) => ({
        value: item.Name,
        label: item.Name,
      }));
      setProvinceOptions(provinceData);
    }
  };

  // District Options By Selected Province
  const getDistrictByProvince = async (Province) => {
    const { data, success } = await new APIServices(
      `health-center/get/districts?Province=${Province}`).get()

    if (success) {
      let districtOptions = data.map((item) => ({
        value: item,
        label: item,
      }))
      setDistrictOptions(districtOptions);
    }
  };

  // District Options By Selected Province
  const getLocalBodiesByDistrict = async (District) => {
    const { data, success } = await new APIServices(
      `health-center/get/localbodies?District=${District}`
    ).get()
    if (success) {
      let localbodiesOptions = data.map((item) => ({
        value: item,
        label: item,
      }));
      setLocalBodiesOptionsptions(localbodiesOptions);
    }
  };

  const handleSelectSingle = (selectedOption, fieldName, setFieldValue) => {
    setFieldValue(fieldName, selectedOption.value);
    getDistrictByProvince(selectedOption.value);
  };

  const handleSelectSingleDistrict = (
    selectedOption,
    fieldName,
    setFieldValue
  ) => {
    setFieldValue(fieldName, selectedOption.value);
    getLocalBodiesByDistrict(selectedOption.value);
  };
  const handleSelectSingleLocalBody = (
    selectedOption,
    fieldName,
    setFieldValue
  ) => {
    setFieldValue(fieldName, selectedOption.value);
  };

  const handleNameChange = (value, form) => {
    form.setFieldValue("Name", value);
    form.setFieldValue(
      "Slug",
      slugify(value, { replacement: "_", lower: true })
    );
  };

  return (
    <>
      <section className="py-16 bg-gray-100 dark:bg-gray-800">
        <div className="max-w-4xl px-4 mx-auto ">
          <Formik
            initialValues={initialValues}
            validationSchema={HealthCentreValidationSchema}
            onSubmit={handleFormikFormSubmit}
            enableReinitialize
          >
            {(formik) => (
              <Form>
                <div className="">
                  <h3 className="mb-2 text-base font-bold leading-6 text-gray-900 dark:text-gray-300">
                    {t("Health Center's Information")}
                  </h3>
                  <div className="p-6 mb-4 bg-white rounded-md shadow dark:border-gray-800 dark:bg-gray-900">

                    <FormikOnChangeField
                      label="Name *"
                      name="Name"
                      placeholder="Health Center Name"
                      handleChange={handleNameChange}
                      formik={formik}
                    />

                    <FormikTextField
                      label="Slug"
                      name="Slug"
                      formik={formik}
                      disabled
                    />

                    <FormikTextField
                      label="Devnagari Name"
                      name="DevnagariName"
                      formik={formik}
                    />

                    <div className="">
                      <FormikImageField label={t("Hospital Image")} name="Image" />
                    </div>
                    <FormikTextField
                      label="Contact No. *"
                      name="ContactNo"
                      type="number"
                      formik={formik}
                    />
                    <div className="grid w-full gap-6 mb-6 lg:grid-cols-3">
                      <FormikTextField
                        label="Country"
                        name="Country"
                        readOnly={true}
                        formik={formik}
                      />
                      <FormikSelectSingleField
                        label="Province"
                        name="Province"
                        options={provinceOptions}
                        handleSelectSingle={handleSelectSingle}
                      />
                      <FormikSelectSingleField
                        label="District"
                        name="District"
                        options={districtOptions}
                        handleSelectSingle={handleSelectSingleDistrict}
                      />
                    </div>
                    <div className="grid w-full gap-6 mb-6 lg:grid-cols-3">
                      <FormikSelectSingleField
                        label="Local Bodies"
                        name="LocalBodies"
                        options={localBodiesOptions}
                        handleSelectSingle={handleSelectSingleLocalBody}
                      />
                      <FormikTextField
                        label="Ward No."
                        name="WardNo"
                        type="number"
                        formik={formik}
                      />
                      <FormikTextField
                        label="Tole"
                        name="Tole"
                        formik={formik}
                      />
                    </div>
                    <div className="mb-6">
                      <FormikTextField
                        label="Address"
                        name="Address"
                        formik={formik}
                      />
                    </div>
                    <div className="grid w-full gap-4 mb-6 lg:grid-cols-2">
                      <FormikTextField
                        label="Latitude"
                        name="Latitude"
                        formik={formik}
                      />
                      <FormikTextField
                        label="Longitude"
                        name="Longitude"
                        formik={formik}
                      />
                    </div>
                    <FormikTextAreaField label={t("Detail")} name="Detail" formik={formik} />

                    <FormikTextField
                      type="number"
                      label={t("Bed Capacity")}
                      name="BedCapacity"
                      formik={formik}
                    />
                    <div className="grid w-full gap-4 mb-6 lg:grid-cols-3">
                      <FormikSwitchField
                        label={t("Birthing Center")}
                        name="IsBirthingCenter"
                      />
                      {formik.values?.IsBirthingCenter && (
                        <FormikTextField
                          type="number"
                          label="Birth Center Capacity"
                          name="BirthCenterCapacity"
                          formik={formik}
                        />
                      )}
                      <FormikSwitchField
                        label={t("Emergency Service")}
                        name="IsEmergencyService"
                      />
                    </div>
                    <FormikSwitchField label={t("Status")} name="Status" />
                  </div>

                  {/* Main Person  */}
                  <h3 className="mb-2 text-base font-bold leading-6 text-gray-900 dark:text-gray-300"> {t("Main Person Detail")}
                  </h3>
                  <div className="p-6 mb-4 bg-white rounded-md shadow">
                    <FormikTextField
                      label={t("Full Name ")}
                      name="MainPerson.FullName"
                      formik={formik} />
                    <FormikTextField
                      label="Email"
                      name="MainPerson.Email"
                      formik={formik} />

                    <div className="">
                      <FormikImageField label={t("Main Person Image")} name="MainPerson.Image" />
                    </div>
                    <FormikTextField
                      label={t("Phone")}
                      name="MainPerson.Phone"
                      formik={formik} />
                    <FormikTextField
                      label="Address"
                      name="MainPerson.Address"
                      formik={formik} />

                  </div>

                  {/* Main Person  */}
                  <h3 className="mb-2 text-base font-bold leading-6 text-gray-900 dark:text-gray-300"> {t("Responsible Person Detail")}
                  </h3>
                  <div className="p-6 mb-4 bg-white rounded-md shadow">
                    <FormikTextField
                      label="Full Name "
                      name="ResponsiblePerson.FullName"
                      formik={formik} />
                    <FormikTextField
                      label="Email"
                      name="ResponsiblePerson.Email"
                      formik={formik} />

                    <div className="">
                      <FormikImageField label="Main Person Image" name="ResponsiblePerson.Image" />
                    </div>
                    <FormikTextField
                      label="Phone"
                      name="ResponsiblePerson.Phone"
                      formik={formik} />
                    <FormikTextField
                      label="Address"
                      name="ResponsiblePerson.Address"
                      formik={formik} />

                  </div>

                  {/* SEO AND SEO DESCRIPTION  */}
                  <h3 className="mb-2 text-base font-bold leading-6 text-gray-900 dark:text-gray-300"> {t("SEO Section")}
                  </h3>
                  <div className="p-6 mb-4 bg-white rounded-md shadow">
                    <div className="mb-6">
                      <FormikTextField label="Seo Title" name="SeoTitle" formik={formik} />
                    </div>
                    <div className="">
                      <FormikTextAreaField label="Seo Description" name="SeoDescription" formik={formik} />
                    </div>
                  </div>

                  {!!!data && (
                    <>

                      <h3 className="mb-2 text-base font-bold leading-6 text-gray-900 dark:text-gray-300"> {t("Login User Credential")}
                      </h3>
                      <div className="p-6 mb-4 bg-white rounded-md shadow">
                        <FormikTextField
                          label="User Name"
                          name="UserName"
                          formik={formik}
                        />
                        <FormikTextField
                          label="Password"
                          name="UserPass"
                          type="password"
                          formik={formik}
                        />
                      </div>
                    </>
                  )}
                </div>

                {/* Submit */}
                <div className="mt-7">
                  <div className="flex justify-start space-x-2">
                    <button
                      type="submit"
                      className="inline-block px-6 py-2.5 bg-blue-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-600"
                    >
                      {t("Save")}
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </section>
    </>
  );
};

export default HealthCenterForm;
