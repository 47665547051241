import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import APIServices from '../../../httpServices/httpServices';
import AppUserForm from '../../container/appuser/AppUserForm';
    const EditAppUser = () => {
    const { id } = useParams()
    const [dataToEdit, setDataToEdit] = useState([])
    useEffect(() => {
        if (id)
            getDetail()
        // eslint-disable-next-line
    }, [])

    const getDetail = async () => {
        const { data, success } = await new APIServices(`appuser/${id}`).get()
        if (success) {
            let payload = {
                ...data?.appUsers,
                Status: data?.appUsers.Status === "Active" ? true : false,
                DOB: moment(data?.appUsers.DOB).format('YYYY-MM-DD'),
                MoodReminderIn: moment(data?.appUsers.MoodReminderIn).format('YYYY-MM-DD'),
                
            }
            
            setDataToEdit({ ...payload})
        }
    }


    return (
        <>
            <AppUserForm data={dataToEdit} id={id} />
        </>
    )
}

export default EditAppUser