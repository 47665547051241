import React from 'react'
import TagForm from '../../container/tag/TagForm'

const AddTag = () => {
    return (
        <div><TagForm /></div>
    )
}

export default AddTag
