import React from 'react'
import PregnancyWeekForm from '../../container/pregnancyweek/PregnancyWeekForm'

const AddPregnancyWeek = () => {

    return (
        <div><PregnancyWeekForm /></div>
    )
}

export default AddPregnancyWeek