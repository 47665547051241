import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import APIServices from '../../../httpServices/httpServices';
import { emitSuccessToast } from '../../common/toast/ToastContainer';
import DOMPurify from 'dompurify'
import DeleteModal from '../../common/modals/DeleteModal';
import CustomImage from '../../common/CustomImage';
import { useTranslation } from 'react-i18next';

const ViewTagType = () => {
    const {t}= useTranslation();
    const navigate = useNavigate();
    const { id } = useParams();
    const [itemDetails, setItemDetails] = useState("");
    const [showModal, setShowModal] = useState(false)

    useEffect(() => {
        if (id) {
            getItemDetail()
        }
        //eslint-disable-next-line 
    }, [])


    // Fetch User Data 
    const getItemDetail = async () => {
        const { data} = await new APIServices(`tagtype/${id}`).get()
        if (data) {
            setItemDetails(data)
        }
    }

    const handleShowDeleteModal = () => {
        setShowModal(true)
    }
    const handleEdit = (id) => {
        navigate(`/tag-type/edit/${id}`);
    }

    const handleDelete = async () => {
        const { message, success } = await new APIServices(`tagtype/${id}`).delete()
        if (success) {
            setShowModal(false)
            emitSuccessToast(message)
            navigate("/tag-type");
        }
    }

    return (
        <section className="pb-16 pt-8 bg-white dark:bg-gray-800 ">
            <div className="max-w-4xl px-4 mx-auto">
               

                <h2 className="text-2xl font-semibold mb-1 font-poppins dark:text-gray-300">{itemDetails?.Title}  <button
            onClick={() => handleEdit(id)}
            className="font-medium text-blue-600 hover:text-blue-500 dark:hover:text-gray-300 dark:text-blue-300 ml-2 ">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
            fill="currentColor" className="w-4 h-4 mr-2 bi bi-pencil-square"
            viewBox="0 0 16 16">
            <path
                d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
            <path fillRule="evenodd"
                d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                 </svg>
            </button></h2>
                <br></br>
                <CustomImage alt="" source={itemDetails?.Image ? itemDetails?.Image : ""} className="object-cover w-full h-96" />
                <div className="pt-5 pb-5 dark:text-gray-400 " dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(itemDetails.Detail) }} />
                {
                    itemDetails.Status === "Inactive" && <button onClick={handleShowDeleteModal} className="inline-block flex items-center px-4 py-2.5 bg-red-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-red-600 mb-5">
                        {t("Delete")}
                    </button>
                }
            </div>

           
            {
                showModal && <DeleteModal handleDelete={handleDelete} close={setShowModal} />
            }
        </section>
    )
}

export default ViewTagType