import React from "react";
import HealthCenterForm from "../../container/healthCenter/HealthCenterForm";

const AddHealthCenter = () => {
  return (
    <div>
      <HealthCenterForm />
    </div>
  );
};

export default AddHealthCenter;
