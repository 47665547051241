import DOMPurify from 'dompurify'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'

import { useSelector } from 'react-redux'

export const NavLink = ({ path, title, svg }) => {
    const { pathname } = useLocation()
    return (
        <li>
            <Link
                to={path}
                className={`${pathname.split("/")[1] === path ? "bg-gray-200" : ""} flex items-center px-6 py-4 text-gray-700 dark:text-gray-400 dark:hover:bg-gray-800 group hover:text-gray-600 hover:bg-gray-200`} >
                <span className="drop-shadow-lg mr-2 flex h-8 w-8 items-center 
                                    justify-center rounded-lg bg-white dark:bg-gray-800 dark:text-gray-100 bg-center 
                                    text-center xl:p-2.5" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(svg) }} />
                <span> {title} </span>
            </Link>
        </li >
    )
}

const HealthCenterSideNav = ({ openSideNav }) => {
    const { profile } = useSelector((state) => state.user)
    const {t} = useTranslation();
    
    return (
        <div className="relative lg:block navbar-menu">
            <nav className={`${openSideNav ? 'w-0' : 'w-[280px] hidden'}  fixed top-0  transition-all lg:mt-0 mt-16 left-0 dark:bg-gray-900 bottom-0 flex flex-col w-[280px] shadow bg-white overflow-hidden z-50`} id="sidenav">
                <div className="flex items-center w-full px-4 pt-4 pb-4 border-b border-gray-300 dark:border-gray-700">
                    <a href=" ">
                        <h2 className="ml-3 text-lg font-bold text-gray-700 dark:text-gray-400 whitespace-nowrap"> HajarDin </h2>
                    </a>
                </div>
                <div className="pb-6 mt-4 overflow-x-hidden overflow-y-auto">
                    <ul className="mb-8 text-sm ">
                        <NavLink title={t("Dashboard")} path="/"
                            svg='<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-speedometer2" viewBox="0 0 16 16">
                            <path d="M8 4a.5.5 0 0 1 .5.5V6a.5.5 0 0 1-1 0V4.5A.5.5 0 0 1 8 4zM3.732 5.732a.5.5 0 0 1 .707 0l.915.914a.5.5 0 1 1-.708.708l-.914-.915a.5.5 0 0 1 0-.707zM2 10a.5.5 0 0 1 .5-.5h1.586a.5.5 0 0 1 0 1H2.5A.5.5 0 0 1 2 10zm9.5 0a.5.5 0 0 1 .5-.5h1.5a.5.5 0 0 1 0 1H12a.5.5 0 0 1-.5-.5zm.754-4.246a.389.389 0 0 0-.527-.02L7.547 9.31a.91.91 0 1 0 1.302 1.258l3.434-4.297a.389.389 0 0 0-.029-.518z"/>
                            <path fillRule="evenodd" d="M0 10a8 8 0 1 1 15.547 2.661c-.442 1.253-1.845 1.602-2.932 1.25C11.309 13.488 9.475 13 8 13c-1.474 0-3.31.488-4.615.911-1.087.352-2.49.003-2.932-1.25A7.988 7.988 0 0 1 0 10zm8-7a7 7 0 0 0-6.603 9.329c.203.575.923.876 1.68.63C4.397 12.533 6.358 12 8 12s3.604.532 4.923.96c.757.245 1.477-.056 1.68-.631A7 7 0 0 0 8 3z"/>
                          </svg>' />
                    <li className='group'>
                            <span
                                className="flex items-center px-6 py-4 text-gray-700 dark:text-gray-400 dark:hover:bg-gray-800  hover:text-gray-600 hover:bg-gray-100">
                                <span className="drop-shadow-lg mr-2 flex h-8 w-8 items-center 
                                    justify-center rounded-lg bg-white dark:bg-gray-800 dark:text-gray-100 bg-center 
                                    text-center xl:p-2.5">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-journals" viewBox="0 0 16 16">
                                        <path d="M5 0h8a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2 2 2 0 0 1-2 2H3a2 2 0 0 1-2-2h1a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1H1a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v9a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H5a1 1 0 0 0-1 1H3a2 2 0 0 1 2-2z" />
                                        <path d="M1 6v-.5a.5.5 0 0 1 1 0V6h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 3v-.5a.5.5 0 0 1 1 0V9h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 2.5v.5H.5a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1H2v-.5a.5.5 0 0 0-1 0z" />
                                    </svg>
                                </span>
                                <span> {t("Posts")} </span>
                                <span className="inline-block ml-auto sidenav-arrow">
                                    <svg className="w-3 h-3 dark:group-hover:text-gray-300" viewBox="0 0 10 6"
                                        fill="none" xmlns="http://www.w3.org/2000/svg"
                                        data-config-id="auto-svg-3-1">
                                        <path
                                            d="M9.08329 0.666626C8.74996 0.333293 8.24996 0.333293 7.91663 0.666626L4.99996 3.58329L2.08329 0.666626C1.74996 0.333293 1.24996 0.333293 0.916626 0.666626C0.583293 0.999959 0.583293 1.49996 0.916626 1.83329L4.41663 5.33329C4.58329 5.49996 4.74996 5.58329 4.99996 5.58329C5.24996 5.58329 5.41663 5.49996 5.58329 5.33329L9.08329 1.83329C9.41663 1.49996 9.41663 0.999959 9.08329 0.666626Z"
                                            fill="currentColor"></path>
                                    </svg>
                                </span>
                            </span>
                            <div className="pl-3 ml-3 transition border-gray-500 dropdown-section nested-menu opacity-0 group-hover:opacity-100 hidden group-hover:block">
                                <ul className="text-sm">
                                    <li>
                                        <Link to="post/news"
                                            className="flex items-center py-3 pl-3 pr-4 text-gray-700 rounded dark:text-gray-400 dark:hover:bg-gray-800 hover:bg-gray-100 ">
                                            <span className="text-gray-700 dark:text-gray-400">{t("News")}</span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="post/notice"
                                            className="flex items-center py-3 pl-3 pr-4 text-gray-700 rounded dark:text-gray-400 dark:hover:bg-gray-800 hover:bg-gray-100 ">
                                            <span className="text-gray-700 dark:text-gray-400">{t("Notice")}</span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="post/blog"
                                            className="flex items-center py-3 pl-3 pr-4 text-gray-700 rounded dark:text-gray-400 dark:hover:bg-gray-800 hover:bg-gray-100 ">
                                            <span className="text-gray-700 dark:text-gray-400">{t("Blog")}</span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="post/others-post"
                                            className="flex items-center py-3 pl-3 pr-4 text-gray-700 rounded dark:text-gray-400 dark:hover:bg-gray-800 hover:bg-gray-100 ">
                                            <span className="text-gray-700 dark:text-gray-400">{t("Others Post")}</span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="post/others-video"
                                            className="flex items-center py-3 pl-3 pr-4 text-gray-700 rounded dark:text-gray-400 dark:hover:bg-gray-800 hover:bg-gray-100 ">
                                            <span className="text-gray-700 dark:text-gray-400">{t("Others Video")}    </span>
                                        </Link>
                                    </li>

                                </ul>
                            </div>
                        </li>
                        <NavLink title={t("Pregnancy Week")} path="pregnancy-week"
                            svg='<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="w-5 h-5 bi bi-files" viewBox="0 0 16 16">
                            <path d="M13 0H6a2 2 0 0 0-2 2 2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h7a2 2 0 0 0 2-2 2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm0 13V4a2 2 0 0 0-2-2H5a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1zM3 4a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4z"/>
                          </svg>' />
                           <NavLink title={t("Health Centers")} path={`health-center/manager/${profile._id}`}
                            svg='<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="w-5 h-5 bi bi-files" viewBox="0 0 16 16">
                            <path d="M13 0H6a2 2 0 0 0-2 2 2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h7a2 2 0 0 0 2-2 2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm0 13V4a2 2 0 0 0-2-2H5a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1zM3 4a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4z"/>
                          </svg>' />
    

                    </ul>
                </div>
                
            </nav>
        </div>
    )
}



export default HealthCenterSideNav;