import React from 'react'
import { ToastContainer as ReactToastify, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const emitSuccessToast = (msg) => {
    toast.success(msg);
}

export const emitErrorToast = (msg) => {
    toast.error(msg);
}

export const emitWarnToast = (msg) => {
    toast.warn(msg);
}

export const emitInfoToast = (msg) => {
    toast.info(msg);
}





const ToastContainer = () => {

    return (
        <ReactToastify
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
        />
    )
}

export default ToastContainer