import { ErrorMessage, Field, Form, Formik } from 'formik';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import APIServices from '../../../httpServices/httpServices';
import UserValidationSchema from '../../../validation/UsersValidationSchema';
import { TextError } from '../../common/TextError';
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
import { ADToBS } from "bikram-sambat-js";
import { useTranslation } from 'react-i18next';
import FormikDateTimePickerField from '../../common/form/FormikDateTimePickerField';
import FormikImageField from '../../common/form/FormikImageField';
import { emitSuccessToast } from '../../common/toast/ToastContainer';

const UserForm = () => {
    const {t}= useTranslation();
    const { id } = useParams();
    const navigate = useNavigate()
    const [userType, setUserType] = useState(null)
    const [userRole, setUserRole] = useState(null)
    const [referralUser, setReferralUser] = useState(null)
    const [userDataToEdit, setUserDataToEdit] = useState({ FullName: '', Email: '', DOB: "", UserName: "", ProfileImage: "", AliasName: "", Gender: "Male", UserPass: "", Cellphone: "", Phone: "", Address: "", UserType: "", Role: "", ReferralUser: "", Remarks: "", Status: "" })

    useEffect(() => {
        if (id)
            getUserDetail()
        getFormSelectFieldValues()
        // eslint-disable-next-line
    }, [])


    const getFormSelectFieldValues = async () => {
        const [userTypeObj, userRoleObj, referralUserObj] = await Promise.all([
            new APIServices("setting/get/user-type").get(),
            new APIServices("role/list").post({}),
            new APIServices("user/list").post({})
        ]);
        if (userTypeObj.success) {
            setUserType(JSON.parse(userTypeObj.data?.SettingValue))
        }
        if (userRoleObj.success) {
            setUserRole(userRoleObj.data)
        }

        if (referralUserObj.success) {
            setReferralUser(referralUserObj.data)
        }
    }

    const getUserDetail = async () => {
        const { data } = await new APIServices(`user/${id}`).get()
        if (data) {
            setUserDataToEdit({
                FullName: data?.FullName || '',
                Email: data.Email || '',
                DOB: ADToBS(moment(data.DOB).format("YYYY-MM-DD")) || "",
                UserName: data.UserName || "",
                ProfileImage: data?.ProfileImage || "",
                AliasName: data.AliasName || "",
                Gender: data.Gender || "",
                UserPass: data.UserPass || "",
                Cellphone: data.Cellphone || "",
                Phone: data.Phone || "",
                Address: data.Address || "",
                UserType: data.UserName || "",
                Role: data.Role || "",
                ReferralUser: data.ReferralUser || "",
                Remarks: data.Remarks || "",
                Status: data.Status === "Active" ? true : false
            })
        }
    }

    const handleFormikFormSubmit = async (values, actions) => {
        let { setSubmitting, resetForm } = actions
        let payload = {
            ...values,
            Status: values.Status ? "Active" : "Inactive"
        }

        let formData = new FormData();
        Object.keys(payload).forEach((key, index) => {
            formData.append(key, payload[key]);
        });

        // if id then update else create new 
        const { success, data, message } = !!id ? await new APIServices(`user/${id}`).put(formData) : await new APIServices("user").post(formData)
        if (success) {
            setSubmitting(false);
            resetForm();
            emitSuccessToast(message)
            navigate(`/users/view/${data._id}`, { replace: true })

        }
    }


    return (
        <section className="py-16 bg-gray-100 dark:bg-gray-800">
            <div className="max-w-4xl px-4 mx-auto ">
                <div className="p-6 bg-white rounded-md shadow dark:border-gray-800 dark:bg-gray-900">
                    <h2 className="mb-6 text-xl font-medium leading-6 text-gray-900 dark:text-gray-300">{t("User's Information")}
                    </h2>
                    <Formik
                        initialValues={userDataToEdit}
                        validationSchema={UserValidationSchema}
                        onSubmit={handleFormikFormSubmit}
                        enableReinitialize
                    >

                        {
                            formik => (
                                <Form>
                                    <div className="mb-6">
                                        <label htmlFor='toggleB' className="block items-center cursor-pointer">
                                            <div className='mb-2'>{t("Status")}</div>
                                            <div className="relative flex">
                                                <Field type="checkbox" id="toggleB" name="Status" className="sr-only" />
                                                <div className="block w-14 h-8 bg-gray-200 rounded-full"></div>
                                                <div className="absolute w-6 h-6 transition bg-gray-700 rounded-full dot left-1 top-1">
                                                </div>
                                            </div>
                                        </label>
                                    </div>


                                    {/* FullName */}
                                    <div className="mb-6">
                                        <label className="block mb-2 text-sm font-medium dark:text-gray-400" htmlFor="">
                                            {t("Full Name")} *
                                        </label>
                                        <Field name="FullName" type="text" placeholder={t("Enter a full name")} className={`block w-full px-4 py-3 mb-2 text-sm placeholder-gray-500 bg-white border rounded dark:text-gray-400 dark:placeholder-gray-500 dark:border-gray-800 dark:bg-gray-800 ${formik.touched.FullName && formik.errors.FullName ? "border-red-600" : null}`} />
                                        <ErrorMessage name="FullName" component={TextError} />
                                    </div>

                                    {/* Email and Date of birth */}
                                    <div className="grid w-full gap-4 mb-6 lg:grid-cols-2">
                                        <div>
                                            <label className="block mb-2 text-sm font-medium dark:text-gray-400" htmlFor="">
                                                {t("Email")} *
                                            </label>

                                            <Field type="email" name="Email" placeholder={t("Enter a email")} className={`block w-full px-4 py-3 mb-2 text-sm placeholder-gray-500 bg-white border rounded dark:text-gray-400 dark:placeholder-gray-500 dark:border-gray-800 dark:bg-gray-800 ${formik.touched.Email && formik.errors.Email ? "border-red-600" : null}`} />
                                            <ErrorMessage name="Email" component={TextError} />
                                        </div>
                                        <div>
                                            

                                            {/* <Field name="DOB" >
                                                {({ field, form, meta }) => {
                                                    return <DatePicker
                                                        name="DOB"
                                                        dateFormat="dd/MM/yyyy"
                                                        placeholderText="dd/mm/yyyy"
                                                        selected={(field.value && moment(field.value).toDate()) || null}
                                                        onChange={date => form.setFieldValue(field.name, date)}
                                                        onBlur={form.handleBlur}
                                                        className={`block w-full px-4 py-3 mb-2 text-sm placeholder-gray-500 bg-white border rounded dark:text-gray-400 dark:placeholder-gray-500 dark:border-gray-800 dark:bg-gray-800 ${meta.touched && meta.error ? "border-red-600" : null}`}
                                                    />
                                                }}
                                            </Field> */}
                                            <FormikDateTimePickerField
                                                label="Date of Birth *"
                                                name="DOB"
                                                formik={formik}
                                            />
                                        </div>
                                    </div>

                                    {/* UserName and Password */}
                                    {
                                        !!id ?
                                            (
                                                <div className="mb-6">
                                                    <label className="block mb-2 text-sm font-medium dark:text-gray-400" htmlFor="">
                                                        User Name *
                                                    </label>
                                                    <Field type="text" name="UserName" placeholder="Enter a Username" className={`block w-full px-4 py-3 mb-2 text-sm placeholder-gray-500 bg-white border rounded dark:text-gray-400 dark:placeholder-gray-500 dark:border-gray-800 dark:bg-gray-800 ${formik.touched.UserName && formik.errors.UserName ? "border-red-600" : null}`} />
                                                    <ErrorMessage name="UserName" component={TextError} />
                                                </div>) : (
                                                <div className="grid w-full gap-4 mb-6 lg:grid-cols-2">
                                                    <div>
                                                        <label className="block mb-2 text-sm font-medium dark:text-gray-400" htmlFor="">
                                                            {t("User Name")} *
                                                        </label>
                                                        <Field type="text" name="UserName" placeholder={t("Enter a Username")} className={`block w-full px-4 py-3 mb-2 text-sm placeholder-gray-500 bg-white border rounded dark:text-gray-400 dark:placeholder-gray-500 dark:border-gray-800 dark:bg-gray-800 ${formik.touched.UserName && formik.errors.UserName ? "border-red-600" : null}`} />
                                                        <ErrorMessage name="UserName" component={TextError} />
                                                    </div>
                                                    <div>
                                                        <label className="block mb-2 text-sm font-medium dark:text-gray-400" htmlFor="">
                                                            {t("Password")} *
                                                        </label>
                                                        <Field type="password" name="UserPass" placeholder={t("Enter a Password")} className={`block w-full px-4 py-3 mb-2 text-sm placeholder-gray-500 bg-white border rounded dark:text-gray-400 dark:placeholder-gray-500 dark:border-gray-800 dark:bg-gray-800 ${formik.touched.UserPass && formik.errors.UserPass ? "border-red-600" : null}`} />
                                                        <ErrorMessage name="UserPass" component={TextError} />
                                                    </div>
                                                </div>)
                                    }

                                    <div className="p-6 mb-4 bg-white rounded-md shadow">
                                        <FormikImageField label="Profile Image" name="ProfileImage" />
                                    </div>

                                    {/* AliasName  */}
                                    <div className="mb-6"><label className="block mb-2 text-sm font-medium dark:text-gray-400" htmlFor="">
                                        {t("Alias Name")}
                                    </label>
                                        <Field type="text" name="AliasName" placeholder={t("Enter a alias name")} className={`block w-full px-4 py-3 mb-2 text-sm placeholder-gray-500 bg-white border rounded dark:text-gray-400 dark:placeholder-gray-500 dark:border-gray-800 dark:bg-gray-800 ${formik.touched.AliasName && formik.errors.AliasName ? "border-red-600" : null}`} />
                                        <ErrorMessage name="AliasName" component={TextError} />


                                    </div>

                                    {/* GenderIdentity */}
                                    <div className="mb-6">
                                        <label className="mr-2">
                                            <div className='mb-2'>{t("Gender")} *</div>
                                            <Field type="radio" name="Gender" value="Male" />
                                            <span className="ml-2 dark:text-gray-400">{t("Male")}</span>
                                        </label>
                                        <label>
                                            <Field type="radio" name="Gender" value="Female" />
                                            <span className="ml-2 dark:text-gray-400">{t("Female")}</span>
                                        </label>
                                        <ErrorMessage name="Gender" component={TextError} />
                                    </div>


                                    {/* CellPhone and Phone */}
                                    <div className="grid w-full gap-4 mb-6 lg:grid-cols-2">
                                        <div >
                                            <label className="block mb-2 text-sm font-medium dark:text-gray-400" htmlFor="">
                                                {t("Cellphone")}</label>
                                            <Field type="text" name="Cellphone" placeholder={t("Enter a cellphone")} className={`block w-full px-4 py-3 mb-2 text-sm placeholder-gray-500 bg-white border rounded dark:text-gray-400 dark:placeholder-gray-500 dark:border-gray-800 dark:bg-gray-800 ${formik.touched.Cellphone && formik.errors.Cellphone ? "border-red-600" : null}`} />
                                            <ErrorMessage name="Cellphone" component={TextError} />
                                        </div>
                                        <div>
                                            <label className="block mb-2 text-sm font-medium dark:text-gray-400" htmlFor="">{t("Phone")}</label>
                                            <Field type="text" name="Phone" placeholder={t("Enter a phone")} className={`block w-full px-4 py-3 mb-2 text-sm placeholder-gray-500 bg-white border rounded dark:text-gray-400 dark:placeholder-gray-500 dark:border-gray-800 dark:bg-gray-800 ${formik.touched.Phone && formik.errors.Phone ? "border-red-600" : null}`} />
                                            <ErrorMessage name="Phone" component={TextError} />
                                        </div>
                                    </div>

                                    {/* Address */}
                                    <div className="mb-6">
                                        <label className="block mb-2 text-sm font-medium dark:text-gray-400" htmlFor="">
                                            {t("Address")}
                                        </label>
                                        <Field type="text" name="Address" placeholder={t("Enter a address")} className={`block w-full px-4 py-3 mb-2 text-sm placeholder-gray-500 bg-white border rounded dark:text-gray-400 dark:placeholder-gray-500 dark:border-gray-800 dark:bg-gray-800 ${formik.touched.Address && formik.errors.Address ? "border-red-600" : null}`} />
                                        <ErrorMessage name="Address" component={TextError} />
                                    </div>

                                    {/* UserType & Role */}
                                    <div className="grid w-full gap-4 mb-6 lg:grid-cols-2">
                                        <div>
                                            <label className="block mb-2 text-sm font-medium dark:text-gray-400" htmlFor="">{t("Select User Type")} *</label>
                                            <div className="relative">
                                                <Field
                                                    name="UserType"
                                                    as="select"
                                                    className={`block w-full px-4 py-3 mb-2 text-sm placeholder-gray-500 bg-white border rounded dark:text-gray-400 dark:placeholder-gray-500 dark:border-gray-800 dark:bg-gray-800 ${formik.touched.UserType && formik.errors.UserType ? "border-red-600" : null}`} >
                                                    <option value="" disabled hidden>{t("Please Choose")}...</option>
                                                    {
                                                        userType?.map((item) => <option value={item} key={item}>{item} </option>)
                                                    }
                                                </Field>
                                                <ErrorMessage name="UserType" component={TextError} />

                                            </div>
                                        </div>

                                        <div >
                                            <label className="block mb-2 text-sm font-medium dark:text-gray-400" htmlFor="">{t("Select Role")} *</label>
                                            <div className="relative">
                                                <Field
                                                    name="Role"
                                                    as="select"
                                                    className={`block w-full px-4 py-3 mb-2 text-sm placeholder-gray-500 bg-white border rounded dark:text-gray-400 dark:placeholder-gray-500 dark:border-gray-800 dark:bg-gray-800 ${formik.touched.Role && formik.errors.Role ? "border-red-600" : null}`}>
                                                    <option value="" disabled hidden>{t("Please Choose")}...</option>
                                                    {
                                                        userRole?.map((item) => <option value={item.Title} key={item.Title}>{item.Title} </option>)
                                                    }
                                                </Field>
                                                <ErrorMessage name="Role" component={TextError} />

                                            </div>
                                        </div>
                                    </div>


                                    {/* ReferralUser */}
                                    <div className="mb-6">
                                        <label className="block mb-2 text-sm font-medium dark:text-gray-400" htmlFor="">{t("Select Referral User")}</label>
                                        <div className="relative">
                                            <Field
                                                name="ReferralUser"
                                                as="select"
                                                className={`block w-full px-4 py-3 mb-2 text-sm placeholder-gray-500 bg-white border rounded dark:text-gray-400 dark:placeholder-gray-500 dark:border-gray-800 dark:bg-gray-800 ${formik.touched.ReferralUser && formik.errors.ReferralUser ? "border-red-600" : null}`}>
                                                <option value="" disabled hidden>{t("Please Choose")}...</option>
                                                {
                                                    referralUser?.map((item) => <option value={item._id} key={item.UserName}>{item.UserName} </option>)
                                                }
                                            </Field>
                                            <ErrorMessage name="ReferralUser" component={TextError} />

                                        </div>
                                    </div>

                                    {/* Remarks */}
                                    <div className="mb-6">
                                        <label className="block mb-2 text-sm font-medium dark:text-gray-400" htmlFor="">{t("Remarks")}</label>
                                        <Field
                                            as="textarea"
                                            name="Remarks" rows="5" placeholder={t("Write something")}
                                            className={`block w-full px-4 py-3 mb-2 text-sm placeholder-gray-500 bg-white border rounded dark:text-gray-400 dark:placeholder-gray-500 dark:border-gray-800 dark:bg-gray-800 ${formik.touched.Remarks && formik.errors.Remarks ? "border-red-600" : null}`} />
                                        <ErrorMessage name="Remarks" component={TextError} />
                                    </div>

                                    {/* Submit */}

                                    <div className="mt-7">
                                        <div className="flex justify-start space-x-2">
                                            <button type="submit"
                                                className="inline-block px-6 py-2.5 bg-blue-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-600">{t("Save")}</button>
                                        </div>
                                    </div>

                                </Form>
                            )
                        }
                    </Formik>
                </div>
            </div>
        </section >
    )
}

export default UserForm