import { Field, ErrorMessage } from "formik";
import React from "react";
import { TextError } from "../TextError";
import { useTranslation } from "react-i18next";

const FormikOnChangeField = ({
  label,
  focus = false,
  handleChange,
  name,
  type = "text",
  placeholder = "",
  formik,
  ...props
}) => {
  const { t } = useTranslation();
  return (
    <div>
      <label
        className="block mb-2 text-sm font-medium dark:text-gray-400"
        htmlFor={label}
      >
        {t(label)}
      </label>
      <Field>
        {({ form }) => {
          return (
            <input
              {...props}
              name={name}
              type={type}
              placeholder={t(placeholder)}
              value={form.values[name] || ""}
              onChange={(e) => handleChange(e.target.value, form)}
              onBlur={form.handleBlur}
              className={`block w-full px-4 py-2 text-sm placeholder-gray-500 bg-white border rounded dark:text-gray-400 dark:placeholder-gray-500 dark:border-gray-800 dark:bg-gray-800 ${
                formik?.touched?.[name] && formik?.errors?.[name]
                  ? "border-red-600"
                  : null
              }`}
            />
          );
        }}
      </Field>
      <ErrorMessage name={name} component={TextError} />
    </div>
  );
};

export default FormikOnChangeField;
