import React, { useState } from 'react'
import HomeSkeleton from '../../common/skeleton/HomeSkeleton'


const Home = () => {
    //eslint-disable-next-line
    const [isLoading, setIsLoading] = useState(true)

   

    return (
        <>
            <section className="px-6 py-6">
                {
                    isLoading ? <HomeSkeleton/>
                        :
                        <>
                            <div className="grid grid-cols-1 gap-6 lg:grid-cols-3">
                            
                            </div>
                        </>
                }

            </section>
        </>

    )
}

export default Home