import React from 'react'

const TableSkeleton = () => {
    return (
        <>
            <div className="flex flex-wrap items-center justify-between px-6 pb-4 border-b">
                <div className="w-[180px] bg-gray-200 animate-pulse rounded h-4 rounded"> </div>
                <div className="bg-blue-100 animate-pulse rounded w-16 h-6 rounded">
                </div>
            </div>
            <div className="pt-4 overflow-x-auto">
                <table className="w-full table-auto">
                    <thead>
                        <tr className="text-sm text-left text-gray-500 dark:text-gray-400">
                            <th className=" pb-3">
                                <div className="w-24 h-5 bg-gray-200 animate-pulse rounded my-4 mx-6"></div>
                            </th>
                            <th className=" pb-3">
                                <div className="w-24 h-5 bg-gray-200 animate-pulse rounded my-4 mx-6"></div>
                            </th>
                            <th className=" pb-3">
                                <div className="w-24 h-5 bg-gray-200 animate-pulse rounded my-4 mx-6"></div>
                            </th>
                            <th className=" pb-3">
                                <div className="w-24 h-5 bg-gray-200 animate-pulse rounded my-4 mx-6"></div>
                            </th>
                            <th className=" pb-3">
                                <div className="w-24 h-5 bg-gray-200 animate-pulse rounded my-4 mx-6"></div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className="text-sm dark:text-gray-400 dark:bg-gray-800 border-t">
                            <td><div className="w-24 h-5 bg-gray-200 animate-pulse rounded my-4 mx-6"></div></td>
                            <td><div className="w-24 h-5 bg-gray-200 animate-pulse rounded my-4 mx-6"></div></td>
                            <td><div className="w-24 h-5 bg-gray-200 animate-pulse rounded my-4 mx-6"></div></td>
                            <td><div className="w-24 h-5 bg-gray-200 animate-pulse rounded my-4 mx-6"></div></td>
                            <td><div className="w-24 h-5 bg-gray-200 animate-pulse rounded my-4 mx-6"></div></td>
                        </tr>
                        <tr className="text-sm dark:text-gray-400 dark:bg-gray-800 border-t">
                            <td><div className="w-24 h-5 bg-gray-200 animate-pulse rounded my-4 mx-6"></div></td>
                            <td><div className="w-24 h-5 bg-gray-200 animate-pulse rounded my-4 mx-6"></div></td>
                            <td><div className="w-24 h-5 bg-gray-200 animate-pulse rounded my-4 mx-6"></div></td>
                            <td><div className="w-24 h-5 bg-gray-200 animate-pulse rounded my-4 mx-6"></div></td>
                            <td><div className="w-24 h-5 bg-gray-200 animate-pulse rounded my-4 mx-6"></div></td>
                        </tr>
                        <tr className="text-sm dark:text-gray-400 dark:bg-gray-800 border-t">
                            <td><div className="w-24 h-5 bg-gray-200 animate-pulse rounded my-4 mx-6"></div></td>
                            <td><div className="w-24 h-5 bg-gray-200 animate-pulse rounded my-4 mx-6"></div></td>
                            <td><div className="w-24 h-5 bg-gray-200 animate-pulse rounded my-4 mx-6"></div></td>
                            <td><div className="w-24 h-5 bg-gray-200 animate-pulse rounded my-4 mx-6"></div></td>
                            <td><div className="w-24 h-5 bg-gray-200 animate-pulse rounded my-4 mx-6"></div></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="flex justify-between p-6 border-t dark:border-gray-700">
                <div className="w-[180px] h-5 bg-gray-200 animate-pulse rounded"></div>
                <div className='flex gap-4'>
                    <div className="w-6 h-6 bg-blue-100 animate-pulse rounded"></div>
                    <div className="w-6 h-6 bg-blue-100 animate-pulse rounded"></div>
                    <div className="w-6 h-6 bg-blue-100 animate-pulse rounded"></div>
                    <div className="w-6 h-6 bg-blue-100 animate-pulse rounded"></div>

                </div>
            </div>
        </>
    )
}

export default TableSkeleton