import React, { useEffect, useState } from 'react'
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { TextError } from '../../common/TextError';
import RoleValidationSchema from '../../../validation/RoleValidatationSchema';
import APIServices from '../../../httpServices/httpServices';
import { emitSuccessToast } from '../../common/toast/ToastContainer';
import slugify from 'slugify'
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
const RolesForm = ({ data, id }) => {
    const {t} = useTranslation();
    const navigate = useNavigate()
    const initial = {
        Title: "",
        UniqueName: "",
        PermissionValue: [],
        Status: false

    }
    const [initialValues, setInitialValues] = useState(null)
    const [routeAndPermission, setRouteAndPermission] = useState(null)
    const [allPermissions, setAllPermissions] = useState([])


    useEffect(() => {
        if (data) {
            setInitialValues(data)
        }

    }, [data])


    useEffect(() => {
        (async () => {
            const { data, success } = await new APIServices(`permission/list`).get()
            if (success) {
                let allPer = []
                setRouteAndPermission(data);
                data.forEach((item) => allPer = [...allPer, ...JSON.parse(item.PermissionValue)])
                setAllPermissions(allPer)

            }



        })()
    }, [])


    const handleFormikFormSubmit = async (values, actions) => {
        let { setSubmitting, resetForm } = actions
        let payload = {
            ...values,
            Status: values.Status ? "Active" : "Inactive",
            PermissionValue: values.PermissionValue
        }
        const { success, data, message } = !!!id ? await new APIServices(`role`).post(payload) : await new APIServices(`role/${id}`).put(payload)
        if (success) {
            setSubmitting(false);
            resetForm();
            emitSuccessToast(message)
            navigate(`/setting/role/view/${data._id}`, { replace: true })

        }

    }

    const handleSelectAll = (e, setFieldValue) => {
        if (e.target.checked) {

            setFieldValue("PermissionValue", allPermissions)
        } else {

            setFieldValue("PermissionValue", [])
        }
    }

    const selectAllCheck = (allVal, value) => {
        const containsAll = allVal.length === value.length
        return containsAll
    }

    const handleAllCheck = (e, setFieldValue, allVal, value) => {
        if (e.target.checked) {
            setFieldValue("PermissionValue", [...allVal, ...value])
        } else {

            const filtered = allVal.filter((item) => !value.includes(item))
            setFieldValue("PermissionValue", [...filtered])
        }
    }

    const checkIfAllChecked = (allVal, value) => {
        const containsAll = value.every(element => {
            return allVal.includes(element);
        });
        return containsAll
    }
    return (
        <section className="py-16 bg-gray-100 dark:bg-gray-800">
            <div className="max-w-4xl px-4 mx-auto ">
                <div className="p-6 bg-white rounded-md shadow dark:border-gray-800 dark:bg-gray-900">
                    <h2 className="mb-6 text-xl font-medium leading-6 text-gray-900 dark:text-gray-300">{t("Role's Information")}
                    </h2>
                    <Formik
                        initialValues={initialValues || initial}
                        validationSchema={RoleValidationSchema}
                        onSubmit={handleFormikFormSubmit}
                        enableReinitialize
                    >

                        {
                            formik => (
                                <Form>
                                    <div className="mb-6">
                                        <label htmlFor='toggleB' className="block items-center cursor-pointer">
                                            <div className='mb-2'>{t("Status")}</div>
                                            <div className="relative flex">
                                                <Field type="checkbox" id="toggleB" name="Status" className="sr-only" />
                                                <div className="block w-14 h-8 bg-gray-200 rounded-full"></div>
                                                <div className="absolute w-6 h-6 transition bg-gray-700 rounded-full dot left-1 top-1">
                                                </div>
                                            </div>
                                        </label>
                                    </div>




                                    {/* Title and UniqueName*/}
                                    <div className="grid w-full gap-4 mb-6 lg:grid-cols-2">
                                        <div>
                                            <label className="block mb-2 text-sm font-medium dark:text-gray-400" htmlFor="">
                                                {t("Title")} *
                                            </label>
                                            <Field type="text" name="Title" placeholder="Enter a Title" >
                                                {({ field, form, meta }) => {
                                                    return <input
                                                        name="Title"
                                                        type="Text"
                                                        placeholder="Enter a Title"
                                                        value={field.value || ""}
                                                        onChange={e => { form.setFieldValue("UniqueName", slugify(e.target.value, { lower: true })); form.setFieldValue(field.name, e.target.value) }}
                                                        onBlur={form.handleBlur}
                                                        className={`block w-full px-4 py-3 mb-2 text-sm placeholder-gray-500 bg-white border rounded dark:text-gray-400 dark:placeholder-gray-500 dark:border-gray-800 dark:bg-gray-800 ${formik.touched.Title && formik.errors.Title ? "border-red-600" : null}`}
                                                    />
                                                }}
                                            </Field>
                                            <ErrorMessage name="Title" component={TextError} />
                                        </div>
                                        <div>

                                            <label className="block mb-2 text-sm font-medium dark:text-gray-400" htmlFor="">
                                                {t("UniqueName")} *
                                            </label>
                                            <Field name="UniqueName" type="text" placeholder="Enter a unique name" className={`block w-full px-4 py-3 mb-2 text-sm placeholder-gray-500 bg-white border rounded dark:text-gray-400 dark:placeholder-gray-500 dark:border-gray-800 dark:bg-gray-800 ${formik.touched.UniqueName && formik.errors.UniqueName ? "border-red-600" : null}`} />
                                            <ErrorMessage name="UniqueName" component={TextError} />
                                        </div>
                                    </div>


                                    {/* Checkboxes  */}
                                    <div className='border-t pt-5 -mx-6'>
                                        <div className="px-5">
                                            <div>
                                                <div className='flex'>
                                                    <Field name="PermissionValue" >
                                                        {({ field, form, meta }) => {
                                                            return <input
                                                                name="PermissionValue"
                                                                type="checkbox"
                                                                className='cursor-pointer'
                                                                checked={selectAllCheck(allPermissions, form.values.PermissionValue)}
                                                                onChange={e => handleSelectAll(e, form.setFieldValue)}
                                                                onBlur={form.handleBlur}
                                                            />
                                                        }}
                                                    </Field>
                                                    <label className='ml-2 font-bold text-sm'>Select All</label>
                                                </div>
                                            </div>

                                            <div className="grid grid-cols-2 gap-4 mt-3">
                                                {
                                                    !!routeAndPermission && !!routeAndPermission.length && routeAndPermission.map((item) => {
                                                        return (
                                                            <div key={item.Model}>
                                                                <div className='flex mt-2 mb-3'>
                                                                    <Field name="PermissionValue" >
                                                                        {({ field, form, meta }) => {
                                                                            return <input
                                                                                name="PermissionValue"
                                                                                type="checkbox"
                                                                                className='cursor-pointer'
                                                                                checked={checkIfAllChecked(form.values.PermissionValue, JSON.parse(item.PermissionValue))}
                                                                                onChange={e => handleAllCheck(e, form.setFieldValue, form.values.PermissionValue, JSON.parse(item.PermissionValue))}
                                                                                onBlur={form.handleBlur}
                                                                            />
                                                                        }}
                                                                    </Field>
                                                                    <label className='ml-2 text-sm font-bold'>{item.Model}</label>
                                                                </div>

                                                                {
                                                                    !!item.PermissionValue && JSON.parse(item.PermissionValue).map((val) => {
                                                                        return (
                                                                            <div key={val} className="flex ml-5 items-center mb-2">
                                                                                <Field type="checkbox" className='cursor-pointer' name="PermissionValue" value={val} />
                                                                                <label className='ml-2 text-sm'>{val}</label>
                                                                            </div>)
                                                                    })
                                                                }
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>

                                        </div>
                                    </div>




                                    {/* Submit */}

                                    <div className="mt-7">
                                        <div className="flex justify-start space-x-2">
                                            <button type="submit"
                                                className="inline-block px-6 py-2.5 bg-blue-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-600">{t("Save")}</button>
                                        </div>
                                    </div>

                                </Form>
                            )
                        }
                    </Formik>

                </div>
            </div>
        </section >
    )
}

export default RolesForm