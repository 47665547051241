import React, { useEffect, useState } from 'react'
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from 'react-redux'
import APIServices from '../../../httpServices/httpServices'
import { GET_USER_PROFILE } from '../../../redux/sagas/actions'
import Card from '../chart/Card'
import Charts from '../chart/Charts'
// import "nepali-datepicker-reactjs/dist/index.css"

const HomeSkeleton = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch()
    const [chartData, setChartData] = useState([])
    const { profile } = useSelector((state) => state.user)
    useEffect(() => {
        if (profile === null || profile === "undefined") {
            getUserProfile()
        }
        //eslint-disable-next-line
    }, [])

    useEffect(() => {
        getDetail()
    }, [])

    const getUserProfile = () => {
        const payload = {
            url: "user/mydetails"
        }
        dispatch(GET_USER_PROFILE(payload))
    }

    const getDetail = async () => {
        const { data, success } = await new APIServices(`dashboard`).get()
        if (success) {
            let payload = {
                ...data
            }
            setChartData({ ...payload })
        }
    }

    const pregnancyLabels = ['15-25', '25-35', '35-45', '45-above'];
    const bloodGroupLabels = [
        'A+',
        'A-',
        'B+',
        'B-',
        'O+',
        'O-',
        'AB+',
        'AB-',
        'Not Set'
    ];
    const pregnancyBackgroundColor = "rgb(255, 99, 132)"
    const bloodGroupBackgroundColor = ["#3e95cd", "#8e5ea2", "#3cba9f", "#e8c3b9", "#c45850", "#e8c9b0", "#3fba0f", "#000000", "#8e5e00"]
    

    return (
        <>
        
            <h2 className=" text-2xl font-bold text-black uppercase">{t("Welcome")} {profile?.UserName} </h2>
            <div className="container p-4 mx-auto">
                <p className="s text-xl font-bold"> {t("App Users Report")} </p>
                <br></br>
                <div className="body-content">
                    <div className="grid grid-cols-1 gap-4 md:grid-cols-3 xl:grid-cols-4">
                        <Card data={chartData.users} label={t("Active Users")} />
                       
                        <Card data={chartData.users} label={t("Archived Users")} />
                        <Card data={chartData.todayactive} label={t("Today's Active Users")} />
                        <Card data={chartData.yesterdayactive} label={t("Yesterday's Active Users")} />
                        <Card data={chartData.currentmonthactive} label={t("This Month Active Users")} />
                        <Card data={chartData.lastmonthactive} label={t("Last Month Active Users")} />
                    </div>
                    <div className="grid lg:grid-cols-2 xl:grid-cols-3 gap-4 mt-4">
                        <Charts chartdata={chartData.age} chartType="Bar" label={t("Pregnancy Ratio")} chartlabels={pregnancyLabels} color={pregnancyBackgroundColor} />
                        <Charts chartdata={chartData.bloodgroup} chartType="Pie" label={t("Blood Group Ratio")} chartlabels={bloodGroupLabels} color={bloodGroupBackgroundColor} />
                    </div>
                </div>

            </div>
        </>
    )
}

export default HomeSkeleton