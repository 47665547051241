import React from 'react';
import TipsForm from '../../container/healthPregnancyTips/tipsForm';

const AddTips = () => {

    return (
        <div><TipsForm /></div>
    )
}

export default AddTips