import React from 'react';
import EmergencyForm from '../../container/emergency/emergencyForm';

const AddEmergency = () => {

    return (
        <div><EmergencyForm /></div>
    )
}

export default AddEmergency