import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import APIServices from "../../../httpServices/httpServices";
import CustomImage from '../../common/CustomImage';
import DeleteModal from "../../common/modals/DeleteModal";
import { emitSuccessToast } from "../../common/toast/ToastContainer";
import DataView from "../../common/viewDetail/DataView";


const ViewExternalOrganization = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { id } = useParams();
    const [showModal, setShowModal] = useState(false);
    const [externalOrganizationData, setExternalOrganizationData] = useState([]);

    useEffect(() => {
        if (id) {
            getHealthCenterDetailView();
        }
        //eslint-disable-next-line
    }, []);

    // Fetch User Data
    const getHealthCenterDetailView = async () => {
        const { data } = await new APIServices(
            `external-organization/${id}`
        ).get();
        if (data) {
            setExternalOrganizationData(data);
        }
    };

    const handleShowDeleteModal = (id) => {
        setShowModal(true);
    };

    const handleDelete = async () => {
        const { message, success } = await new APIServices(
            `external-organization/${id}`
        ).delete();
        if (success) {
            setShowModal(false);
            emitSuccessToast(message);
            navigate("/external-organization");
        }
    };
    const handleEdit = (id) => {
        navigate(`/external-organization/edit/${id}`);
    };

    const { _id, Name, Slug, DevnagariName, Status, Image, Logo } = externalOrganizationData;

    return (
        <div className="max-w-6xl px-4 py-4 mx-auto lg:py-8 md:px-6">
            <div className="flex justify-between mt-5 mb-4 items-end">
                <h2 className="font-bold text-lg"> {t("External Organization's Information")} </h2>
                {Status === "Inactive" && (
                    <button
                        onClick={() => handleShowDeleteModal(_id)}
                        className="inline-block flex items-center px-4 py-2.5 bg-red-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-red-600"
                    >

                        {t("Delete")}
                    </button>
                )}
                <button
                    onClick={() => handleEdit(_id)}
                    className="inline-block flex items-center px-4 py-2.5 bg-blue-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-600"
                >
                    {t("Update")}
                </button>

            </div>
            <div className='shadow bg-stone-100 border-gray-500 p-8 rounded' >
                <div className="text-left">
                    <div className="flex flex-wrap justify-between">
                        <CustomImage alt="" source={Image ? Image : ""} className="object-contain h-40 w-40 max-w-xs rounded-md object-left" />
                        <CustomImage alt="" source={Logo ? Logo : ""} className="object-contain h-40 w-40 max-w-xs rounded-md object-left" />
                    </div>
                </div>
            </div>
            <div className="shadow bg-white border-gray-500 p-8 rounded">
                <div className="flex flex-wrap">
                    <DataView label={t("Name")} value={Name} />
                    <DataView label={t("Devnagari Name")} value={DevnagariName} />
                    <DataView label={t("Slug")} value={Slug} />
                    <DataView label={t("Contact No.")} value={externalOrganizationData?.ContactNo} />
                    <DataView label={t("Country")} value={externalOrganizationData?.Country} />
                    <DataView label={t("Province")} value={externalOrganizationData?.Province} />
                    <DataView label={t("District")} value={externalOrganizationData?.District} />
                    <DataView
                        label={t("Local Bodies")}
                        value={externalOrganizationData?.LocalBodies}
                    />
                    <DataView label={t("Ward No.")} value={externalOrganizationData?.WardNo} />
                    <DataView label={t("Tole")} value={externalOrganizationData?.Tole} />
                    <DataView label={t("Address")} value={externalOrganizationData?.Address} />
                    <DataView label={t("Latitiude")} value={externalOrganizationData?.Latitude} />
                    <DataView label={t("Longitude")} value={externalOrganizationData?.Longitude} />
                    <DataView
                        label={t("Is Verified")}
                        value={externalOrganizationData?.IsVerified}
                    />
                    <DataView label={t("Status")} value={Status} />
                </div>
            </div>
            {showModal && (
                <DeleteModal handleDelete={handleDelete} close={setShowModal} />
            )}
        </div>
    );
};

export default ViewExternalOrganization;
