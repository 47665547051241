// ./components/BarChart.js
import React from "react";
// this chart import is essential. Don't remove it. It will automatically handle chart id and type
// eslint-disable-next-line
import Chart from "chart.js/auto";
import { Bar, Pie } from "react-chartjs-2";


const Charts = ({ chartdata, chartType, label, chartlabels, color }) => {
    const labels = chartlabels;
    const data = {
        labels: labels,
        datasets: [
            {
                label: label,
                backgroundColor: color,
                borderColor: "rgb(255, 255, 255)",
                data: chartdata,
            },
        ],
    };
    return (
        <div className="bg-white p-4 rounded shadow" >
            {chartType === 'Pie' ?
                (
                    <Pie data={data} />
                ) : (
                    <Bar data={data} />
                )}
        </div>

    );
};

export default Charts; 