import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import APIServices from '../../../httpServices/httpServices';
import SettingsForm from '../../container/settings/SettingsForm';

const EditSetting = () => {
  const { id } = useParams()
  const [settingDataToEdit, setSettingDataToEdit] = useState({
    Title: "",
    UniqueName: "",
    SettingValue: [],
    Status: false
  })
  useEffect(() => {
    if (id)
      getSettingDetail()
      //eslint-disable-next-line 
  }, [])

  const getSettingDetail = async () => {
    const { data } = await new APIServices(`setting/${id}`).get()

    if (data) {
      setSettingDataToEdit({ ...data, Status: data.Status === "Active" ? true : false, SettingValue: JSON.parse(data.SettingValue) })
    }
  }

  //checking from uniquename in api
  return (
    <div><SettingsForm data={settingDataToEdit} id={id} /></div>
  )
}

export default EditSetting