import React from "react";
import ExternalOrganizationForm from "../../container/externalOrganization/ExternalOrganizationForm";

const AddExternalOrganization = () => {
    return (
        <div>
            <ExternalOrganizationForm />
        </div>
    );
};

export default AddExternalOrganization;
