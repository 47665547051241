import React from 'react'
import { useSelector } from 'react-redux'
import HealthCenterLayout from './HealthCenterLayout'
import SuperAdminLayout from './SuperAdminLayout'
import UsersLayout from './UsersLayout'

// This component decide layout according to user role
const LayoutDecider = () => {
    const { profile } = useSelector((state) => state.user)
    switch (profile?.Role) {
        case "Superadmin":
        case "Admin":
            return <SuperAdminLayout />
        case "Manager":
            return <HealthCenterLayout/>
        default:
            return <UsersLayout />
    }
}

export default LayoutDecider