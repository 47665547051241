// import logo from './logo.svg';
import './App.css';

import { useSelector } from "react-redux";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import UserForm from "./components/container/users/UserForm";
import Home from "./components/pages/dashboard/Home";
import Login from "./components/pages/auth/Login";
import Profile from "./components/pages/users/Profile";
import Protected from "./components/pages/Protected";
import Public from "./components/pages/Public";
import UsersList from './components/pages/users/UsersList';
import RolesList from './components/pages/roles/RolesList';
import AddRole from './components/pages/roles/AddRole';
import EditRole from './components/pages/roles/EditRole';
import SettingsList from './components/pages/setting/SettingsList';
import EditSetting from './components/pages/setting/EditSetting';
import AddSetting from './components/pages/setting/AddSetting';
import ViewUser from './components/pages/users/ViewUser';
import ViewSetting from './components/pages/setting/ViewSetting';
import ViewRole from './components/pages/roles/ViewRole';
import PostList from './components/pages/post/PostList';
import AddPost from './components/pages/post/AddPost';
import EditPost from './components/pages/post/EditPost';
import ViewPosts from './components/pages/post/ViewPosts';
import ForgotPassword from './components/pages/auth/ForgotPassword';
import RecoverPassword from './components/pages/auth/RecoverPassword';
import ProfileChangePassword from './components/pages/auth/ProfileChangePassword';
import UserChangePassword from './components/pages/auth/UserChangePassword';
import NotFound from './components/pages/notfound/NotFound';
import AddTag from './components/pages/tag/AddTag';
import AddTagType from './components/pages/tagtype/AddTagType';
import TagTypeList from './components/pages/tagtype/TagTypeList';
import TagList from './components/pages/tag/TagList';
import EditTag from './components/pages/tag/EditTag';
import EditTagType from './components/pages/tagtype/EditTagType';
import ViewTagType from './components/pages/tagtype/ViewTagType';
import ViewTag from './components/pages/tag/ViewTag';
import LayoutDecider from './components/layouts/LayoutDecider';
import RolesCheck from './components/pages/RolesChek';
import CategoryList from './components/pages/category/CategoryList';
import AddCategory from './components/pages/category/AddCategory';
import EditCategory from './components/pages/category/EditCategory';
import ViewCategory from './components/pages/category/ViewCategory';
import ProvinceList from './components/pages/province/ProvinceList';
import EditProvince from './components/pages/province/EditProvince';
import ViewProvince from './components/pages/province/ViewProvince';
import DistrictList from './components/pages/district/DistrictList';
import EditDistrict from './components/pages/district/EditDistrict';
import ViewDistrict from './components/pages/district/ViewDistrict';
import LocalBodyList from './components/pages/localbody/LocalBodyList';
import EditLocalBody from './components/pages/localbody/EditLocalBody';
import ViewLocalBody from './components/pages/localbody/ViewLocalBody';
import PregnancyWeekList from './components/pages/pregnancyweek/PregnancyWeekList';
import AddPregnancyWeek from './components/pages/pregnancyweek/AddPregnancyWeek';
import EditPregnancyWeek from './components/pages/pregnancyweek/EditPregnancyWeek';
import ViewPrgnancyWeek from './components/pages/pregnancyweek/ViewPregnancyWeek';
import AppUserList from './components/pages/appuser/AppUserList';
import AddAppUser from './components/pages/appuser/AddAppUser';
import EditAppUser from './components/pages/appuser/EditAppUser';
import ViewAppUser from './components/pages/appuser/ViewAppUser';
import AddBaby from './components/pages/baby/AddBaby';
import ViewBaby from './components/pages/baby/ViewBaby';
import HealthCenterList from "./components/pages/healthCenter/HealthCenterList";
import AddHealthCenter from "./components/pages/healthCenter/AddHealthCenter";
import EditHealthCenter from "./components/pages/healthCenter/EditHealthCenter";
import ViewHealthCenter from "./components/pages/healthCenter/ViewHealthCenter";
import ViewMood from './components/pages/appuser/ViewMood';
import ViewWeight from './components/pages/appuser/ViewWeight';
import EditBaby from './components/pages/baby/EditBaby';
import ViewNote from './components/pages/appuser/ViewNote';
import EditTips from './components/pages/healthPregnancyTips/EditTips';
import ViewHealthCenterForManager from './components/pages/healthCenter/ViewHealthCenterForManager';
import TipsList from './components/pages/healthPregnancyTips/TipsList';
import AddTips from './components/pages/healthPregnancyTips/AddTips';
import AddEmergency from './components/pages/emergency/AddEmergency';
import Emergencylist from './components/pages/emergency/Emergencylist';
import EditEmergency from './components/pages/emergency/EditEmergency';
import AddBabyNames from './components/pages/babynames/AddBabyNames';
import BabyNamesList from './components/pages/babynames/BabyNamesList';
import EditBabyNames from './components/pages/babynames/EditBabyNames';
import ViewTips from './components/pages/healthPregnancyTips/ViewTips';
import ViewEmergency from './components/pages/emergency/ViewEmergency';
import ViewBabyNames from './components/pages/babynames/ViewBabyNames';
import ExternalOrganizationList from './components/pages/externalOrganization/ExternalOrganizationList';
import AddExternalOrganization from './components/pages/externalOrganization/AddExternalOrganization';
import EditExternalOrganization from './components/pages/externalOrganization/EditExternalOrganization';
import ViewExternalOrganization from './components/pages/externalOrganization/ViewExternalOrganization';
function App() {
  const { isLoggedIn } = useSelector((state) => state.auth)

  return (
    <Routes>

      {/* AUTHENTICATION ROUTES */}
      <Route path="/auth" element={<Public isLoggedIn={isLoggedIn} > <Outlet /></Public>} >
        <Route index element={<Navigate to="login" replace />} />
        <Route path="login" element={<Login />} />
        <Route path="forgot-password" element={<ForgotPassword />} />
        <Route path="recover-password/:token" element={<RecoverPassword />} />
      </Route>

      {/* AUTHENTICATION PASSED ROUTES */}
      <Route path="/" element={<Protected isLoggedIn={isLoggedIn} ><LayoutDecider /></Protected>}>
        <Route index element={<Home />} />

        <Route path="/users" element={<RolesCheck roles={['Superadmin', "Admin"]} />}>
          <Route index element={<UsersList />} />
          <Route path="add" element={<UserForm />} />
          <Route path="edit/:id" element={<UserForm />} />
          <Route path="view/:id" element={<ViewUser />} />
          <Route path="change-password" element={<UserChangePassword />} />
        </Route>

        <Route path="/setting" element={<RolesCheck roles={['Superadmin', "Admin"]} />}>
          <Route index element={<SettingsList />} />
          <Route path="add" element={<AddSetting />} />
          <Route path="edit/:id" element={<EditSetting />} />
          <Route path="view/:id" element={<ViewSetting />} />
          <Route path="role">
            <Route index element={<RolesList />} />
            <Route path="add" element={<AddRole />} />
            <Route path="edit/:id" element={<EditRole />} />
            <Route path="view/:id" element={<ViewRole />} />
          </Route>
        </Route>


        <Route path="/post">
          <Route path=":Pagetype" element={<PostList />} />
          <Route path="add" element={<AddPost />} />
          <Route path="edit/:id" element={<EditPost />} />
          <Route path="view/:id" element={<ViewPosts />} />
        </Route>

        <Route path="/tag">
          <Route index element={<TagList />} />
          <Route path="add" element={<AddTag />} />
          <Route path="edit/:id" element={<EditTag />} />
          <Route path="view/:id" element={<ViewTag />} />
        </Route>


        <Route path="/babynames">
          <Route index element={<BabyNamesList />} />
          <Route path="add" element={<AddBabyNames />} />
          <Route path="edit/:id" element={<EditBabyNames />} />
          <Route path="view/:id" element={<ViewBabyNames />} />
        </Route>


        <Route path="/emergency">
          <Route index element />
          <Route path="list/:id" element={<Emergencylist />} />
          <Route path="add/:id" element={<AddEmergency />} />
          <Route path="edit/:id" element={<EditEmergency />} />
          <Route path="view/:id" element={<ViewEmergency />} />
        </Route>

        <Route path="/health-tips">
          <Route index element={<TipsList />} />
          <Route path="add" element={<AddTips />} />
          <Route path="edit/:Slug" element={<EditTips />} />
          <Route path="view/:Slug" element={<ViewTips />} />
        </Route>

        <Route path="/tag-type">
          <Route index element={<TagTypeList />} />
          <Route path="add" element={<AddTagType />} />
          <Route path="edit/:id" element={<EditTagType />} />
          <Route path="view/:id" element={<ViewTagType />} />
        </Route>

        <Route path="/profile" >
          <Route index element={<Profile />} />
          <Route path="change-password" element={<ProfileChangePassword />} />
        </Route>

        <Route path="/category">
          <Route index element={<CategoryList />} />
          <Route path="add" element={<AddCategory />} />
          <Route path="edit/:id" element={<EditCategory />} />
          <Route path="view/:id" element={<ViewCategory />} />
        </Route>


        <Route path="/province">
          <Route index element={<ProvinceList />} />
          <Route path="edit/:id" element={<EditProvince />} />
          <Route path="view/:id" element={<ViewProvince />} />
        </Route>

        <Route path="/district">
          <Route index element={<DistrictList />} />
          <Route path="edit/:id" element={<EditDistrict />} />
          <Route path="view/:id" element={<ViewDistrict />} />
        </Route>

        <Route path="/localbody">
          <Route index element={<LocalBodyList />} />
          <Route path="edit/:id" element={<EditLocalBody />} />
          <Route path="view/:id" element={<ViewLocalBody />} />
        </Route>

        <Route path="/pregnancy-week">
          <Route index element={<PregnancyWeekList />} />
          <Route path="add" element={<AddPregnancyWeek />} />
          <Route path="edit/:id" element={<EditPregnancyWeek />} />
          <Route path="view/:id" element={<ViewPrgnancyWeek />} />
        </Route>

        <Route path="/appuser">
          <Route index element={<AppUserList />} />
          <Route path="add" element={<AddAppUser />} />
          <Route path="edit/:id" element={<EditAppUser />} />
          <Route path="view/:id" element={<ViewAppUser />} />
          <Route path="view/mood/:id" element={<ViewMood />} />
          <Route path="view/weight/:id" element={<ViewWeight />} />
          <Route path="view/note/:id" element={<ViewNote />} />
        </Route>

        <Route path="/baby">
          <Route path="add/:id" element={<AddBaby />} />
          <Route path="view/:id" element={<ViewBaby />} />
          <Route path="edit/:id" element={<EditBaby />} />
        </Route>

        <Route path="/health-center">
          <Route index element={<HealthCenterList />} />
          <Route path="add" element={<AddHealthCenter />} />
          <Route path="edit/:id" element={<EditHealthCenter />} />
          <Route path="view/:id" element={<ViewHealthCenter />} />
          <Route path="manager/:id" element={<ViewHealthCenterForManager />} />
        </Route>
        <Route path="/external-organization">
          <Route index element={<ExternalOrganizationList />} />
          <Route path="add" element={<AddExternalOrganization />} />
          <Route path="edit/:id" element={<EditExternalOrganization />} />
          <Route path="view/:id" element={<ViewExternalOrganization />} />
        </Route>
      </Route>

      {/* ROUTES NOT FOUND */}
      <Route path="*" element={<NotFound />} />

    </Routes >

  );
}

export default App;
