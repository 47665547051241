import { ErrorMessage, Field } from "formik";
import React from "react";
import { TextError } from "../TextError";
import { useTranslation } from "react-i18next";

const FormikTextAreaField = ({ label, name, formik }) => {
  const { t } = useTranslation();

  return (
    <div>
      <label
        className="block mb-2 text-sm font-medium dark:text-gray-400"
        htmlFor=""
      >
        {t(label)}
      </label>
      <Field
        as="textarea"
        name={name}
        rows="5"
        placeholder="Write something..."
        className={`block w-full px-4 py-3 text-sm placeholder-gray-500 bg-white border rounded dark:text-gray-400 dark:placeholder-gray-500 dark:border-gray-800 dark:bg-gray-800 ${
          formik?.touched?.[name] && formik?.errors?.[name]
            ? "border-red-600"
            : null
        }`}
      />
      <ErrorMessage name={name} component={TextError} />
    </div>
  );
};

export default FormikTextAreaField;
