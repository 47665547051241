import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import APIServices from "../../../httpServices/httpServices";
import ExternalOrganizationForm from "../../container/externalOrganization/ExternalOrganizationForm";

const EditExternalOrganization = () => {
    // The ID you get here is SLUG
    const { id } = useParams();
    const [externalOrganizationData, setExternalOrganizationData] = useState([]);
    useEffect(() => {
        if (id) getDetail();
        //eslint-disable-next-line
    }, [id]);

    const getDetail = async () => {
        const { data, success } = await new APIServices(
            `external-organization/${id}`
        ).get();
        if (success) {
            let payload = {
                ...data,
                Status: data.Status === "Active" ? true : false,
                IsVerified: data.IsVerified === "Yes" ? true : false,
            };
            setExternalOrganizationData({ ...payload });
        }
    };

    return (
        <>
            <ExternalOrganizationForm data={externalOrganizationData} id={id} />
        </>
    );
};

export default EditExternalOrganization;
