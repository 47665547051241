import { ErrorMessage, Field } from "formik";
import { useEffect, useState } from "react";
import CreatableSelect from "react-select/creatable";
import { TextError } from "../TextError";
import { useTranslation } from "react-i18next";

const FormikCreatableSelect = ({
  label,
  name,
  handleSelectOption,
  options,
}) => {
  const { t } = useTranslation();
  const [optionValue, setOptionValue] = useState([]);
  useEffect(() => {
    setOptionValue(options);
  }, [options]);
  return (
    <div>
      <label
        className="block mb-2 text-sm font-medium dark:text-gray-400"
        htmlFor=""
      >
        {t(label)}
      </label>
      <Field type="text" name={name}>
        {({ field, form, meta }) => {
          return field.value ? (
            <CreatableSelect
              isMulti
              name={name}
              options={optionValue}
              classNamePrefix="select"
              onBlur={form.handleBlur}
              value={
                field.value.length
                  ? field.value?.map((item) => ({ value: item, label: item }))
                  : ""
              }
              onChange={(selectedOption) =>
                handleSelectOption(
                  selectedOption,
                  field.name,
                  form.setFieldValue
                )
              }
              className={`${
                meta.touched && meta.error ? "error-select" : null
              }`}
            />
          ) : (
            <CreatableSelect
              isMulti
              name={name}
              options={optionValue}
              classNamePrefix="select"
              onBlur={form.handleBlur}
              onChange={(selectedOption) =>
                handleSelectOption(
                  selectedOption,
                  field.name,
                  form.setFieldValue
                )
              }
              className={`${
                meta.touched && meta.error ? "error-select" : null
              }`}
            />
          );
        }}
      </Field>
      <ErrorMessage name={name} component={TextError} />
    </div>
  );
};

export default FormikCreatableSelect;
