import React from 'react'
import AppUserForm from '../../container/appuser/AppUserForm'

const AddAppUser = () => {
    return (
        <div><AppUserForm /></div>
    )
}

export default AddAppUser
