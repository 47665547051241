import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import APIServices from '../../../httpServices/httpServices';

const    CalculateTrimisterDays = () => {
    const [appUserData, setAppUserData] = useState({});
    const {id} =useParams();

    useEffect(() => {
        if (id) {
            getItemDetail()
        }
        // eslint-disable-next-line
    }, [])


    // Fetch User Data 
    const getItemDetail = async () => {
        const { data } = await new APIServices(`appuser/${id}`).get()
        if (data) {
          setAppUserData(data.appUsers);
        };
        
    }
    

    if (Object.keys(appUserData).length !== 0) {
    const totalDays = 280;
    const edd = new Date(appUserData.ExpectedDeliveryDate);

    const currentDate = new Date();
    const day= edd.getDate() ;
    const month = edd.getMonth() * 30 * 24 * 60;
    const year = edd.getFullYear() * 365 *24 * 60;
    const totaledd = day + month + year ;
    const day1= currentDate.getDate() * 24 * 60;
    const month1 = currentDate.getMonth() * 30 * 24 * 60;
    const year1 = currentDate.getFullYear() * 365 *24 * 60;
    const totaledd1 = day1 + month1 + year1 ;
    const difference = totaledd - totaledd1; // give remaining days
   
    if (difference < 0) {
      // WHEN DUE DATE IS PASSED
      const passedDays = totalDays + Math.abs(difference) / (1000 * 60 * 60 * 24);
      const passedWeekOfPeriod = Math.floor(passedDays / 7);
      const passedDaysOfPeriod = Math.floor(passedDays % 7);
      const TermData = {
        info: `${passedWeekOfPeriod} w. ${passedDaysOfPeriod} d`
      // Assuming you have a state variable and setter for 'termData' in your component
      }
      return TermData;
    } else {
      // WHEN DUE DATE NOT IS PASSED
      const remainingDays = difference / (1000 * 60 * 60 * 24);
      const totalPassedDays = totalDays - remainingDays;
  
      // const remainingWeekOfPeriod = Math.floor(remainingDays / 7);
      // const remainingDaysOfPeriod = Math.floor(remainingDays % 7);
  
      const passedWeekOfPeriod = Math.floor(totalPassedDays / 7);
      const passedDaysOfPeriod = Math.floor(totalPassedDays % 7);
      const TermData= {
        info: `${passedWeekOfPeriod} w. ${passedDaysOfPeriod} d`
     
  
      // Assuming you have a state variable and setter for 'termData' in your component
      
    }
  
    return TermData.info;
  }

  }
}
 
    
  
  export default CalculateTrimisterDays