
import DOMPurify from 'dompurify';
import { t } from 'i18next';
import { default as React, useState } from 'react';
import { Link } from 'react-router-dom';

const DropDown = ({ title, path, svg, child }) => {
    
    const [isExpanded, setExpanded] = useState(false);
    const handleExpand = () => {
        setExpanded(!isExpanded);
    };
    
    return (
        <li className='group'>
            <span onClick={() => handleExpand()}
                className="flex cursor-pointer items-center px-6 py-4 text-gray-700 dark:text-gray-400 dark:hover:bg-gray-800  hover:text-gray-600 hover:bg-gray-100">
               <span className="drop-shadow-lg mr-2 flex h-8 w-8 items-center 
                                    justify-center rounded-lg bg-white dark:bg-gray-800 dark:text-gray-100 bg-center 
                                    text-center xl:p-2.5" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(svg) }} />
                <span> {t(title)} </span>
                <span className="inline-block ml-auto sidenav-arrow">
                    <button  >
                        <svg className="w-3 h-3 dark:group-hover:text-gray-300" viewBox="0 0 10 6"
                            fill="none" xmlns="http://www.w3.org/2000/svg"
                            data-config-id="auto-svg-3-1">
                            <path
                                d="M9.08329 0.666626C8.74996 0.333293 8.24996 0.333293 7.91663 0.666626L4.99996 3.58329L2.08329 0.666626C1.74996 0.333293 1.24996 0.333293 0.916626 0.666626C0.583293 0.999959 0.583293 1.49996 0.916626 1.83329L4.41663 5.33329C4.58329 5.49996 4.74996 5.58329 4.99996 5.58329C5.24996 5.58329 5.41663 5.49996 5.58329 5.33329L9.08329 1.83329C9.41663 1.49996 9.41663 0.999959 9.08329 0.666626Z"
                                fill="currentColor"></path>
                        </svg>
                    </button>
                </span>
            </span>

            <div className={`pl-3 ml-3 transition border-gray-500 dropdown-section nested-menu ${isExpanded ? 'opacity-100' : 'opacity-0'} ${isExpanded ? 'block' : 'hidden'}`}>
                <ul className="text-sm">
                    {
                       
                        child?.map((item, index) => (
                            <li key={index}>
                                <Link to={item.link}
                                    className="flex items-center py-3 pl-3 pr-4 text-gray-700 rounded dark:text-gray-400 dark:hover:bg-gray-800 hover:bg-gray-100 ">
                                    <span className="text-gray-700 dark:text-gray-400">{t(item.title)}</span>
                                </Link>
                            </li>
                            
                        )
                        )
                    }


                </ul>
            </div>
        </li>
    )
}

export default DropDown