import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import APIServices from "../../../httpServices/httpServices";
import { LIMIT } from "../../../utilities/constant";
import CustomTable from "../../common/CustomTable";
// import DeleteModal from "../../common/modals/DeleteModal";
import TableSkeleton from "../../common/skeleton/TableSkeleton";
import TableEntry from "../../common/table/TableEntry";
import TablePagination from "../../common/table/TablePagination";
// import { emitSuccessToast } from "../../common/toast/ToastContainer";

const ExternalOrganizationList = () => {
    const { t } = useTranslation();

    const navigate = useNavigate();
    //eslint-disable-next-line
    const [tableHeading, setTableHeading] = useState([
        "Domain",
        "Name",
        "DevnagariName",
        "Slug",
        "Status",
        "Action",
    ]);
    const [externalOrganizationList, setExternalOrganizationList] = useState([]);
    //eslint-disable-next-line
    // const [selectedId, setSelectedHealthCenterId] = useState(null);
    // const [showModal, setShowModal] = useState(false);

    //PAGINATION STATE
    const [searchTxt, setsearchTxt] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const limit = LIMIT
    const [numberOfTotalData, setNumberOfTotalData] = useState(0);
    const [totalPagesNumber, setTotalPageNumber] = useState(0);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        let timeout;
        if (!!searchTxt) {
            timeout = setTimeout(() => {
                getListOfExternalOrganization();
            }, 1500);
        } else {
            getListOfExternalOrganization();
        }
        return () => clearTimeout(timeout);
        //eslint-disable-next-line
    }, [searchTxt, currentPage]);

    const getListOfExternalOrganization = async () => {
        const { data, success, total, totalPages } = await new APIServices(
            "external-organization/list"
        ).post({
            page: currentPage,
            limit: limit,
            search: searchTxt,
        });

        if (success) {
            setIsLoading(false);
            setExternalOrganizationList(data);
            setTotalPageNumber(totalPages || 0);
            setNumberOfTotalData(total || 0);
        }
    };


    // const handleDelete = async () => {
    //     const { message, success } = await new APIServices(
    //         `external-organization/${selectedId}`
    //     ).delete();
    //     if (success) {
    //         setShowModal(false);
    //         emitSuccessToast(message);
    //         getListOfExternalOrganization();
    //     }
    // };

    const handleEdit = (id) => {
        navigate(`/external-organization/edit/${id}`);
    };

    const handleView = (id) => {
        navigate(`/external-organization/view/${id}`);
    };

    const navigateToAddNew = () => {
        navigate("/external-organization/add");
    };

    const handleTableSearch = (val) => {
        setsearchTxt(val);
    };
    // const handleTableSearchD = (val) => {
    //   setsearchdist(val);
    // };

    return (
        <section className="dark:bg-gray-800 ">
            <div className="max-w-6xl px-4 py-4 mx-auto lg:py-8 md:px-6">
                <div className="pt-4 rounded shadow bg-white dark:bg-gray-900">
                    {isLoading ? (
                        <TableSkeleton />
                    ) : (
                        <>
                            <TableEntry
                                title={t("List of External Organization")}
                                navigate={navigateToAddNew}
                                handleTableSearch={handleTableSearch}

                            />
                            <CustomTable
                                tableData={externalOrganizationList}
                                tableHeadingData={tableHeading}
                                handleDelete={false}
                                handleEdit={handleEdit}
                                handleView={handleView}
                                actionField="_id"
                            />
                            <TablePagination
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                totalPagesNumber={totalPagesNumber}
                                numberOfTotalData={numberOfTotalData}
                                limit={limit}
                            />
                        </>
                    )}
                </div>
            </div>
            {/* {showModal && (
                <DeleteModal handleDelete={handleDelete} close={setShowModal} />
            )} */}
        </section>
    );
};

export default ExternalOrganizationList;
