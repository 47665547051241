import React, { useState, useEffect } from 'react'

const SelectForm = ({ data, setFieldValue, fieldName, type = "text", hasError = false }) => {
    const [arrayValue, setArrayValue] = useState([])
    const [settingSel, setsettingSel] = useState("")

    useEffect(() => {
        if (!!data && typeof (data) === "string") {
            setArrayValue(JSON.parse(data))
        } else {
            setArrayValue(data)
        }
    }, [data])

    const handleKeydown = (val) => {
        if (val) {
            setArrayValue([...new Set([...arrayValue, val])])
            setsettingSel("")
            setFieldValue(fieldName, [...arrayValue, val])

        }

    }

    const handleChange = (e) => {
        setsettingSel(e.target.value)
    }



    //Filter Way
    const handleDelete = (itemValue) => {
        let newArray = arrayValue.filter((item) => item !== itemValue);
        setFieldValue(fieldName, newArray)
    }

    return (
        <>
            <div className="flex flex-wrap gap-2">
                {
                    !!arrayValue?.length && arrayValue?.map((item, index) => (
                        <span className="bg-gray-500 text-white text-sm rounded flex items-center overflow-hidden" key={index}>
                            <span className='p-1 px-2'>
                                {item}
                            </span>
                            <svg xmlns="http://www.w3.org/2000/svg" onClick={() => handleDelete(item)} width="30" fill="currentColor" className="hover:bg-gray-700 cursor-pointer h-full border-l border-gray-400 px-2 bi bi-x-lg" viewBox="0 0 16 16">
                                <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
                            </svg>
                        </span>

                    ))
                }
                <input
                    name="settingSel"
                    type={type}
                    className={`border px-2 text-sm rounded ${hasError ? "border-red-600" : "border-gray-400"}`}
                    onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                            event.preventDefault()
                            handleKeydown(settingSel)
                        }
                    }}
                    value={settingSel}
                    onChange={handleChange}

                />
            </div>
        </>
    )
}

export default SelectForm