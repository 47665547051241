import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import APIServices from '../../../httpServices/httpServices';
import TagForm from '../../container/tag/TagForm';

const EditTag = () => {
    const { id } = useParams()
    const [dataToEdit, setDataToEdit] = useState([])
    useEffect(() => {
        if (id)
            getDetail()
        //eslint-disable-next-line 
    }, [])

    const getDetail = async () => {
        const { data, success } = await new APIServices(`tag/${id}`).get()
        if (success) {
            let payload = {
                ...data,
                Status: data.Status === "Active" ? true : false,
            }
            setDataToEdit({ ...payload })
        }
    }


    return (
        <>
            <TagForm dataToEdit={dataToEdit} id={id} />
        </>
    )
}

export default EditTag