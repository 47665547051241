import React, { useEffect, useState } from 'react'
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { TextError } from '../../common/TextError';
import APIServices from '../../../httpServices/httpServices';
import { emitSuccessToast } from '../../common/toast/ToastContainer';
import slugify from 'slugify'
import FormikTextField from '../../common/form/FormikTextField';
import FormikImageField from '../../common/form/FormikImageField';
import FormikTextAreaField from '../../common/form/FormikTextAreaField';
import FormikSwitchField from '../../common/form/FormikSwitchField';
import { useNavigate } from 'react-router-dom';
import TagTypeValidationSchema from '../../../validation/TagTypeValidationSchema';
import { useTranslation } from 'react-i18next';

const TagTypeForm = ({ data, id }) => {
    const {t}=useTranslation()
    const navigate = useNavigate()
    const [initialValues, setInitialValues] = useState({
        Status: false,
        Title: "",
        Slug: "",
        Image: "",
        Detail: "",
        SeoTitle: "",
        SeoDescription: "",
    })


    useEffect(() => {
        if (data)
            setInitialValues({
                Status: data?.Status || false,
                Title: data?.Title || "",
                Slug: data?.Slug || "",
                SeoTitle: data?.SeoTitle || "",
                SeoDescription: data?.SeoDescription || "",
                Detail: data?.Detail || "",
                Image: data?.Image || "",
            })
    }, [data])

    const handleFormikFormSubmit = async (values, actions) => {
        let { setSubmitting, resetForm } = actions
        let payload = {
            ...values,
            Status: values.Status ? "Active" : "Inactive",
            SeoTitle: values?.SeoTitle || values?.Title,
            SeoDescription: values?.SeoDescription || values?.Detail
        }
        let formData = new FormData();

        Object.keys(payload).forEach((key, index) => {
            formData.append(key, payload[key]);
        });
        const { success, data, message } = !!!id ? await new APIServices(`tagtype`).post(formData) : await new APIServices(`tagtype/${id}`).put(formData)
        if (success) {
            setSubmitting(false);
            resetForm();
            emitSuccessToast(message)
            navigate(`/tag-type/view/${data._id}`, { replace: true })
        }

    }

    return (
        <section className="py-16 bg-gray-100 dark:bg-gray-800">
            <div className="max-w-4xl px-4 mx-auto ">
                <Formik
                    initialValues={initialValues}
                    validationSchema={TagTypeValidationSchema}
                    onSubmit={handleFormikFormSubmit}
                    enableReinitialize
                >
                    {
                        formik => (
                            <Form>
                                <div className="grid grid-cols-[70%,1fr] gap-4">

                                    <div>
                                        <h3 className="mb-2 text-base font-medium leading-6 text-gray-900 dark:text-gray-300">{t("Tag's Type Information")}
                                        </h3>
                                        <div className="p-6 mb-4 bg-white rounded-md shadow dark:border-gray-800 dark:bg-gray-900">
                                            <div className="grid w-full gap-6 mb-6 lg:grid-cols-2">
                                                <div>
                                                    <label className="block mb-2 text-sm font-medium dark:text-gray-400" htmlFor="">
                                                        {t("Title")} *
                                                    </label>
                                                    <Field type="text" name="Title" placeholder="Enter a Title" >
                                                        {({ field, form, meta }) => {
                                                            return <input
                                                                name="Title"
                                                                type="Text"
                                                                placeholder={t("Enter a Title")}
                                                                value={field.value || ""}
                                                                onChange={e => { form.setFieldValue("Slug", slugify(e.target.value, { lower: true })); form.setFieldValue(field.name, e.target.value) }}
                                                                onBlur={form.handleBlur}
                                                                className={`block w-full px-4 py-3 text-sm placeholder-gray-500 bg-white border rounded dark:text-gray-400 dark:placeholder-gray-500 dark:border-gray-800 dark:bg-gray-800 ${formik.touched.Title && formik.errors.Title ? "border-red-600" : null}`}
                                                            />
                                                        }}
                                                    </Field>
                                                    <ErrorMessage name="Title" component={TextError} />
                                                </div>
                                                <FormikTextField label={t("Slug*")} name="Slug" formik={formik} />
                                            </div>
                                            {/* Details */}
                                            <FormikTextAreaField label="Detail *" name="Detail" formik={formik} />
                                        </div>


                                        {/* SEO AND SEO DESCRIPTION  */}
                                        <h3 className="mb-2 text-base font-medium leading-6 text-gray-900 dark:text-gray-300"> {t("SEO Section")}
                                        </h3>
                                        <div className="p-6 mb-4 bg-white rounded-md shadow">
                                            <div className="mb-6">
                                                <FormikTextField label="Seo Title" name="SeoTitle" formik={formik} />
                                            </div>
                                            <div className="">
                                                <FormikTextAreaField label="Seo Description" name="SeoDescription" formik={formik} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-8">
                                        <div className="p-6 mb-4 bg-white rounded-md shadow dark:border-gray-800 dark:bg-gray-900">
                                            <FormikSwitchField label="Status" name="Status" />
                                        </div>
                                        <div className="p-6 mb-4 bg-white rounded-md shadow dark:border-gray-800 dark:bg-gray-900">
                                            <FormikImageField label="Image" name="Image" formik={formik} />
                                        </div>

                                    </div>
                                </div>


                                {/* Submit */}
                                <div className="mt-7">
                                    <div className="flex justify-start space-x-2">
                                        <button type="submit"
                                            className="inline-block px-6 py-2.5 bg-blue-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-600">{t("Save")}</button>
                                    </div>
                                </div>
                            </Form>
                        )
                    }
                </Formik>
            </div>
        </section >
    )
}

export default TagTypeForm