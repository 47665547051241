import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import APIServices from '../../../httpServices/httpServices';
import TipsForm from '../../container/healthPregnancyTips/tipsForm';

const EditTips = () => {
    const { Slug } = useParams()
    
    const [dataToEdit, setDataToEdit] = useState([])
    useEffect(() => {
        if (Slug)
            getDetail()
        //eslint-disable-next-line 
    }, [])

    const getDetail = async () => {
        const { data, success } = await new APIServices(`health-tips/${Slug}`).get()
        if (success) {
            let payload = {
                ...data,
            }
            setDataToEdit({ ...payload })
        }
    }


    return (
        <>
            <TipsForm data={dataToEdit} Slug={Slug} />
        </>
    )
}

export default EditTips