import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import slugify from "slugify";
import APIServices from "../../../httpServices/httpServices";
import ExternalOrganizationValidationSchema from "../../../validation/ExternalOrganizationValidationSchema";
import FormikImageField from "../../common/form/FormikImageField";
import FormikOnChangeField from "../../common/form/FormikOnChangeField";
import FormikSelectSingleField from "../../common/form/FormikSelectSingleField";
import FormikSwitchField from "../../common/form/FormikSwitchField";
import FormikTextAreaField from "../../common/form/FormikTextAreaField";
import FormikTextField from "../../common/form/FormikTextField";
import {
    emitErrorToast,
    emitSuccessToast,
} from "../../common/toast/ToastContainer";
// import FarmerValidationSchema from "../../../validation/FarmersValidationSchema";

const ExternalOrganizationForm = ({ data, id }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [provinceOptions, setProvinceOptions] = useState([]);
    const [districtOptions, setDistrictOptions] = useState([]);
    const [localBodiesOptions, setLocalBodiesOptionsptions] = useState([]);
    const [initialValues, setInitialValues] = useState({
        Name: "",
        DevnagariName: "",
        Domain: "",
        Slug: "",
        Country: "Nepal",
        Province: "",
        District: "",
        LocalBodies: "",
        WardNo: "",
        Tole: "",
        Address: "",
        Latitude: "",
        Longitude: "",
        Status: true,
        Image: "",
        Logo: "",
        IsVerified: false,
    });

    useEffect(() => {
        if (data) {
            getDistrictByProvince(data.Province);
            getLocalBodiesByDistrict(data.District);
        }
    }, [data]);

    useEffect(() => {
        getFormSelectFieldValues();
    }, []);

    useEffect(() => {
        if (data)
            setInitialValues((prev) => ({
                ...prev,
                ...data,
            }));
    }, [data]);

    const handleFormikFormSubmit = async (values, actions) => {
        // let bool = await validateSlug(values?.Slug);
        let { setSubmitting, resetForm } = actions;
        let payload = {
            ...values,
            Status: values.Status ? "Active" : "Inactive",
            IsVerified: values.IsVerified ? "Yes" : "No",
        };
        const { Logo, ...rest } = payload;
        let formData = new FormData();
        // Append image files
        formData.append('Image', Image);
        formData.append('Logo', Logo);
        Object.keys(rest).forEach((key, index) => {
            formData.append(key, rest[key]);
        });
        const { success, data, message } = !!!id
            ? await new APIServices(`external-organization`).post(formData)
            : await new APIServices(`external-organization/${id}`).put(formData);
        if (success) {
            setSubmitting(false);
            resetForm();
            emitSuccessToast(message);
            navigate(`/external-organization/view/${data._id}`, { replace: true });
        } else {
            emitErrorToast(message);
        }
    };

    const getFormSelectFieldValues = async () => {
        const [provinceObj] = await Promise.all([
            new APIServices("province/list").post({}),
        ]);
        if (provinceObj.success) {
            let provinceData = provinceObj.data.map((item) => ({
                value: item.Name,
                label: item.Name,
            }));
            setProvinceOptions(provinceData);
        }
    };

    // District Options By Selected Province
    const getDistrictByProvince = async (Province) => {
        const { data, success } = await new APIServices(
            `external-organization/get/districts?Province=${Province}`).get()

        if (success) {
            let districtOptions = data.map((item) => ({
                value: item,
                label: item,
            }))
            setDistrictOptions(districtOptions);
        }
    };

    // District Options By Selected Province
    const getLocalBodiesByDistrict = async (District) => {
        const { data, success } = await new APIServices(
            `external-organization/get/localbodies?District=${District}`
        ).get()
        if (success) {
            let localbodiesOptions = data.map((item) => ({
                value: item,
                label: item,
            }));
            setLocalBodiesOptionsptions(localbodiesOptions);
        }
    };

    const handleSelectSingle = (selectedOption, fieldName, setFieldValue) => {
        setFieldValue(fieldName, selectedOption.value);
        getDistrictByProvince(selectedOption.value);
    };

    const handleSelectSingleDistrict = (
        selectedOption,
        fieldName,
        setFieldValue
    ) => {
        setFieldValue(fieldName, selectedOption.value);
        getLocalBodiesByDistrict(selectedOption.value);
    };
    const handleSelectSingleLocalBody = (
        selectedOption,
        fieldName,
        setFieldValue
    ) => {
        setFieldValue(fieldName, selectedOption.value);
    };

    const handleNameChange = (value, form) => {
        form.setFieldValue("Name", value);
        form.setFieldValue(
            "Slug",
            slugify(value, { replacement: "_", lower: true })
        );
    };

    return (
        <>
            <section className="py-16 bg-gray-100 dark:bg-gray-800">
                <div className="max-w-4xl px-4 mx-auto ">
                    <Formik
                        initialValues={initialValues}
                        validationSchema={ExternalOrganizationValidationSchema}
                        onSubmit={handleFormikFormSubmit}
                        enableReinitialize
                    >
                        {(formik) => (
                            <Form>
                                <div className="">
                                    <h3 className="mb-2 text-base font-bold leading-6 text-gray-900 dark:text-gray-300">
                                        {t("External Organization's Information")}
                                    </h3>
                                    <div className="p-6 mb-4 bg-white rounded-md shadow dark:border-gray-800 dark:bg-gray-900">

                                        <FormikOnChangeField
                                            label="Name *"
                                            name="Name"
                                            placeholder="External Organization Name"
                                            handleChange={handleNameChange}
                                            formik={formik}
                                        />

                                        <FormikTextField
                                            label="Slug"
                                            name="Slug"
                                            formik={formik}
                                            disabled
                                        />

                                        <FormikTextField
                                            label="Devnagari Name"
                                            name="DevnagariName"
                                            formik={formik}
                                        />
                                        <FormikTextField
                                            label="Domain"
                                            name="Domain"
                                            formik={formik}
                                        />
                                        <div className="">
                                            <FormikImageField label={t("External Organization Image")} name="Image" />
                                        </div>

                                        <div className="">
                                            <FormikImageField label="External Organization Logo" name="Logo" />
                                        </div>
                                        <FormikTextField
                                            label="Contact No. *"
                                            name="ContactNo"
                                            type="number"
                                            formik={formik}
                                        />
                                        <div className="grid w-full gap-6 mb-6 lg:grid-cols-3">
                                            <FormikTextField
                                                label="Country"
                                                name="Country"
                                                readOnly={true}
                                                formik={formik}
                                            />
                                            <FormikSelectSingleField
                                                label="Province"
                                                name="Province"
                                                options={provinceOptions}
                                                handleSelectSingle={handleSelectSingle}
                                            />
                                            <FormikSelectSingleField
                                                label="District"
                                                name="District"
                                                options={districtOptions}
                                                handleSelectSingle={handleSelectSingleDistrict}
                                            />
                                        </div>
                                        <div className="grid w-full gap-6 mb-6 lg:grid-cols-3">
                                            <FormikSelectSingleField
                                                label="Local Bodies"
                                                name="LocalBodies"
                                                options={localBodiesOptions}
                                                handleSelectSingle={handleSelectSingleLocalBody}
                                            />
                                            <FormikTextField
                                                label="Ward No."
                                                name="WardNo"
                                                type="number"
                                                formik={formik}
                                            />
                                            <FormikTextField
                                                label="Tole"
                                                name="Tole"
                                                formik={formik}
                                            />
                                        </div>
                                        <div className="mb-6">
                                            <FormikTextField
                                                label="Address"
                                                name="Address"
                                                formik={formik}
                                            />
                                        </div>
                                        <div className="grid w-full gap-4 mb-6 lg:grid-cols-2">
                                            <FormikTextField
                                                label="Latitude"
                                                name="Latitude"
                                                formik={formik}
                                            />
                                            <FormikTextField
                                                label="Longitude"
                                                name="Longitude"
                                                formik={formik}
                                            />
                                        </div>
                                        <FormikTextAreaField label={t("Detail")} name="Detail" formik={formik} />


                                        <div className="grid w-full gap-4 mb-6 lg:grid-cols-3">
                                            <FormikSwitchField
                                                label={t("Is Verified")}
                                                name="IsVerified"
                                            />
                                        </div>
                                        <FormikSwitchField label={t("Status")} name="Status" />
                                    </div>

                                    {/* SEO AND SEO DESCRIPTION  */}
                                    <h3 className="mb-2 text-base font-bold leading-6 text-gray-900 dark:text-gray-300"> {t("SEO Section")}
                                    </h3>
                                    <div className="p-6 mb-4 bg-white rounded-md shadow">
                                        <div className="mb-6">
                                            <FormikTextField label="Seo Title" name="SeoTitle" formik={formik} />
                                        </div>
                                        <div className="">
                                            <FormikTextAreaField label="Seo Description" name="SeoDescription" formik={formik} />
                                        </div>
                                    </div>

                                </div>

                                {/* Submit */}
                                <div className="mt-7">
                                    <div className="flex justify-start space-x-2">
                                        <button
                                            type="submit"
                                            className="inline-block px-6 py-2.5 bg-blue-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-600"
                                        >
                                            {t("Save")}
                                        </button>
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </section>
        </>
    );
};

export default ExternalOrganizationForm;
