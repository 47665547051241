import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const TablePagination = ({ setCurrentPage, currentPage, totalPagesNumber }) => {
    const { t } = useTranslation();
    const [movePage, setMovePage] = useState(0);

    useEffect(() => {
        setMovePage(Number(currentPage));
        // eslint-disable-next-line
    }, [currentPage]);

    const buttonClasses =
        "relative block px-3 py-1.5 mr-3 mb-4 text-base hover:text-blue-700 transition-all duration-300 hover:bg-blue-100 dark:hover:text-gray-400 font-semibold dark:hover:bg-gray-700 rounded-md";

    return (
        <div className="flex flex-wrap items-center justify-between gap-4 mt-4 mb-4">
            <div className="flex items-center">
                <span className="ml-4 mr-4 font-semibold">{t("Page")}</span>
                <select
                    className="px-2 py-1 mr-4 border border-gray-300 rounded-md"
                    value={currentPage}
                    onChange={(e) => setCurrentPage(e.target.value)}
                >
                    {[...Array(totalPagesNumber)].map((_, index) => (
                        <option key={index} value={index + 1}>
                            {index + 1}
                        </option>
                    ))}
                </select>
                <span>
                    {t("of")} {totalPagesNumber}
                </span>
            </div>

            <div className="flex flex-wrap items-center">
                <span className="mx-4 font-semibold">{t("Jump to page")}</span>
                <input
                    className="px-2 py-1 mr-4 border border-gray-300 rounded-md input-pagination"
                    type="number"
                    min={1}
                    max={totalPagesNumber}
                    value={movePage}
                    onChange={(e) => setMovePage(e.target.value)}
                    onKeyDown={(e) => {
                        if (e.key === "Enter") setCurrentPage(Number(movePage));
                    }}
                />
                <button
                    className="px-2 py-1 text-white rounded-md bg-primary hover:opacity-90"
                    onClick={() => setCurrentPage(Number(movePage))}
                >
                    {t("Go")}
                </button>
            </div>

            <div className="flex">
                <button
                    className={`${buttonClasses} ${currentPage === 1 && "pointer-events-none opacity-50 "
                        }`}
                    onClick={() => setCurrentPage(Number(currentPage) - 1)}
                    disabled={currentPage === 1}
                >
                    {t("Previous")}
                </button>

                <button
                    className={`${buttonClasses} ${currentPage >= totalPagesNumber && "pointer-events-none opacity-50 "
                        }`}
                    onClick={() => setCurrentPage(Number(currentPage) + 1)}
                    disabled={currentPage >= totalPagesNumber}
                >
                    {t("Next")}
                </button>
            </div>
        </div>
    );
};

export default TablePagination;
