import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from 'react-router-dom';
import APIServices from '../../../httpServices/httpServices';
import Card from '../../common/chart/Card';
import Charts from '../../common/chart/Charts';
import UserModel from '../../common/modals/UserModel';
import DataView from '../../common/viewDetail/DataView';


const ViewLocalBody = () => {
    
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { id } = useParams();
    const [localBodyData, setLocalBodyData] = useState([]);
    const [chartData, setChartData] = useState([])
    const { profile } = useSelector((state) => state.user);
    const [userModel, setUserModel] = useState(false);
    const [payload, setPayload] = useState("");

    useEffect(() => {
        if (id) {
            getLocalBodyDetailView()
        }
        //eslint-disable-next-line
    }, [])

    // Fetch User Data 
    const getLocalBodyDetailView = async () => {
        const { data, success } = await new APIServices(`localbody/${id}`).get()
        if (success) {
            setLocalBodyData(data.localBodies)
            setChartData({ ...data })
            setPayload({
                ...data,
                FullName: "",
                UserType: "Localbody",
                Role: "Admin",
                UserName: "",
                UserPass: "",
            });
        }
    }
    const { _id, District, Name, DevNagariName, Area, Mayor, MaleP, FemaleP, OtherP, IsUser } = localBodyData

    const handleUser = async (values) => {
        // if (IsUser) {
        //     const { message, success } = await new APIServices(
        //         `user/${farmerId}`
        //     ).delete();

        //     if (success) {
        //         emitSuccessToast(message);
        //         setFarmerData({ ...farmerData, IsUser: false, UserId: "" });
        //     } else {
        //         emitErrorToast(message);
        //     }
        // } else {
        //     const { success, message } = await new APIServices("user").post(values);

        //     if (success) {
        //         await new APIServices(`farmer/${farmerId}`).put({
        //             ...farmerData,
        //             IsUser: true,
        //         });
        //         setFarmerData({ ...farmerData, IsUser: true });
        //         emitSuccessToast(message);
        //     } else {
        //         emitErrorToast(message);
        //     }
        // }

        setUserModel(false);
    };



    const pregnancyLabels = ['15-25', '25-35', '35-45', '45-above'];
    const bloodGroupLabels = [
        'A+',
        'A-',
        'B+',
        'B-',
        'O+',
        'O-',
        'AB+',
        'AB-',
        'Not Set'
    ];
    const pregnancyBackgroundColor = "rgb(255, 99, 132)"
    const bloodGroupBackgroundColor = ["#3e95cd", "#8e5ea2", "#3cba9f", "#e8c3b9", "#c45850", "#e8c9b0", "#3fba0f", "#000000", "#8e5e00"]
    const handleEdit = (id) => {
        navigate(`/localbody/edit/${id}`);
    }
    return (
        <div className="max-w-6xl px-4 py-4 mx-auto lg:py-8 md:px-6">
            <div className="flex justify-between mt-5 mb-4 items-end">
                <h2 className="font-bold text-lg"> {t("Local Body Information")}   <button
            onClick={() => handleEdit(_id)}
            className="font-medium text-blue-600 hover:text-blue-500 dark:hover:text-gray-300 dark:text-blue-300 ml-1">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
            fill="currentColor" className="w-4 h-4 mr-2 bi bi-pencil-square"
            viewBox="0 0 16 16">
            <path
                d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
            <path fillRule="evenodd"
                d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                 </svg>
            </button></h2>
            </div>
            {["Superadmin", "Admin"].includes(profile?.Role) && (
                <button
                    onClick={() => setUserModel(true)}
                    className={` flex items-center px-4 py-2.5 bg-${IsUser ? "red" : "green"
                        }-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-${IsUser ? "red" : "green"
                        }-600 mb-5`}
                >
                    {IsUser ? t("Remove User") : t("Create Local body User")}
                </button>
            )}
             
            <div className="shadow bg-white border-gray-500 p-8 rounded mb-4">
                <div className="flex flex-wrap">
                    <DataView label={t("District")} value={District} />
                    <DataView label={t("Name")} value={Name} />
                    <DataView label={t("Dev Nagari Name")} value={DevNagariName} />
                    <DataView label={t("Area")} value={Area} />
                    <DataView label={t("Mayor")} value={Mayor} />
                    <DataView label={t("Male Population")} value={MaleP} />
                    <DataView label={t("Female Population")} value={FemaleP} />
                    <DataView label={t("Other Population")} value={OtherP} />
                </div>
            </div>
            <div className="container p-4 mx-auto">
                <p className="text-xl font-bold"> {t("Local Bodies Users Report")} </p>
                <div className="body-content">
                    <div className="grid grid-cols-1 gap-4 md:grid-cols-3 xl:grid-cols-4">
                        <Card data={chartData.usersInLocalBodies} label={t("Active")} />
                        <Card data={chartData.usersInLocalBodies} label={t("Archived Users")} />
                        <Card data={chartData.todayactive} label={t("Today's Active Users")} />
                        <Card data={chartData.yesterdayactive} label={t("Yesterday's Active Users")} />
                        <Card data={chartData.currentmonthactive} label={t("This Month Active Users")} />
                        <Card data={chartData.lastmonthactive} label={t("Last Month Active Users")} />
                    </div>
                    <div className="grid lg:grid-cols-2 xl:grid-cols-3 gap-4 mt-4">
                        <Charts chartdata={chartData.age} chartType="Bar" label={t("Pregnancy Ratio")} chartlabels={pregnancyLabels} color={pregnancyBackgroundColor} />
                        <Charts chartdata={chartData.bloodgroup} chartType="Pie" label={t("Blood Group Ratio")} chartlabels={bloodGroupLabels} color={bloodGroupBackgroundColor} />
                    </div>
                </div>
            </div>
            {userModel && (
                <UserModel
                    isUser={IsUser}
                    handleUser={handleUser}
                    close={setUserModel}
                    payload={payload}
                />
            )}
        </div>
    )
}

export default ViewLocalBody