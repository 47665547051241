import React from 'react'

const DataView = ({ label, value }) => {
    return (
        <div className="w-1/2">
            <label className="block mb-0 text-sm text-gray-600 font-normal">{label}</label>
            <p className="mb-5 text-lg font-semibold text-gray-800">{value ? value : '--'}</p>
        </div>
    )
}

export default DataView