import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import APIServices from '../../../httpServices/httpServices';
import { LIMIT } from '../../../utilities/constant';
import CustomTable from '../../common/CustomTable';
import TableSkeleton from '../../common/skeleton/TableSkeleton';
import TableEntry from '../../common/table/TableEntry';
import TablePagination from '../../common/table/TablePagination';
import moment from 'moment';
import  {ADToBS}  from "bikram-sambat-js"
const PostList = () => {
    const { Pagetype } = useParams()
    const navigate = useNavigate();
    const tableHeading = ["Title", "PageType", "Status", "PublishedOn", "IsVerified", "Action"]
    const [PostList, setPostList] = useState([])

    //PAGINATION STATE
    const [searchTxt, setsearchTxt] = useState("")
    const [currentPage, setCurrentPage] = useState(1)
    const limit = LIMIT
    const [numberOfTotalData, setNumberOfTotalData] = useState(0)
    const [totalPagesNumber, setTotalPageNumber] = useState(0)
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        let timeout;
        if (!!searchTxt) {
            timeout = setTimeout(() => {
                getListOfPost()
            }, 1500)
        } else {
            getListOfPost()
        }
        return () => clearTimeout(timeout)
        // eslint-disable-next-line
    }, [searchTxt, currentPage, Pagetype])

    const getListOfPost = async () => {
        const { data, success, total, totalPages } = await new APIServices(`post/getalllist/${Pagetype}`).post({
            page: currentPage,
            limit: limit,
            search: searchTxt,
        })
     

        if (success) {
            setPostList(
                data.map((item) => ({
                  ...item,
                  PublishedOn: ADToBS(moment(item?.PublishedOn).format("YYYY-MM-DD"))
                }))
              );
         
            setIsLoading(false)  
            setTotalPageNumber(totalPages || 0)
            setNumberOfTotalData(total || 0)
        }
    }

   

    const handleEditPost = (id) => {
        navigate(`/post/edit/${id}`, {
            state: {
                Pagetype: Pagetype,
            }
        });
    }


    const handleView = (id) => {
        navigate(`/post/view/${id}`);
    }

    const navigateToAddNewPost = () => {
        navigate("/post/add",
            {
                state: {
                    Pagetype: Pagetype,
                }
            }
        );
    }


    const handleTableSearch = (val) => {
        setsearchTxt(val)
    }

    return (
        <section className="dark:bg-gray-800 ">
            <div className="max-w-6xl px-4 py-4 mx-auto lg:py-8 md:px-6">
                <div className="pt-4 rounded shadow bg-white dark:bg-gray-900">

                    {
                        isLoading ?
                            <TableSkeleton /> :
                            <>
                                <TableEntry title={`List of ${Pagetype}`} navigate={navigateToAddNewPost} handleTableSearch={handleTableSearch} />
                                <CustomTable
                                    tableData={PostList}
                                    tableHeadingData={tableHeading}
                                    handleDelete={false}
                                    handleEdit={handleEditPost}
                                    handleView={handleView}
                                    actionField="Slug"
                                />
                                <TablePagination currentPage={currentPage} setCurrentPage={setCurrentPage} totalPagesNumber={totalPagesNumber} numberOfTotalData={numberOfTotalData} limit={limit} />
                            </>
                    }

                </div>
            </div>
        </section>
    )
}

export default PostList