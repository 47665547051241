 import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import APIServices from '../../../httpServices/httpServices';
import BabyForm from '../../container/baby/BabyForm';

    const EditBaby = () => {
        
    const { id } = useParams()
    const [dataToEdit, setDataToEdit] = useState([])
    useEffect(() => {
        if (id)
            getDetail()
        //eslint-disable-next-line
    }, [])
   
    const getDetail = async () => {
        const { data, success } = await new APIServices(`baby/${id}`).get()
        if (success) {
            let payload = {
                ...data?.babies,
                Status: data?.babies.Status === "Active" ? true : false,
               
                // MoodReminderIn: moment(data?.babies.MoodReminderIn).format('YYYY-MM-DD'),
                
            }
          
            setDataToEdit({ ...payload})
        }
    }


    return (
        <>
            <BabyForm data={dataToEdit} babyId={id} />
        </>
    )
}

export default EditBaby