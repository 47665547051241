import { Field, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import APIServices from '../../../httpServices/httpServices';
import FormikTextField from '../../common/form/FormikTextField';
import { emitErrorToast, emitSuccessToast } from '../../common/toast/ToastContainer';

import FormikTextAreaField from '../../common/form/FormikTextAreaField';
// import FormikDateField from '../../common/form/FormikDateField';
import { useNavigate } from 'react-router-dom';
// import FormikDateTimePickerField from '../../common/form/FormikDateTimePickerField';
import { useTranslation } from 'react-i18next';
import HealthTipsValidationSchema from '../../../validation/HealthTipsValidationSchema';

const TipsForm = ({ data, Slug }) => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const [initialValues, setInitialValues] = useState({
        Tips:"",
        Slug: "",
        SeoTitle:"",
        SeoDescription:"",
        Symptoms:"",
        Day:""

    })
    useEffect(() => {
        if (data)
            setInitialValues((prev) => ({
                ...prev,
                ...data,
            }));
    }, [data]);
    const handleTitleChange = (e, field, form) => {
        const titleValue = e.target.value;
        form.setFieldValue(field.name, titleValue);
        const slugValue = `Day-${parseInt(titleValue, 10)}`;
        form.setFieldValue("Slug", slugValue);
     }

    const handleFormikFormSubmit = async (values, actions) => {
        let { setSubmitting, resetForm } = actions
        let payload = {
            ...values,
        }

        const { success,  message } = !!!Slug ? await new APIServices(`health-tips`).post(payload) : await new APIServices(`health-tips/${Slug}`).put(payload)
        if (success) {
            setSubmitting(false);
            resetForm();
            emitSuccessToast(message)
            navigate(`/health-tips`)
        } else{
            emitErrorToast(message)
        }
    }

    return (
        <section className="py-16 bg-gray-100 dark:bg-gray-800">
            <div className="max-w-4xl px-4 mx-auto ">
                <Formik
                    initialValues={initialValues}
                    validationSchema={HealthTipsValidationSchema}
                    onSubmit={handleFormikFormSubmit}
                    enableReinitialize
                >
                    {
                formik => (
                            <Form>
                                <div>
                                    <h3 className="mb-2 text-base font-medium leading-6 text-gray-900 dark:text-gray-300">{t("Health Tips Information")}
                                    </h3>
                                    <div className="p-6 mb-4 bg-white rounded-md shadow dark:border-gray-800 dark:bg-gray-900">
                                    <label className="block mb-2 text-sm font-medium dark:text-gray-400" htmlFor="">
                                                    {t("Day")} *
                                                </label>
                                    <Field  type="text" name="Day" >
                                                    {({ field, form, meta }) => {
                                                        return <input
                                                            name="Day"
                                                            type="Number"
                                                            value={field.value || ""}
                                                            onChange={e => handleTitleChange(e, field, form)}
                                                            className={`block w-full px-4 py-2 text-sm bg-white border rounded`}
                                                        />
                                                    }}
                                                    </Field>
                                        <FormikTextField label={t("Slug")} name="Slug" readOnly formik={formik} />
                                        <FormikTextAreaField label={t("Tips *")} name="Tips" formik={formik} />
                                        <FormikTextAreaField label={t("Symptoms")} name="Symptoms" formik={formik} />
                                        <FormikTextField label={t("SeoTitle")} name="SeoTitle" formik={formik} />
                                        {/* <FormikSelectSingleField label="Weight Unit" name="Unit" options={weightUnitOptions} handleSelectSingle={handleSelectSingle} /> */}
                                        <FormikTextField label={t("Seo Description")} name="SeoDescription" formik={formik} />
                                       
                                </div>
                                {/* Submit */}
                                <div className="mt-7">
                                    <div className="flex justify-start space-x-2">
                                        <button type="submit"
                                            className="inline-block px-6 py-2.5 bg-blue-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-600">{t("Save")}</button>
                                    </div>
                                </div>
                                </div>
                            </Form>
                    )
                    }
                </Formik>
            </div>
        </section >
    )
}
 export default TipsForm;
