import * as yup from 'yup';


let ExternalOrganizationValidationSchema = yup.object().shape({
    Name: yup.string().required('Required !'),
    DevnagariName: yup.string().required('Required !'),
    Domain: yup.string().required('Required !'),

})

export default ExternalOrganizationValidationSchema