import { ADToBS } from "bikram-sambat-js";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from 'react-router-dom';
import APIServices from '../../../httpServices/httpServices';
const ViewWeight = () => {
  const {t} = useTranslation();
    const navigate = useNavigate();
    const { id } = useParams();
    // eslint-disable-next-line no-unused-vars
    const [showWeight, setShowweight] = useState(false);
    const [weightData, setWeightdata] = useState({});
    useEffect(() => {
        if (id)
            handleshowweight() 
        // eslint-disable-next-line
    }, [id])
const handleshowweight = async () => {
const { success, data } = await new APIServices(`appuser/get-weight/${id}`).get()
if (success) {
    
    setShowweight(true)
    setWeightdata(data)
    navigate(`/appuser/view/weight/${id}`, { replace: true });
}
};
return (
  <section className="py-8 px-5 lg:flex bg-gray-100  lg:h-screen font-poppins  ">
  <div className="justify-center flex-1 px-4 py-4 max-w-6xl mx-auto md:px-6">     
          <div className="flex flex-wrap items-center py-6 px-4 border-b bg-white">
              <h1 className="mb-4 text-xl font-bold md:mb-0 ">{t("List of Weight")}</h1>
              </div>
  <div className="shadow bg-white border-gray-500 rounded">
  <div className="overflow-y-auto max-h-96">
                                        <table className="w-full table-auto">
                                            <thead>
                                                <tr className="text-xs text-left text-gray-500 border-b border-gray-200">
                                                    <th className="px-9 py-3 font-large ">{t("Date")}</th>
                                                    <th className="px-9 py-3 font-large">{t("Weight")}</th>
                                                    <th className="px-9 py-3 font-large">{t("Week")}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                  (weightData.userweight?.length>0) ?
                                                    weightData.userweight?.map((item,index) => (
                                                        <tr key={index} className=" border-b border-gray-200">
                                                            <td className="px-9 py-6 text-sm font-medium"> {ADToBS(moment(item?.Date).format("YYYY-MM-DD"))}</td>
                                                            <td className="px-9 py-6 text-sm font-medium"> {item?.Weight} {item?.ShowWeightIn}</td>
                                                            <td className="px-9 py-6 text-sm font-medium">{item?.Week} </td>
                                                        </tr>
                                                    ))
                                                    :
                                                    <tr>
                                                    <td colSpan="3" className="px-6 py-3 text-sm font-medium">{t("No Record Found")}</td>
                                                </tr>
                                                }

                                            </tbody>
                                        </table>
                                    </div>
                                    </div>        
    </div>
    </section>
)
}
export default ViewWeight;