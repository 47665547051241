import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import APIServices from '../../../httpServices/httpServices';
import LocalBodyForm from '../../container/localbody/LocalBodyForm';

const EditLocalBody = () => {
    const { id } = useParams()
    const [dataToEdit, setDataToEdit] = useState([])
    useEffect(() => {
        if (id)
            getDetail()
        //eslint-disable-next-line
    }, [])

    const getDetail = async () => {
        const { data, success } = await new APIServices(`localbody/${id}`).get()
        if (success) {
            setDataToEdit(data?.localBodies)
        }

    }


    return (
        <>
            <LocalBodyForm data={dataToEdit} id={id} />
        </>
    )
}

export default EditLocalBody