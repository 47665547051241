import React from 'react'
import moment from 'moment/moment';
import { useState } from 'react';
import { v4 as uuid } from 'uuid';
import { useNavigate } from 'react-router-dom';
import { ADToBS } from 'bikram-sambat-js';

const CustomTableForAppUser = ({ tableHeadingData = [], tableData = [], ForEdit,ForView, actionField }) => {
    const navigate = useNavigate();
    // eslint-disable-next-line
    const [hasSelectAllFeature, sethasSelectAllFeature] = useState(false)
    const handleView = (id) => {
        navigate(`/${ForView}/view/${id}`);
    }
    const handleEdit = (id) => {
        navigate(`/${ForEdit}/edit/${id}`);
    }
    return (
        <div className="shadow pt-4 bg-white border-gray-500">
            <table className="w-full table-auto">
                <thead>
                    <tr className="text-xs text-left text-gray-500 border-b border-gray-200">
                        {
                            tableHeadingData.map((item, index) => {
                                if (index === 0 && hasSelectAllFeature) {
                                    return <th className={`px-6 py-3 text-sm font-medium dark:text-gray-400  && flex items-center`} key={uuid().slice(0, 8)}>
                                         <input className="mr-4" type="checkbox" name="" id="" />
                                        <span>{tableHeadingData[0].replace(/([a-z](?=[A-Z]))/g, '$1 ')}</span>
                                    </th>
                                } else {
                                    return <th className="px-6 pb-3 font-medium " key={uuid().slice(0, 8)}>{item.replace(/([a-z](?=[A-Z]))/g, '$1 ')} </th>
                                }
                            })
                        }
                    </tr>
                </thead>
                <tbody>
                {
                        !!tableData.length ? tableData.slice(0, 3).map((item, i) => {
                            return <tr className={`${i % 2 === 0 ? " bg-white" : "bg-white"} border-b text-sm dark:text-gray-400 dark:bg-gray-800 border-t`} key={uuid().slice(0, 8)}>
                                { 
                                    tableHeadingData.map((heading, index) => {
                                        if (index === 0 && hasSelectAllFeature) {
                                            return <td className="flex items-center px-6 py-3 text-sm font-medium" key={uuid().slice(0, 8)}>
                                                <input className="mr-4" type="checkbox" name="" id="" />
                                                <p className="">{item[heading] || "--"}</p>
                                            </td>
                                             } else if (heading === "Action") {
                                                return <td className=" px-6 py-5" key={uuid().slice(0, 8)}>
                                                    <div className="flex items-center w-full">
                                                        {
                                                            handleView && (
                                                                <button onClick={() => handleView(item[actionField])}>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="w-4 h-4 mr-2 bi bi-eye" viewBox="0 0 16 16">
                                                                        <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
                                                                        <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
                                                                    </svg>
                                                                </button>
                                                            )
                                                        }
    
                                                        {
                                                            handleEdit && <button onClick={() => handleEdit(item[actionField])}
                                                                className="font-medium text-blue-600 hover:text-blue-500 dark:hover:text-gray-300 dark:text-blue-300">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                                    fill="currentColor" className="w-4 h-4 mr-2 bi bi-pencil-square"
                                                                    viewBox="0 0 16 16">
                                                                    <path
                                                                        d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                                                    <path fillRule="evenodd"
                                                                        d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                                                                </svg>
                                                            </button>
                                                        }
                                                         </div>
                                            </td>
                                             } else if (heading === "Date") {
                                                return <td className="px-6 py-5 text-sm font-medium" key={uuid().slice(0, 8)}>
                                                    {ADToBS(moment(item?.Date).format("YYYY-MM-DD"))}
                                                </td>
                                             }else if (heading === "Mood") {
                                                return <td className="px-6 py-5 text-sm font-medium" key={uuid().slice(0, 8)}>
                                                    {item?.Mood === "Happy" && (
                                                                <div className="text-green-600">
                                                                    <svg xmlns="http://www.w3.  org/2000/svg" width="22" height="22" fill="currentColor" className="bi bi-emoji-laughing" viewBox="0 0 16 16">
                                                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                                                                        <path d="M12.331 9.5a1 1 0 0 1 0 1A4.998 4.998 0 0 1 8 13a4.998 4.998 0 0 1-4.33-2.5A1 1 0 0 1 4.535 9h6.93a1 1 0 0 1 .866.5M7 6.5c0 .828-.448 0-1 0s-1 .828-1 0S5.448 5 6 5s1 .672 1 1.5m4 0c0 .828-.448 0-1 0s-1 .828-1 0S9.448 5 10 5s1 .672 1 1.5" />
                                                                    </svg>
                                                                </div>
                                                            )}
                                                                {item?.Mood === "Sad" && (
                                                                    <div className="text-red-600">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" className="bi bi-emoji-frown" viewBox="0 0 16 16">
                                                                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                                                                            <path d="M4.285 12.433a.5.5 0 0 0 .683-.183A3.498 3.498 0 0 1 8 10.5c1.295 0 2.426.703 3.032 1.75a.5.5 0 0 0 .866-.5A4.498 4.498 0 0 0 8 9.5a4.5 4.5 0 0 0-3.898 2.25.5.5 0 0 0 .183.683M7 6.5C7 7.328 6.552 8 6 8s-1-.672-1-1.5S5.448 5 6 5s1 .672 1 1.5m4 0c0 .828-.448 1.5-1 1.5s-1-.672-1-1.5S9.448 5 10 5s1 .672 1 1.5" />
                                                                        </svg>
                                                                    </div>
                                                                )}
                                                                {item?.Mood === "Normal" && (
                                                                    <div className="text-yellow-600">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" className="bi bi-emoji-neutral" viewBox="0 0 16 16">
                                                                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                                                                            <path d="M4 10.5a.5.5 0 0 0 .5.5h7a.5.5 0 0 0 0-1h-7a.5.5 0 0 0-.5.5m3-4C7 5.672 6.552 5 6 5s-1 .672-1 1.5S5.448 8 6 8s1-.672 1-1.5m4 0c0-.828-.448-1.5-1-1.5s-1 .672-1 1.5S9.448 8 10 8s1-.672 1-1.5" />
                                                                        </svg>
                                                                    </div>)}
                                                </td>
                                                 }
                                                 else if (heading === "Weight") {
                                                    return <td className="px-6 py-5 text-sm font-medium" key={uuid().slice(0, 8)}>
                                                        {item?.Weight}  <span className="text-sm italic font-normal text-gray-500 ml-1"> {item?.ShowWeightIn}  </span>
                                                    </td>
                                                 }  else if (heading === "Height") {
                                                    return <td className="px-6 py-5 text-sm font-medium" key={uuid().slice(0, 8)}>
                                                        {item?.Height}  <span className="text-sm italic font-normal text-gray-500 ml-1"> {item?.ShowHeightIn}  </span>
                                                    </td>
                                                 }
                                                  else {
                                                    return <td className="px-6 py-5 text-sm font-medium " key={uuid().slice(0, 8)}>{item[heading] || "--"}</td>
                                                }
                                            })}
                                    </tr>
                                }) : <tr>
                                    <td colSpan={tableHeadingData.length} className="text-center py-12 text-3xl text-gray-500">No Data Found</td>
                                </tr>
                            }
        
                        </tbody>
                    </table>
                </div>
            )
        }
export default CustomTableForAppUser