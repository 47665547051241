// import { ADToBS } from "bikram-sambat-js";
// import { isAfter } from 'date-fns';
import * as yup from 'yup';
const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/gif", "image/png", "image/webp"];
let AppUserValidationSchema = yup.object().shape({
  Status: yup.boolean(),
  Name: yup.string().required('Required !'),
  AliasName: yup.string(),
  UserName: yup.string().required('Required !'),
  // UserPass: yup.string().required('Required !'),
  Email: yup.string().email('Invalid email address').required('Required !'),
  ProfileImage: yup.mixed().notRequired()
    .nullable()
    .test('fileSize',
      'The file is too large', (value) => {
        if (!value) return true
        return !value || (value && value.size <= (1024 * 1024) * 2)
      })
    .test('fileFormat',
      'Unsupported File Format', (value) => !value || (value && SUPPORTED_FORMATS.includes(value.type)))
  // DOB: yup
  //     .date()
  //     .max(ADToBS(new Date()), 'Date must be in the past!')
  //     .transform((value, originalValue) => {
  //       if (originalValue) {
  //         console.log("originalvalue",originalValue)
  //         console.log("first",ADToBS(new Date()))
  //         if (isAfter(originalValue, ADToBS(new Date()))) {
  //           return ADToBS(new Date()); // Replace with desired behavior, e.g. throw error or set default
  //         }
  //       }
  //       return value;
  //     }),        
});


export default AppUserValidationSchema