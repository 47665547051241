import { ADToBS } from "bikram-sambat-js";
import DOMPurify from 'dompurify';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import APIServices from '../../../httpServices/httpServices';
import CustomImage from '../../common/CustomImage';
import DeleteModal from '../../common/modals/DeleteModal';
import { emitSuccessToast } from '../../common/toast/ToastContainer';



const ViewPosts = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { id } = useParams();
    const [postDetailData, setPostDetailData] = useState("");
    const [showModal, setShowModal] = useState(false)
    const [showIframe, setShowIframe] = useState(false)

    const getId = (url) => {
        const videoid = url?.split("v=")[1];
        return videoid;
      };
    
      const myId = getId(postDetailData.Video);

    useEffect(() => {
        if (id) {
            getPostDetail()
        }
        // eslint-disable-next-line
    }, [])


    // Fetch User Data 
    const getPostDetail = async () => {
        const { data, success } = await new APIServices(`post/${id}`).get()
        if (success) {
            setPostDetailData(data)
        }
    }

    const handleShowDeleteModal = () => {
        setShowModal(true)
    }


    const handleDeletePost = async () => {
        const { message, success } = await new APIServices(`post/${id}`).delete()
        if (success) {
            setShowModal(false)
            emitSuccessToast(message)
            navigate("/post");
        }
    }
    const handleurl = async () => {
        window.open(postDetailData.Video)
    }

    const { PageType, FeaturedImage } = postDetailData
    const handleEditPost = (id) => {
        navigate(`/post/edit/${id}`, {
            state: {
                Pagetype: PageType,
            }
        });
    }


    const isExternalImage = FeaturedImage && FeaturedImage.startsWith('https://');
    return (
        <section className="pb-16 pt-8 bg-white dark:bg-gray-800 ">
            <div className="max-w-4xl px-4 mx-auto">

                <h2 className="text-2xl font-semibold mb-1 font-poppins dark:text-gray-300">{postDetailData?.Title}
                    <button
                        onClick={() => handleEditPost(id)}
                        className="font-medium text-blue-600 hover:text-blue-500 dark:hover:text-gray-300 dark:text-blue-300 ml-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                            fill="currentColor" className="w-4 h-4 mr-2 bi bi-pencil-square"
                            viewBox="0 0 16 16">
                            <path
                                d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                            <path fillRule="evenodd"
                                d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                        </svg>
                    </button></h2>
                <span className='mb-7 block text-sm text-gray-600'> {t("Published On")} : {ADToBS(moment(postDetailData?.PublishedOn).format("YYYY-MM-DD"))}</span>

                {isExternalImage ?
                    <img src={FeaturedImage} alt="img"
                        className="object-contain h-90 w-90 max-w-xs rounded-md" />
                    :
                    <CustomImage alt="" source={FeaturedImage ? FeaturedImage : ""} className="object-contain h-90 w-90 max-w-xs rounded-md" />
                }
                <br></br>
                {PageType === "others-video" && (
                    <button onClick={handleurl} className="inline-block flex items-center px-4 py-2.5 bg-gray-700 text-white font-small text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-600 mb-7">
                        {t("Video Source")}
                    </button>
                )}
 {PageType === "others-video" && (
                <button onClick={() => setShowIframe(true)} className='inline-block flex items-center px-4 py-2.5 bg-gray-700 text-white font-small text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-600 mb-7'><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
  <path stroke-linecap="round" stroke-linejoin="round" d="M15.91 11.672a.375.375 0 0 1 0 .656l-5.603 3.113a.375.375 0 0 1-.557-.328V8.887c0-.286.307-.466.557-.327l5.603 3.112Z" />
</svg>
 Watch Now </button>
 )}

                {/* Overlay */}
                
                  {showIframe && (
                    <div onClick={() => setShowIframe(false)} className='fixed inset-0 bg-gray-800/70 z-50 flex justify-center items-center'>
                      {PageType === "others-video" && (
                        <iframe title="video" width="560" height="315" src={`https://www.youtube.com/embed/${myId}`} frameborder="0" allowfullscreen></iframe>
                      )}
                    </div>
                  )}
                  
                

                <div className="pt-5 pb-5 dark:text-gray-400 " dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(postDetailData.Detail) }} />
                <br></br>
                {postDetailData.Status === "Inactive" && (
                    <button onClick={handleShowDeleteModal} className="inline-block flex items-center px-4 py-2.5 bg-red-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-red-600 mb-5 ">
                        {t("Delete")}
                    </button>
                )}
            </div>
            <br></br>
            <br></br>

            {
                showModal && <DeleteModal handleDelete={handleDeletePost} close={setShowModal} />
            }

        </section>
    )
}

export default ViewPosts