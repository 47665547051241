import { configureStore, combineReducers, getDefaultMiddleware } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga'

import authReducer from './features/auth/authSlice';
import userReducer from './features/user/userSlice';
import rootSaga from './sagas';
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web


const sagaMiddleware = createSagaMiddleware()

const rootReducer = combineReducers({
    auth: authReducer,
    user: userReducer

})

const persistConfig = {
    key: 'root',
    version: 1,
    storage,
    whitelist: ['auth', 'user'],
};


const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
    reducer: persistedReducer,
    middleware: [
        ...getDefaultMiddleware({ thunk: false, serializableCheck: false }),
        sagaMiddleware,
    ],
    devTools: process.env.NODE_ENV !== 'production',

});

export let persistor = persistStore(store)


sagaMiddleware.run(rootSaga)
