import { all, takeLatest } from 'redux-saga/effects';
import { sagaActionTypes } from "./actions";
import { doLogin, doLogout } from "./authSagas";
import { getUserProfile } from "./userSaga";

function* watchGeneralRequest() {
    yield takeLatest(sagaActionTypes.LOGIN, doLogin)
    yield takeLatest(sagaActionTypes.lOGOUT, doLogout)
    yield takeLatest(sagaActionTypes.GET_USER_PROFILE, getUserProfile)
}

export default function* rootSaga() {
    yield all([watchGeneralRequest()])
}