import { Form, Formik } from 'formik'
import React, { useState } from 'react'
import { useDispatch } from "react-redux"
import { Link } from 'react-router-dom'
import { LOGIN } from '../../../redux/sagas/actions'
import LoginValidationSchema from '../../../validation/LoginValidationSchema'
import FormikTextField from '../../common/form/FormikTextField'


const Login = () => {
    const dispatch = useDispatch()
    //eslint-disable-next-line
    const [initialValues, setinitialValues] = useState({
        Email: "",
        UserPass: ""
    })

    const handleFormikFormSubmit = async (values, actions) => {
        const { setFieldError } = actions
        const payload = {
            data: {
                ...values,
                setFieldError
            },
            url: "auth/login",
            setFieldError
        }
        dispatch(LOGIN(payload))
    }

    return (
        <section className="bg-gray-100">
            <div className="max-w-md mx-auto">
                <div
                    className="flex items-center justify-center h-screen ">
                    <div className="p-10 w-full bg-white dark:bg-gray-900 h-100 shadow rounded">
                        <div className="mb-4 text-center">
                            <h3 className="text-2xl font-bold"> Sign In </h3>
                        </div>
                        {
                            <Formik
                                initialValues={initialValues}
                                validationSchema={LoginValidationSchema}
                                onSubmit={(values, actions) => handleFormikFormSubmit(values, actions)}
                                enableReinitialize
                            >
                                {
                                    formik => {
                                        return (
                                            <Form>
                                                <div className="mb-6">
                                                    <FormikTextField label="Email *" name="Email" type="email" formik={formik} />
                                                </div>
                                                <div className="mb-6">
                                                    <FormikTextField label="Password *" name="UserPass" type="password" formik={formik} />
                                                </div>
                                                {/* Submit */}
                                                <div className=" text-center">
                                                    <button
                                                        className="w-full px-4 py-2 font-bold rounded text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:shadow-outline"
                                                        type="submit"
                                                    >
                                                        Login
                                                    </button>
                                                </div>
                                            </Form>
                                        )
                                    }
                                }
                            </Formik>
                        }
                        <div className="mt-2 text-right">
                            <Link to="/auth/forgot-password" className="text-sm hover:text-blue-700 text-gray-500 cursor-pointer">Forgot
                                Password ?</Link>
                        </div>
                    </div>
                </div>

            </div>
        </section>
    )
}

export default Login