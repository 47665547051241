import React, { useEffect, useState } from 'react';
import APIServices from '../../../httpServices/httpServices';
import DataView from '../../common/viewDetail/DataView';
import moment from 'moment';
import CustomImage from '../../common/CustomImage';

const Profile = () => {
    
    
    const [userData, setUserData] = useState([]);

    useEffect(() => {
        getUserDetailView()
    }, [])


    // Fetch User Data 
    const getUserDetailView = async () => {
        const { data } = await new APIServices(`user/mydetails/`).get()
        if (data) {
            setUserData(data)
        }
    }

    const {
        Address, AliasName, Cellphone,
        DOB, Email, FullName,
        Gender, Phone, ProfileImage,
         Remarks, Role,
        Status, UserName,  UserType } = userData

    return (
        <div className="max-w-6xl px-4 py-4 mx-auto lg:py-8 md:px-6">
            <div className="flex gap-x-4">
                <div className="user-profile w-[180px] h-[180px]">
                <CustomImage alt="person" source={`${ProfileImage ? ProfileImage : " "}`} className="employee-avatar object-cover h-full w-full rounded-md" />
                </div>
                <div className="flex-1">
                    <div className="font-bold text-2xl" name="">{FullName}</div>
                    <div className="mb-3 text-gray-500">
                        <span className="text-base">{Email}</span>
                    </div>
                    <div className="grid grid-cols-3 gap-x-2">

                        <div className="flex flex-col">
                            <span className="block mb-0 text-sm text-gray-600 font-normal">Date of Birth </span>
                            <span className="font-bold">{moment(DOB).format("YYYY-MM-DD")}</span>
                        </div>

                        <div className="flex flex-col">
                            <span className="block mb-0 text-sm text-gray-600 font-normal"> Phone </span>
                            <span className="mb-5 text-base font-semibold text-gray-800"> {Phone ? Phone : "--"} </span>
                        </div>

                        <div className="flex flex-col">
                            <span className="block mb-0 text-sm text-gray-600 font-normal"> Cellphone </span>
                            <span className="mb-5 text-base font-semibold text-gray-800"> {Cellphone ? Cellphone : "--"}</span>
                        </div>


                    </div>

                    <div className="flex items-center justify-between">
                        <div className="flex items-center">

                            <div className={`rounded-full w-3 h-3 bg-green-700 mr-2 ${Status === "Active" ? "bg-green-700" : "bg-red-700"}`}></div>
                            <span className="text-sm"> {Status} </span>

                        </div>

                    </div>

                </div>
            </div>
<br></br>
            <div className="shadow bg-white border-gray-500 p-8 rounded ">
                <h2 className="font-bold text-lg  mb-8"> Personal Information </h2>
                <div className="flex flex-wrap">
                    <DataView label="UserName" value={UserName} />
                    <DataView label="Alias Name" value={AliasName} />
                    <DataView label="Role" value={Role} />
                    <DataView label="UserType" value={UserType} />
                    <DataView label="Address" value={Address} />
                    <DataView label="Remarks" value={Remarks} />
                    <DataView label="Gender" value={Gender} />
                </div>
            </div>
        </div>
    )
}

export default Profile