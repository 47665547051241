import { Form, Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import APIServices from '../../../httpServices/httpServices'
import RecoverPasswordValidationSchema from '../../../validation/RecoverPasswordValidation'
import FormikTextField from '../../common/form/FormikTextField'
import { emitErrorToast, emitSuccessToast } from '../../common/toast/ToastContainer'

const UserChangePassword = () => {
    const navigate = useNavigate()
    const location = useLocation();
    const [userId, setUserId] = useState("")
    //eslint-disable-next-line
    const [initialValues, setinitialValues] = useState({
        Password: "",
        ConfirmPassword: ""
    })

    useEffect(() => {
        if (!location?.state) {
            navigate(`/`)
        } else {
            const { userId: id } = location.state
            setUserId(id)
        }
        //eslint-disable-next-line
    }, [location])


    const handleFormikFormSubmit = async (values, actions) => {
        const { success, message } = await new APIServices(`user/change-password/${userId}`).post(values)
        if (success) {
            emitSuccessToast("Password changed successfully")
            navigate(`/users/view/${userId}`)
        } else {
            emitErrorToast(message)
        }

    }

    return (
        <section className="bg-gray-100">
            <div className="max-w-md mx-auto">
                <div className="flex items-center justify-center h-screen " >
                    <div className="p-10 rounded shadow w-full bg-white p-10">
                        <div className="mb-6 text-center">
                            <h3 className="text-2xl font-bold"> Change Your Password </h3>
                        </div>
                        <Formik
                            initialValues={initialValues}
                            validationSchema={RecoverPasswordValidationSchema}
                            onSubmit={handleFormikFormSubmit}
                            enableReinitialize
                        >
                            {
                                formik => (
                                    <Form>
                                        <div className="mb-6">
                                            <FormikTextField label="New Password *" name="Password" type="password" formik={formik} />
                                        </div>
                                        <div className="mb-6">
                                            <FormikTextField label="Confirm Password *" name="ConfirmPassword" type="password" formik={formik} />
                                        </div>
                                        {/* Submit */}
                                            <button
                                                className="w-full px-4 py-2 font-bold rounded text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:shadow-outline"
                                                type="submit"
                                            >
                                                Change Password
                                            </button>
                                    </Form>
                                )
                            }
                        </Formik>

                    </div>
                </div>
            </div>
        </section>
    )
}

export default UserChangePassword