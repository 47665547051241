import * as yup from 'yup';

let SettingValidationSchema = yup.object().shape({
    Status: yup.boolean(),
    Title: yup.string().max(20, 'Must be 15 characters or less').required('Required !'),
    UniqueName: yup.string().max(20, 'Must be 15 characters or less').required('Required !'),
    SettingValue: yup.array().nullable(),

});


export default SettingValidationSchema