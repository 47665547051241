import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from "react-router-dom";
import APIServices from '../../../httpServices/httpServices';
import { LIMIT } from '../../../utilities/constant';
import CustomTable from '../../common/CustomTable';
import DeleteModal from '../../common/modals/DeleteModal';
import TableSkeleton from '../../common/skeleton/TableSkeleton';
import TableEntry from '../../common/table/TableEntry';
import TablePagination from '../../common/table/TablePagination';
import { emitSuccessToast } from '../../common/toast/ToastContainer';

const Emergencylist = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    //eslint-disable-next-line 
    const [tableHeading, setTableHeading] = useState(["ContactName","ContactMobile","ContactPhone","Action"])
    //eslint-disable-next-line 
    const [selectedItemId, setSelectedItemId] = useState(null)
    const [showModal, setShowModal] = useState(false)

    //PAGINATION STATE
    const [searchTxt, setsearchTxt] = useState("")
    const [currentPage, setCurrentPage] = useState(1)
    const limit = LIMIT
    const [numberOfTotalData, setNumberOfTotalData] = useState(0)
    const [totalPagesNumber, setTotalPageNumber] = useState(0)
    const [isLoading, setIsLoading] = useState(true)
    const [EmergencylistData, setEmergencylistData] = useState([])
    const {id} = useParams();
    useEffect(() => {
        let timeout;
        if (!!searchTxt) {
            timeout = setTimeout(() => {
                getListOfDataToShowOnTable()
            }, 1500)
        } else {
            getListOfDataToShowOnTable()
        }
        return () => clearTimeout(timeout)
        //eslint-disable-next-line 
    }, [searchTxt, currentPage])
    const getListOfDataToShowOnTable = async () => {
        const { data, success, total, totalPages } = await new APIServices(`emergency/list/${id}`).post({
            page: currentPage,
            limit: limit,
            search: searchTxt,
        })
      
        if (success) {
            setEmergencylistData(
                data.map((item) => ({
                  ...item
                })));
              
            setIsLoading(false)  
            setTotalPageNumber(totalPages || 0)
            setNumberOfTotalData(total || 0)
       }
    }
     


    

    // const handleShowDeleteModal = (id) => {
    //     setSelectedItemId(id)
    //     setShowModal(true)
    // }


    const handleDelete = async () => {
        const { message, success } = await new APIServices(`emergency/${selectedItemId}`).delete()
        if (success) {
            setShowModal(false)
            emitSuccessToast(message)
            getListOfDataToShowOnTable()
        }
       
    }

    const handleEdit = (id) => {
        navigate(`/emergency/edit/${id}`);
    }

    const handleView = (id) => {
        navigate(`/emergency/view/${id}`);
    }

    const handleTableSearch = (val) => {
        setsearchTxt(val)
    }
  
    return (
        <section className="dark:bg-gray-800 ">
            <div className="max-w-6xl px-4 py-4 mx-auto lg:py-8 md:px-6">
                <div className="pt-4 rounded shadow bg-white dark:bg-gray-900">

                    {
                        isLoading ?
                            <TableSkeleton /> :
                            <>
                                <TableEntry title={t("List of Emergency Informations")}  handleTableSearch={handleTableSearch} />
                                <CustomTable
                                    tableData={EmergencylistData}
                                    tableHeadingData={tableHeading}
                                    handleDelete={false}
                                    handleEdit={handleEdit}
                                    handleView={handleView}
                                    actionField="_id"
                                />
                                <TablePagination currentPage={currentPage} setCurrentPage={setCurrentPage} totalPagesNumber={totalPagesNumber} numberOfTotalData={numberOfTotalData} limit={limit} />
                            </>
                    }

                </div>
            </div>
            {
                showModal && <DeleteModal handleDelete={handleDelete} close={setShowModal} />            }

        </section>
    )
}

export default Emergencylist