import moment from "moment";
import { ADToBS } from "bikram-sambat-js";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from 'react-router-dom';
import APIServices from '../../../httpServices/httpServices';
const ViewMood = () => {
    const { t } = useTranslation();
    // const navigate = useNavigate();
    const { id } = useParams();
    // eslint-disable-next-line no-unused-vars
    const [showMood, setShowMood] = useState(false);
    const [moodData, setMooddata] = useState({});
    useEffect(() => {
        if (id)
            handleShowmood()
        // eslint-disable-next-line
    }, [])

    const handleShowmood = async () => {
        const { success, data } = await new APIServices(`appuser/get-mood/${id}`).get()
        if (success) {
            
            setShowMood(true)
           
            setMooddata(data)

        }
    };
    return (
        <section className="py-8 px-5 lg:flex bg-gray-100  lg:h-screen font-poppins  ">
            <div className="justify-center flex-1 px-4 py-4 max-w-6xl mx-auto md:px-6">

                <div className="flex flex-wrap items-center py-6 px-4 border-b bg-white">
                    <h1 className="mb-4 text-xl font-bold md:mb-0 ">{t("List of Mood")}</h1>
                </div>
                <div className="shadow bg-white border-gray-500 rounded">
                    <div className="overflow-y-auto max-h-96">
                        <table className="w-full table-auto">
                            <thead>
                                <tr className="text-xs text-left text-gray-500 border-b border-gray-200">
                                    <th className="px-9 py-3 font-large ">{t("Date")}</th>
                                    <th className="px-9 py-3 font-large">{t("Mood")}</th>
                                    <th className="px-9 py-3 font-large">{t("Time")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    (moodData.usermood?.length > 0) ?
                                        moodData.usermood?.map((item, index) => (
                                            <tr key={index} className=" border-b border-gray-200">
                                                <td className="px-9 py-6 text-sm font-medium"> {ADToBS(moment(item?.Date).format("YYYY-MM-DD"))}</td>
                                                <td className="px-9 py-6 text-sm font-medium">
                                                    {item?.Mood === "Happy" && (
                                                        <div className="text-green-600">
                                                            <svg xmlns="http://www.w3.  org/2000/svg" width="28" height="28" fill="currentColor" className="bi bi-emoji-laughing" viewBox="0 0 16 16">
                                                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                                                                <path d="M12.331 9.5a1 1 0 0 1 0 1A4.998 4.998 0 0 1 8 13a4.998 4.998 0 0 1-4.33-2.5A1 1 0 0 1 4.535 9h6.93a1 1 0 0 1 .866.5M7 6.5c0 .828-.448 0-1 0s-1 .828-1 0S5.448 5 6 5s1 .672 1 1.5m4 0c0 .828-.448 0-1 0s-1 .828-1 0S9.448 5 10 5s1 .672 1 1.5" />
                                                            </svg>
                                                        </div>
                                                    )}
                                                    {item?.Mood === "Sad" && (
                                                        <div className="text-red-600">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="currentColor" className="bi bi-emoji-frown" viewBox="0 0 16 16">
                                                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                                                                <path d="M4.285 12.433a.5.5 0 0 0 .683-.183A3.498 3.498 0 0 1 8 10.5c1.295 0 2.426.703 3.032 1.75a.5.5 0 0 0 .866-.5A4.498 4.498 0 0 0 8 9.5a4.5 4.5 0 0 0-3.898 2.25.5.5 0 0 0 .183.683M7 6.5C7 7.328 6.552 8 6 8s-1-.672-1-1.5S5.448 5 6 5s1 .672 1 1.5m4 0c0 .828-.448 1.5-1 1.5s-1-.672-1-1.5S9.448 5 10 5s1 .672 1 1.5" />
                                                            </svg>
                                                        </div>
                                                    )}
                                                    {item?.Mood === "Normal" && (
                                                        <div className="text-yellow-600">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="currentColor" className="bi bi-emoji-neutral" viewBox="0 0 16 16">
                                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                                                        <path d="M4 10.5a.5.5 0 0 0 .5.5h7a.5.5 0 0 0 0-1h-7a.5.5 0 0 0-.5.5m3-4C7 5.672 6.552 5 6 5s-1 .672-1 1.5S5.448 8 6 8s1-.672 1-1.5m4 0c0-.828-.448-1.5-1-1.5s-1 .672-1 1.5S9.448 8 10 8s1-.672 1-1.5"/>
                                                        </svg>
                                                   </div> )}
                                                </td>
                                                <td className="px-9 py-6 text-sm font-medium">{item?.Time} </td>
                                            </tr>
                                        ))
                                        :
                                        <tr>
                                            <td colSpan="3" className="px-6 py-3 text-sm font-medium">{t("No Record Found")}</td>
                                        </tr>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>


            </div>
        </section>
    )
}
export default ViewMood;