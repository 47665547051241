import React from 'react';
import { useTranslation } from 'react-i18next';

const Footer = () => {
    const {t}= useTranslation();
    return (
       
        <div className="px-4 py-6 bg-white dark:bg-gray-800 dark:text-gray-400 shadow">
            <div className="flex flex-wrap items-center justify-center">
                <div className="">{t("© Copyright 2022 . All Rights Reserved")} </div>
            </div>
        </div>
    )
}

export default Footer