import React from 'react'
import SettingsForm from '../../container/settings/SettingsForm'

const AddSetting = () => {

    return (
        <div><SettingsForm /></div>
    )
}

export default AddSetting