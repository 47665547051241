import React from 'react';
import BabyNamesForm from '../../container/babynames/babynamesForm';

const AddBabyNames = () => {

    return (
        <div><BabyNamesForm /></div>
    )
}

export default AddBabyNames