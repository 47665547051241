import { ErrorMessage, Field } from "formik";
import React from "react";
import { TextError } from "../TextError";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { NepaliDatePicker } from "nepali-datepicker-reactjs";
import moment from "moment";

const FormikDateTimePickerField = ({ label, name, formik }) => {
  const { t } = useTranslation();
  return (
    <div>
      <label
        className="block mb-2 text-sm font-medium dark:text-gray-400"
        htmlFor=""
      >
        {t(label)}
      </label>
      <Field name={name}>
        {({ field, form, meta }) => {
          return (
            <NepaliDatePicker inputClassName="form-control"
                  className={`block w-full px-4 py-3 mb-2  placeholder-gray-500 bg-white border rounded dark:text-gray-400 dark:placeholder-gray-500 dark:border-gray-800 dark:bg-gray-800 
                        `}
                      value={field.value || ""}
                      onChange={(date) =>
                                        form.setFieldValue(
                                          field.name,
                                          moment(date).format("YYYY-MM-DD")
                                        )}
                  options={{ calenderLocale: "ne", valueLocale: "en" }} />
            
          );
        }}
      </Field>
      <ErrorMessage name={name} component={TextError} />
    </div>
  );
};

export default FormikDateTimePickerField;
