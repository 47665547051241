import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import APIServices from '../../../httpServices/httpServices';
import FormikImageField from '../../common/form/FormikImageField';
import FormikRichTextEditor from '../../common/form/FormikRichTextEditor';
import FormikTextAreaField from '../../common/form/FormikTextAreaField';
import FormikTextField from '../../common/form/FormikTextField';
import { emitSuccessToast } from '../../common/toast/ToastContainer';
import PregnancyWeekValidationSchema from '../../../validation/PregnancyWeekValidationSchema';

// import ProvinceValidationSchema from '../../../validation/ProvinceValidationSchema';

const PregnancyWeekForm = ({ data, id }) => {
    const {t}= useTranslation()
    const navigate = useNavigate()
    //eslint-disable-next-line
    const [weightUnitOptions, setWeightUnitOptions] = useState([])
    const [initialValues, setInitialValues] = useState({
        Week: "",
        Size: "",
        Height: "",
        HeightDescription: "",
        Weight: "",
        Unit: "",
        HeartRate: "",
        HcGNorms: "",
        CompareImage: "",
        BabySizeImage: "",
        Detail: "",
        NutritionDetail:"",
        BabyDetail:""
    })
    useEffect(() => {
        getFormSelectFieldValues()
    }, [])


    useEffect(() => {
        if (data)
            setInitialValues((prev) => ({
                ...prev,
                ...data,
            }));
    }, [data]);

    const handleFormikFormSubmit = async (values, actions) => {
        let { setSubmitting, resetForm } = actions
        let payload = {
            ...values,
        }
        let formData = new FormData();

        Object.keys(payload).forEach((key, index) => {
            formData.append(key, payload[key]);
        });
        const { success, data, message } = !!!id ? await new APIServices(`pregnancy-week`).post(formData) : await new APIServices(`pregnancy-week/${id}`).put(formData)
        if (success) {
            setSubmitting(false);
            resetForm();
            emitSuccessToast(message)
            navigate(`/pregnancy-week/view/${data._id}`, { replace: true })
        }

    }
    const getFormSelectFieldValues = async () => {
        const weightUnit = ['Kg', 'lbs']
        let options = weightUnit.map((item) => ({ value: item, label: item }))
        setWeightUnitOptions(options)
    }

    // const handleSelectSingle = (selectedOption, fieldName, setFieldValue) => {
    //     setFieldValue(fieldName, selectedOption.value)
    // }

    return (
        <section className="py-16 bg-gray-100 dark:bg-gray-800">
            <div className="max-w-6xl px-4 mx-auto ">
                <Formik
                    initialValues={initialValues}
                    validationSchema={PregnancyWeekValidationSchema}
                    onSubmit={handleFormikFormSubmit}
                    enableReinitialize
                >
                    {
                        formik => (
                            <Form>
                                <div>
                                    <h3 className="mb-2 text-base font-medium leading-6 text-gray-900 dark:text-gray-300">{t("Pregnancy Week's Information")}
                                    </h3>
                                    <div className="p-6 mb-4 bg-white rounded-md shadow dark:border-gray-800 dark:bg-gray-900">
                                        <FormikTextField label={t("Week*")} name="Week" type="number" formik={formik} />
                                        <FormikTextField label={t("Size*")} name="Size" formik={formik} />
                                        <FormikTextField label={t("Height")} name="Height" formik={formik} />
                                        <FormikTextAreaField label={t("HeightDescription*")} name="HeightDescription" formik={formik} />
                                        <FormikTextField label={t("Weight")} name="Weight" formik={formik} />
                                        {/* <FormikSelectSingleField label="Weight Unit" name="Unit" options={weightUnitOptions} handleSelectSingle={handleSelectSingle} /> */}
                                        <FormikTextField label={t("Heart Rate")} name="HeartRate" formik={formik} />
                                        <FormikTextField label={t("HCG Norms")} name="HcGNorms" formik={formik} />
                                        <div className='grid grid-cols-2 mt-4'>
                                            <FormikImageField label={t("Compare Image")} name="CompareImage" formik={formik} />

                                            <FormikImageField label={t("Baby Size Image")} name="BabySizeImage" formik={formik} />
                                        </div>
                                        <FormikRichTextEditor label={t("Mom Detail")} name="Detail" formik={formik} />
                                        <FormikRichTextEditor label={t("Baby Detail")} name="BabyDetail" formik={formik} />
                                        <FormikRichTextEditor label={t("Nutrition Detail")} name="NutritionDetail" formik={formik} />
                                    </div>
                                    
                                </div>

                                {/* Submit */}
                                <div className="mt-7">
                                    <div className="flex justify-start space-x-2">
                                        <button type="submit"
                                            className="inline-block px-6 py-2.5 bg-blue-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-600">{t("Save")}</button>
                                    </div>
                                </div>
                            </Form>
                        )
                    }
                </Formik>
            </div>
        </section >
    )
}

export default PregnancyWeekForm