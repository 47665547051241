import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import APIServices from "../../../httpServices/httpServices";
import CustomImage from '../../common/CustomImage';
import DeleteModal from "../../common/modals/DeleteModal";
import { emitSuccessToast } from "../../common/toast/ToastContainer";
import DataView from "../../common/viewDetail/DataView";


const ViewHealthCenter = () => {
  const {t}= useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const [showModal, setShowModal] = useState(false);
  const [healthCenterData, setHealthCenterData] = useState([]);

  useEffect(() => {
    if (id) {
      getHealthCenterDetailView();
    }
    //eslint-disable-next-line
  }, []);

  // Fetch User Data
  const getHealthCenterDetailView = async () => {
    const { data} = await new APIServices(
      `health-center/${id}`
    ).get();
    if (data) {
      setHealthCenterData(data);
    }
  };

  const handleShowDeleteModal = (id) => {
    setShowModal(true);
  };

  const handleDelete = async () => {
    const { message, success } = await new APIServices(
      `health-center/${id}`
    ).delete();
    if (success) {
      setShowModal(false);
      emitSuccessToast(message);
      navigate("/health-center");
    }
  };
  const handleEdit = (id) => {
    navigate(`/health-center/edit/${id}`);
  };

  const { _id, Name, Slug, DevnagariName, Status, Image, UserName } = healthCenterData;

  return (
    <div className="max-w-6xl px-4 py-4 mx-auto lg:py-8 md:px-6">
      <div className="flex justify-between mt-5 mb-4 items-end">
        <h2 className="font-bold text-lg"> {t("Health Center's Information")} </h2>  
        {Status === "Inactive" && (
          <button
            onClick={() => handleShowDeleteModal(_id)}
            className="inline-block flex items-center px-4 py-2.5 bg-red-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-red-600"
          >
          
            {t("Delete")}
          </button>
        )}
         <button
            onClick={() => handleEdit(_id)}
            className="inline-block flex items-center px-4 py-2.5 bg-blue-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-600"
          >
            {t("Update")}
          </button>
          
      </div>
      <div className='shadow bg-stone-100 border-gray-500 p-8 rounded' >
        <div className="text-left">
            <p>{t("Health Center Image")}</p>
            <br></br>
            <CustomImage alt="" source={Image ? Image: ""} className="object-contain h-40 w-40 max-w-xs rounded-md object-left" />
           </div>
           </div>
      <div className="shadow bg-white border-gray-500 p-8 rounded">
        <div className="flex flex-wrap">
          <DataView label={t("Name")} value={Name} />
          <DataView label={t("UserName")} value={UserName} />
          <DataView label={t("Slug")} value={Slug} />
          <DataView label={t("Devnagari Name")} value={DevnagariName} />
          <DataView label={t("Contact No.")} value={healthCenterData?.ContactNo} />
          <DataView label={t("Country")} value={healthCenterData?.Country} />
          <DataView label={t("Province")} value={healthCenterData?.Province} />
          <DataView label={t("District")} value={healthCenterData?.District} />
          <DataView
            label={t("Local Bodies")}
            value={healthCenterData?.LocalBodies}
          />
          <DataView label={t("Ward No.")} value={healthCenterData?.WardNo} />
          <DataView label={t("Tole")} value={healthCenterData?.Tole} />
          <DataView label={t("Address")} value={healthCenterData?.Address} />
          <DataView label={t("Latitiude")} value={healthCenterData?.Latitude} />
          <DataView label={t("Longitude")} value={healthCenterData?.Longitude} />
          <DataView
            label={t("Birthing Center")}
            value={healthCenterData?.IsBirthingCenter}
          />
          <DataView
            label={t("Emergency Service")}
            value={healthCenterData?.IsEmergencyService}
          />
          <DataView label={t("Status")} value={Status}/>
        </div>
      </div>
      <div className="flex justify-between items-end ">
    <h2 className="font-bold text-lg mt-8 ">{t("Main Person's Information")}</h2>
</div>
      <div className="shadow bg-white border-gray-500 p-8 rounded mt-5">
        <div className="flex flex-wrap ">
          <DataView label={t("Full Name")} value={healthCenterData?.MainPerson?.FullName}/>
          <DataView label={t("Email")} value={healthCenterData?.MainPerson?.Email}/>
          <br></br>
          <DataView label={t("Phone")} value={healthCenterData?.MainPerson?.Phone}/>
          <DataView label={t("Address")} value={healthCenterData?.MainPerson?.Address}/>
          <DataView label={t("Main Person Image")} value={<CustomImage alt="" source={healthCenterData?.MainPerson?.Image ? healthCenterData?.MainPerson?.Image: ""} className="object-contain h-40 w-40 max-w-xs rounded-md mt-4 mr-20 "/>}/>
            </div>
            </div>
  <div className="flex justify-between items-end ">
    <h2 className="font-bold text-lg mt-8 ">{t("Responsible Person's Information")}</h2>
</div>
      <div className="shadow bg-white border-gray-500 p-8 rounded mt-5">
        <div className="flex flex-wrap ">
          <DataView label={t("Full Name")} value={healthCenterData?.ResponsiblePerson?.FullName}/>
          <DataView label={t("Email")} value={healthCenterData?.ResponsiblePerson?.Email}/>
          <br></br>
          <DataView label={t("Phone")} value={healthCenterData?.ResponsiblePerson?.Phone}/>
          <DataView label={t("Address")} value={healthCenterData?.ResponsiblePerson?.Address}/>
          <DataView label={t("Responsible Person Image")} value={<CustomImage alt="" source={healthCenterData?.ResponsiblePerson?.Image ? healthCenterData?.ResponsiblePerson?.Image: ""} className="object-contain h-40 w-40 max-w-xs rounded-md mt-4 mr-20 "/>}/>
            </div>
            </div>
            
      {showModal && (
        <DeleteModal handleDelete={handleDelete} close={setShowModal} />
      )}
    </div>
  );
};

export default ViewHealthCenter;
