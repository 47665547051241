
import * as yup from 'yup';
let PregnancyWeekValidationSchema = yup.object().shape({
    Week: yup.number().required('Required !'),
    Size: yup.string().required('Required !'),
    HeightDescription: yup.string().required('Required !'),
       
});


export default PregnancyWeekValidationSchema