import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import APIServices from '../../../httpServices/httpServices';
import FormikTextField from '../../common/form/FormikTextField';
import { emitSuccessToast } from '../../common/toast/ToastContainer';

import FormikTextAreaField from '../../common/form/FormikTextAreaField';
// import FormikDateField from '../../common/form/FormikDateField';
import { useNavigate } from 'react-router-dom';
// import FormikDateTimePickerField from '../../common/form/FormikDateTimePickerField';
import { useTranslation } from 'react-i18next';
import FormikSelectSingleField from '../../common/form/FormikSelectSingleField';
import BabyNamesValidationSchema from '../../../validation/BabyNamesValidationSchema';

const BabyNamesForm = ({ data, id }) => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const [initialValues, setInitialValues] = useState({
        Name:"",
       Detail: "",
        NameFor:"",
        Ethnicity:"",
            })
            
    const currentNameFor = ["Boy", "Girl","Both"]
    let currentNameForOptions = currentNameFor.map((item) => ({ value: item, label: item }))
    
    const currentNameType = ["General","Hindu","Buddhist","Muslim","Others"]
    let currentNameTypeOptions = currentNameType.map((item) => ({ value: item, label: item }))

    const handleSelectSingle = (selectedOption, fieldName, setFieldValue) => {
        setFieldValue(fieldName, selectedOption.value)
    }
    useEffect(() => {
        if (data)
            setInitialValues((prev) => ({
                ...prev,
                ...data,
            }));
    }, [data]);
    const handleFormikFormSubmit = async (values, actions) => {
        let { setSubmitting, resetForm } = actions
        let payload = {
            ...values,
        }
     
        const { success,  message } = !!!id ? await new APIServices(`babynames`).post(payload) : await new APIServices(`babynames/${id}`).put(payload)
        if (success) {
            setSubmitting(false);
            resetForm();
            emitSuccessToast(message)
            navigate(`/babynames`)
        }

    }

    return (
        <section className="py-16 bg-gray-100 dark:bg-gray-800">
            <div className="max-w-4xl px-4 mx-auto ">
                <Formik
                    initialValues={initialValues}
                    validationSchema={BabyNamesValidationSchema}
                    onSubmit={handleFormikFormSubmit}
                    enableReinitialize
                >
                    {
                formik => (
                            <Form>
                                <div>
                                    <h3 className="mb-2 text-base font-medium leading-6 text-gray-900 dark:text-gray-300">{t("Baby Names Information")}
                                    </h3>
                                    <div className="p-6 mb-4 bg-white rounded-md shadow dark:border-gray-800 dark:bg-gray-900">
                                        <FormikTextField label={t("Name")} name="Name"  formik={formik} />
                                        <br></br>
                                        <FormikSelectSingleField label={t("Name For")} name="NameFor"  options={currentNameForOptions}  handleSelectSingle={handleSelectSingle} />
                                      <br></br>
                                        <FormikSelectSingleField label={t("Ethnicity")} name="Ethnicity"  options={currentNameTypeOptions}  handleSelectSingle={handleSelectSingle} />
                                        <br></br>
                                        <FormikTextAreaField label={t("Detail")} name="Detail" formik={formik} />
                                </div>
                                {/* Submit */}
                                <div className="mt-7">
                                    <div className="flex justify-start space-x-2">
                                        <button type="submit"
                                            className="inline-block px-6 py-2.5 bg-blue-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-600">{t("Save")}</button>
                                    </div>
                                </div>
                                </div>
                            </Form>
                    )
                    }
                </Formik>
            </div>
        </section >
    )
}
 export default BabyNamesForm;
