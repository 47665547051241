import { ADToBS } from "bikram-sambat-js";
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import APIServices from '../../../httpServices/httpServices';
import DeleteModal from '../../common/modals/DeleteModal';
import { emitErrorToast, emitSuccessToast } from '../../common/toast/ToastContainer';
import DataView from '../../common/viewDetail/DataView';
import CustomTableForAppUser from "../../common/CustomTableForAppUser";
import BabyWeightModal from "../../common/modals/BabyWeightModal";
import BabyHeightModal from "../../common/modals/BabyHeightModal";

const ViewBaby = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { id } = useParams();
    const [showModal, setShowModal] = useState(false)
    const [babyData, setBabyData] = useState([]);
    const tableHeadingforWeight = ["Date", "Weight"]
    const tableHeadingforHeight = ["Date", "Height"]
    const [babyWeightData, setBabyWeightData] = useState([])
    const [babyHeightData, setBabyHeightData] = useState([])
    const [handleWeightModal, setHandleWeightModal] = useState(false)
    const [handleHeightModal, setHandleHeightModal] = useState(false)
    useEffect(() => {
        if (id) {
            getBabyView()
            getHeightDetail()
            getWeightDetail()
        }
        //eslint-disable-next-line
    }, [])

    // Fetch User Data 
    const getBabyView = useCallback(
        async () => {
            const { data, success } = await new APIServices(`baby/${id}`).get()
            if (success) {
                setBabyData(data.babies)
            }
        },
        [id],
    )
    const showWeightModal = (id) => {
        setHandleWeightModal(true)
    }
    const showHeightModal = (id) => {
        setHandleHeightModal(true)
    }
    const getWeightDetail = async () => {
        const { data } = await new APIServices(`babyweighthistory/get/${id}`).get()
        if (data) {
            setBabyWeightData(data)
        }

    }
    const getHeightDetail = async () => {
        const { data } = await new APIServices(`babyheighthistory/get/${id}`).get()
        if (data) {
            setBabyHeightData(data)
        }
    }
    console.log(babyHeightData)
    const handleWeight = async (values, actions) => {
        let { setSubmitting, resetForm } = actions
        let payload = {
            ...values,
        }
        const { message, success } = await new APIServices(`babyweighthistory/${id}`).post(payload)
        if (success) {
            setSubmitting(false);
            resetForm();
            setHandleWeightModal(false)
            emitSuccessToast(message)

        } else {
            setHandleWeightModal(true)
            emitErrorToast(message)
        }
    }
    const handleHeight = async (values, actions) => {
        let { setSubmitting, resetForm } = actions
        let payload = {
            ...values,
        }
        const { message, success } = await new APIServices(`babyheighthistory/${id}`).post(payload)
        if (success) {
            setSubmitting(false);
            resetForm();
            setHandleHeightModal(false)
            emitSuccessToast(message)

        } else {
            setHandleHeightModal(true)
            emitErrorToast(message)
        }
    }

    const handleDelete = async () => {
        const { message, success } = await new APIServices(`baby/${id}`).delete()
        if (success) {
            setShowModal(false)
            emitSuccessToast(message)
            navigate("/appusers");
        }
    }

    const {
        Name, MotherWeight, ExpectedDeliveryDate, BabyBornDate, BornLatitude, BornLongitude,
        ExactBornTime, BabyWeightInBorn, BabyBloodGroup, Gender, BabyBornIn, ShowWeightIn, Birth,
        BabyHealth, IsArchieved } = babyData

    return (
        <div className="max-w-6xl px-4 py-4 mx-auto lg:py-8 md:px-6">
            <h2 className="font-bold text-lg mt-5 mb-2"> {t("Baby Information")} </h2>
            <div className="shadow bg-white border-gray-500 p-8 rounded mt-18">
                <div className="flex flex-wrap">
                    <DataView label={t("Name")} value={Name} />
                    <DataView label={t("Expected Delivery Date")} value={ExpectedDeliveryDate} />
                    <DataView label={t("Baby Born Date")} value={ADToBS(moment(BabyBornDate).format("YYYY-MM-DD"))} />
                    <DataView label={t("Born Latitude")} value={BornLatitude} />
                    <DataView label={t("Born Longitude")} value={BornLongitude} />
                    <DataView label={t("MotherWeight")} value={MotherWeight} />
                    <DataView label={t("Gender")} value={Gender} />
                    <DataView label={t("Exact Born Time")} value={ExactBornTime} />
                    <DataView label={t("Baby Weight In Born")} value={BabyWeightInBorn} />
                    <DataView label={t("Show Weight In")} value={ShowWeightIn} />
                    <DataView label={t("Baby Blood Group")} value={BabyBloodGroup} />
                    <DataView label={t("Baby Born In")} value={BabyBornIn} />
                    <DataView label={t("Birth")} value={Birth} />
                    <DataView label={t("Baby Health")} value={BabyHealth} />
                    <DataView label={t("IsArchieved")} value={IsArchieved} />

                </div>
            </div>
            <div className="grid grid-cols-1 xl:grid-cols-2  gap-x-4">
                <div>
                    <div className="flex justify-between mt-5 mb-2 items-center">
                        <h2 className="font-bold text-lg"> {t("Weight History")} </h2>
                        <div>
                            <button onClick={() => showWeightModal(id)} className="bg-green-600 hover:bg-blue-700 text-white text-xs px-1 py-1 rounded font-bold mr-1 ">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus-lg w-4 h-4 " viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z" />
                                </svg>
                            </button>
                            {
                                handleWeightModal && <BabyWeightModal handleWeight={handleWeight} close={setHandleWeightModal} />
                            }
                        </div>
                    </div>
                    {
                        <>
                            <CustomTableForAppUser
                                tableData={babyWeightData}
                                tableHeadingData={tableHeadingforWeight}
                            />
                        </>
                    }
                </div>
                <div>
                    <div className="flex justify-between mt-5 mb-2 items-center">
                        <h2 className="font-bold text-lg"> {t("Height History")} </h2>
                        <div>
                            <button onClick={() => showHeightModal(id)} className="bg-green-600 hover:bg-blue-700 text-white text-xs px-1 py-1 rounded font-bold mr-1 ">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus-lg w-4 h-4 " viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z" />
                                </svg>
                            </button>
                            {
                                handleHeightModal && <BabyHeightModal handleHeight={handleHeight} close={setHandleHeightModal} />
                            }
                        </div>
                    </div>
                    {
                        <>
                            <CustomTableForAppUser
                                tableData={babyHeightData}
                                tableHeadingData={tableHeadingforHeight}
                            />
                        </>
                    }
                </div>
            </div>
          

            {
                showModal && <DeleteModal handleDelete={handleDelete} close={setShowModal} />
            }
        </div>

    )

}

export default ViewBaby