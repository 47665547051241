import React, { useEffect, useState } from "react";
import APIServices from "../../../httpServices/httpServices";
import { Form, Formik } from "formik";
import FormikTextField from "../form/FormikTextField";
import FormikSelectSingleField from "../form/FormikSelectSingleField";
import LocalBodyUserValidation from "../../../validation/LocalBodyUserValidation";

const UserModel = ({ close, handleUser, isUser, payload }) => {
  const [userType, setUserType] = useState([]);
  const [userRole, setUserRole] = useState([]);

  const [initialValues, setInitialValues] = useState({
    UserName: "",
    UserPass: "",
    UserType: "",
    Role: "",
  });

  useEffect(() => {
    setInitialValues({ ...payload });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getFormSelectFieldValues();
  }, []);

  const getFormSelectFieldValues = async () => {
    const [userTypeObj, userRoleObj] = await Promise.all([
      new APIServices("setting/get/user-type").get(),
      new APIServices("role/list").post({}),
    ]);
    if (userTypeObj.success) {
      let userOpt = JSON.parse(userTypeObj.data?.SettingValue).map((s) => ({
        value: s,
        label: s,
      }));
      setUserType(userOpt);
    }
    if (userRoleObj.success) {
      let roleOpt = userRoleObj.data?.map((r) => ({
        label: r.Title,
        value: r.Title,
      }));
      setUserRole(roleOpt);
    }
  };

  const handleSelectSingle = (selectedOption, fieldName, setFieldValue) => {
    setFieldValue(fieldName, selectedOption.value);
  };

  const handleSubmit = (values) => {
    handleUser(values);
  };

  return (
    <>
      <div
        className="fixed inset-0 bg-gray-800/80 z-[98]"
        onClick={() => close(false)}
      ></div>
      <div
        id="popup-modal"
        className="fixed top-1/2 z-[99] -translate-x-1/2 -translate-y-1/2 left-1/2 p-4 overflow-x-hidden overflow-y-auto"
      >
        <div className="relative w-full h-full max-w-md md:h-auto">
          <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
            <button
              onClick={() => close(false)}
              className="absolute top-3 right-2.5 text-white hover:bg-red-700 bg-red-600 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
              data-modal-toggle="popup-modal"
            >
              <svg
                aria-hidden="true"
                className="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <span className="sr-only">Close modal</span>
            </button>

            <div className="p-6">
              {!isUser && (
                <Formik
                  initialValues={initialValues}
                  validationSchema={LocalBodyUserValidation}
                  onSubmit={handleSubmit}
                  enableReinitialize
                >
                  {(formik) => (
                    <Form>
                      <FormikTextField
                        label="UserName/Number"
                        name="UserName"
                        formik={formik}
                      />
                      <FormikTextField
                        label="Password"
                        name="UserPass"
                        type="password"
                        formik={formik}
                      />

                      <FormikSelectSingleField
                        options={userType}
                        name="UserType"
                        label="User Type"
                        formik={formik}
                        handleSelectSingle={handleSelectSingle}
                      />
                      <FormikSelectSingleField
                        options={userRole}
                        name="Role"
                        label="Role"
                        formik={formik}
                        handleSelectSingle={handleSelectSingle}
                      />
                      <div className="flex mt-4 justify-between">
                        <button
                          type="submit"
                          data-modal-toggle="popup-modal"
                          className={`text-white bg-green-500  hover:bg-green-600 focus:ring-green-300  focus:ring-4 focus:outline-none dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2`}
                        >
                          Create as User
                        </button>

                        <button
                          data-modal-toggle="popup-modal"
                          onClick={() => close(false)}
                          className=" bg-red-600 hover:bg-red-700 text-white focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 text-center focus:z-10"
                        >
                          No, cancel
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              )}

              {isUser && (
                <>
                  <svg
                    aria-hidden="true"
                    className="mx-auto mb-4 text-gray-400 w-14 h-14 dark:text-gray-200"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    ></path>
                  </svg>
                  <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                    Are you sure you want to Remove ?
                  </h3>

                  <button
                    onClick={handleUser}
                    data-modal-toggle="popup-modal"
                    className={`text-white bg-red-500  hover:bg-red-600 focus:ring-red-300  focus:ring-4 focus:outline-none dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2`}
                  >
                    Remove as User
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserModel;
