import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import APIServices from '../../../httpServices/httpServices';
import FormikTextField from '../../common/form/FormikTextField';
import { emitSuccessToast } from '../../common/toast/ToastContainer';
// import ProvinceValidationSchema from '../../../validation/ProvinceValidationSchema';

const LocalBodyForm = ({ data, id }) => {
    const {t} = useTranslation();
    const navigate = useNavigate()
    const [initialValues, setInitialValues] = useState({
        District: "",
        DevNagariName: "",
        Name: "",
        Area: "",
        Mayor: "",
        MaleP: "",
        FemaleP: "",
        OtherP: "",
    })


    useEffect(() => {
        if (data)
            setInitialValues({
                District: data?.District || "",
                DevNagariName: data?.DevNagariName || "",
                Name: data?.Name || "",
                Area: data?.Area || "",
                Mayor: data?.Mayor || "",
                MaleP: data?.MaleP || "",
                FemaleP: data?.FemaleP || "",
               
            })
    }, [data])

    const handleFormikFormSubmit = async (values, actions) => {
        let { setSubmitting, resetForm } = actions
        let payload = {
            ...values,
        }

        const { success, data, message } = !!!id ? await new APIServices(`localbody`).post(payload) : await new APIServices(`localbody/${id}`).put(payload)
        if (success) {
            setSubmitting(false);
            resetForm();
            emitSuccessToast(message)
            navigate(`/localbody/view/${data._id}`, { replace: true })
        }

    }

    return (
        <section className="py-16 bg-gray-100 dark:bg-gray-800">
            <div className="max-w-4xl px-4 mx-auto ">
                <Formik
                    initialValues={initialValues}
                    // validationSchema={ProvinceValidationSchema}
                    onSubmit={handleFormikFormSubmit}
                    enableReinitialize
                >
                    {
                        formik => (
                            <Form>
                                <div>
                                    <h3 className="mb-2 text-base font-medium leading-6 text-gray-900 dark:text-gray-300">{t("Local Bodies Information")}
                                    </h3>
                                    <div className="p-6 mb-4 bg-white rounded-md shadow dark:border-gray-800 dark:bg-gray-900">
                                        <FormikTextField label={t("District*")} name="District" formik={formik} />
                                        <FormikTextField label={t("Name*")} name="Name" formik={formik} />
                                        <FormikTextField label={t("Dev Nagari Name*")} name="DevNagariName" formik={formik} />
                                        <FormikTextField label={t("Mayor")} name="Mayor" formik={formik} />
                                        <FormikTextField label={t("Area")} name="Area" type="number" formik={formik} />
                                        {/* Male Pop */}
                                        <FormikTextField label={t("Male Population*")} name="MaleP" type="number" formik={formik} />
                                        <FormikTextField label={t("Female Population*")} name="FemaleP" type="number" formik={formik} />
                                        <FormikTextField label={t("Other Population*")} name="OtherP" type="number" formik={formik} />
                                    </div>
                                </div>

                                {/* Submit */}
                                <div className="mt-7">
                                    <div className="flex justify-start space-x-2">
                                        <button type="submit"
                                            className="inline-block px-6 py-2.5 bg-blue-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-600">{t("Save")}</button>
                                    </div>
                                </div>
                            </Form>
                        )
                    }
                </Formik>
            </div>
        </section >
    )
}

export default LocalBodyForm