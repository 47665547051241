import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";

const RolesCheck = ({roles}) => {
    const { profile } = useSelector((state) => state.user)
    if (roles.includes(profile?.Role)) {
        return <Outlet />;
    }
    return <Navigate to={`not-found`} replace />;
};
export default RolesCheck;

