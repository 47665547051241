import { ADToBS } from "bikram-sambat-js";
import { Form, Formik } from 'formik';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import APIServices from '../../../httpServices/httpServices';
import FormikDateTimePickerField from '../../common/form/FormikDateTimePickerField';
import FormikRadioField from '../../common/form/FormikRadioField';
import FormikSelectSingleField from '../../common/form/FormikSelectSingleField';
import FormikTextAreaField from '../../common/form/FormikTextAreaField';
import FormikTextField from '../../common/form/FormikTextField';
import { emitSuccessToast } from '../../common/toast/ToastContainer';
import BabyValidationSchema from "../../../validation/BabyValidationSchema";

// import AppUserValidationSchema from '../../../validation/AppUserValidationSchema';

const BabyForm = ({ data, id , babyId}) => {
    const navigate = useNavigate()
    const [provinceOptions, setProvinceOptions] = useState(null)
    const [districtOptions, setDistrictOptions] = useState(null)
    const [localBodiesOptions, setLocalBodiesOptionsptions] = useState(null)
   
    const [initialValues, setInitialValues] = useState({
        Name: "",
        BabyBornDate: "",
        ExactBornTime: "",
        BornLatitude: '',
        BornLongitude: "",
        BabyBloodGroup: "",
        BabyWeightInBorn: "",
        ShowWeightIn: "",
        Gender: "",
        BabyBornIn: "",
        Birth: "",
        BabyHealth: "",
        IsArchieved: "",
        Province: "",
        District: "",
        LocalBodies: "",
        Address: "",
        HopitalName: ""
    })
    const bloodGroup = ['A+', 'A-', 'B+', 'B-', 'O+', 'O-', 'AB+', 'AB-']
    let babyBloodGroupOptions = bloodGroup.map((item) => ({ value: item, label: item }))

    const showWeightIn = ['Kg', 'lbs']
    let showWeightInOptions = showWeightIn.map((item) => ({ value: item, label: item }))

    const gender = ['Male', 'Female']
    let genderOptions = gender.map((item) => ({ value: item, label: item }))

    const babyBornIn = ['Hospital', 'Home']
    let babyBornInOptions = babyBornIn.map((item) => ({ value: item, label: item }))

    const deliveryType = ['Normal', 'C section']
    let birthOptions = deliveryType.map((item) => ({ value: item, label: item }))

    useEffect(() => {
        if (data) {
            getDistrictByProvince(data.Province)
            getLocalBodiesByDistrict(data.District)
        }
    }, [data])

    useEffect(() => {
        getFormSelectFieldValues()
    }, [])

    useEffect(() => {
        if (data)
            setInitialValues({
                Name: data?.Name || "",
                BabyBornDate: ADToBS(moment(data?.BabyBornDate).format("YYYY-MM-DD")) || "",
                ExactBornTime: data?.ExactBornTime || "",
                BornLatitude: data?.BornLatitude || "",
                BornLongitude: data?.BornLongitude || "",
                BabyBloodGroup: data?.BabyBloodGroup || "",
                BabyWeightInBorn: data?.BabyWeightInBorn || "",
                ShowWeightIn: data?.ShowWeightIn || "",
                Gender: data?.Gender || "",
                BabyBornIn: data?.BabyBornIn || "",
                Birth: data?.Birth || "",
                BabyHealth: data?.BabyHealth || "",
                IsArchieved: data?.IsArchieved || "",
                Province: data?.Province || "",
                District: data?.District || "",
                LocalBodies: data?.LocalBodies || "",
                Address: data?.Address || "",
                HopitalName: data?.HopitalName || "",
            })
    }, [data])

    const handleFormikFormSubmit = async (values, actions) => {
        let { setSubmitting, resetForm } = actions
        let payload = {
            ...values,
        }
        const { success, data, message } =
        !! babyId ? await new APIServices(`baby/${babyId}`).put(payload):await new APIServices(`baby/${id}`).post(payload) 
        if (success) {
            setSubmitting(false);
            resetForm();
            emitSuccessToast(message)
            navigate(`/appuser/view/${data.AppUserId}`, { replace: true })
        }
        

    }

    const getFormSelectFieldValues = async () => {
        const [provinceObj] = await Promise.all([
            new APIServices("province/list").post({})
        ]);
        if (provinceObj.success) {
            let provinceData = provinceObj.data.map((item) => ({ value: item.Name, label: item.Name }))
            setProvinceOptions(provinceData)
        }
    }


    // District Options By Selected Province
    const getDistrictByProvince = async (Province) => {
        const { data, success } = await new APIServices(`appuser/get/districts?Province=${Province}`).get()
        if (success) {
            let districtOptions = data.map((item) => ({ value: item, label: item }))
            setDistrictOptions(districtOptions)
        }
    }

    // District Options By Selected Province
    const getLocalBodiesByDistrict = async (District) => {
        const { data, success } = await new APIServices(`appuser/get/localbodies?District=${District}`).get()
        if (success) {
            let localbodiesOptions = data.map((item) => ({ value: item, label: item }))
            setLocalBodiesOptionsptions(localbodiesOptions)
        }
    }

    const handleSelectSingle = (selectedOption, fieldName, setFieldValue) => {
        setFieldValue(fieldName, selectedOption.value)
        getDistrictByProvince(selectedOption.value)
    }

    const handleSelectSingleDistrict = (selectedOption, fieldName, setFieldValue) => {
        setFieldValue(fieldName, selectedOption.value)
        getLocalBodiesByDistrict(selectedOption.value)

    }
    const handleSelectSingleLocalBody = (selectedOption, fieldName, setFieldValue) => {
        setFieldValue(fieldName, selectedOption.value)
    }

    return (
        <section className="py-16 bg-gray-100 dark:bg-gray-800">
            <div className="max-w-4xl px-4 mx-auto ">
                <Formik
                    initialValues={initialValues}
                    validationSchema={BabyValidationSchema}
                    onSubmit={handleFormikFormSubmit}
                    enableReinitialize
                >
                    {
                        formik => (
                            <Form>
                                <div className="">
                                    <div>
                                        <h3 className="mb-2 text-base font-medium leading-6 text-gray-900 dark:text-gray-300">Add your First Baby Record
                                        </h3>
                                        <div className="p-6 mb-4 bg-white rounded-md shadow dark:border-gray-800 dark:bg-gray-900">
                                            <FormikTextField label="Name *" name="Name" formik={formik} />
                                            <div className="grid w-full gap-6 mb-6 lg:grid-cols-2">
                                                <FormikDateTimePickerField label="Baby Date of Birth" name="BabyBornDate"  formik={formik} />
                                                <FormikTextField label="Baby Exact Born Time" name="ExactBornTime" type="time" formik={formik} />
                                            </div>
                                            <div className="grid w-full gap-4 mb-6 lg:grid-cols-2">
                                                <FormikTextField label="Born Latitude " name="BornLatitude"  formik={formik} />
                                                <FormikTextField label="Born Longitude" name="BornLongitude" formik={formik} />
                                            </div>
                                            <FormikSelectSingleField label="Baby Blood Group" name="BabyBloodGroup" options={babyBloodGroupOptions} handleSelectSingle={handleSelectSingle} />
                                            <div className="grid w-full gap-4 mb-6 lg:grid-cols-2">
                                                <FormikTextField label="Mother Weight" name="BabyWeightInBorn" type="number" formik={formik} />
                                                <FormikSelectSingleField label="Weight Unit" name="ShowWeightIn" options={showWeightInOptions} handleSelectSingle={handleSelectSingle} />
                                            </div>
                                            <FormikRadioField label="Gender" name="Gender" options={genderOptions} formik={formik} />
                                            <div className="grid w-full gap-4 mb-6 lg:grid-cols-2">
                                                <FormikRadioField label="Baby Born In" name="BabyBornIn" options={babyBornInOptions} formik={formik} />
                                                <FormikRadioField label="Delivery Type" name="Birth" options={birthOptions} formik={formik} />
                                            </div>
                                            {
                                                (formik.values.BabyBornIn === "Hospital") ?
                                                    <>
                                                        <FormikSelectSingleField label="Province" name="Province" options={provinceOptions} handleSelectSingle={handleSelectSingle} />
                                                        <FormikSelectSingleField label="District" name="District" options={districtOptions} handleSelectSingle={handleSelectSingleDistrict} />
                                                        <FormikSelectSingleField label="LocalBodies" name="LocalBodies" options={localBodiesOptions} handleSelectSingle={handleSelectSingleLocalBody} />
                                                        <FormikTextField label="Address" name="Address" formik={formik} />
                                                        <FormikTextField label="HospitalName" name="HospitalName" formik={formik} />
                                                    </> :
                                                    <></>
                                            }

                                            <FormikTextAreaField label="BabyHealth" name="BabyHealth" formik={formik} />
                                            <FormikTextAreaField label="IsArchieved" name="IsArchieved" formik={formik} />
                                        </div>
                                    </div>

                                </div>

                                {/* Submit */}
                                <div className="mt-7">
                                    <div className="flex justify-start space-x-2">
                                        <button type="submit"
                                            className="inline-block px-6 py-2.5 bg-blue-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-600">Save</button>
                                    </div>
                                </div>
                            </Form>
                        )
                    }
                </Formik>
            </div>
        </section >
    )
}

export default BabyForm