import { ADToBS } from "bikram-sambat-js";
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';
import APIServices from '../../../httpServices/httpServices';
import CustomImage from '../../common/CustomImage';
import DeleteModal from '../../common/modals/DeleteModal';
import { emitSuccessToast } from '../../common/toast/ToastContainer';
import DataView from '../../common/viewDetail/DataView';
const ViewUser = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const { id } = useParams();
    const [showModal, setShowModal] = useState(false)
    const [userData, setUserData] = useState([]);
  

    useEffect(() => {
        if (id) {
            getUserDetailView()
         
        }
        // eslint-disable-next-line
    }, [])
    const handleEditUser = (id) => {
        navigate(`/users/edit/${id}`);
    }

    // Fetch User Data 
    const getUserDetailView = useCallback(
        async () => {
            const { data, success } = await new APIServices(`user/${id}`).get()
            if (success) {
                setUserData(data)
            }
        },
        [id],
    )


    // Fetch User Task 
    // const getUserTask = useCallback(
    //     async () => {

    //         const { data, success } = await new APIServices(`user-task-type/task/${id}`).get()
    //         if (success) {
    //             setUserTaskData(data.map(item => ({
    //                 Status: item?.Status === "Active" ? true : false,
    //                 TaskTypeId: item?.TaskTypeId._id,
    //                 Rate: item?.Rate,
    //                 IsPermission: item?.IsPermission === "Yes" ? true : false,
    //                 UserId: item?.UserId,
    //                 UniqueKey: item._id
    //             })))
    //         }
    //     },
    //     [id],
    // )


    const handleShowDeleteModal = (id) => {
        setShowModal(true)
    }

    const handleDeleteUser = async () => {
        const { message, success } = await new APIServices(`user/${id}`).delete()
        if (success) {
            setShowModal(false)
            emitSuccessToast(message)
            navigate("/users");
        }
    }

    const {
        _id, Address, AliasName, Cellphone,
        DOB, Email, FullName,
        Gender, Phone, ProfileImage,Remarks, Role,
        Status, UserName, UserType } = userData

    return (
        <div className="max-w-6xl px-4 py-4 mx-auto lg:py-8 md:px-6">
            <div className="flex gap-x-4">
                <div className="user-profile w-[180px] h-[180px]">
                    <CustomImage className="employee-avatar object-cover h-full w-full rounded-md" source={!!ProfileImage ? ProfileImage : ""} />
                    <Link to="/users/change-password" state={{ userId: _id }} className="inline-block flex items-center px-4 my-2 py-2.5 bg-red-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-red-600 mb-5">
                        {t("Change Password")}
                    </Link>
                </div>
                <div className="flex-1">
                    <div className="font-bold text-2xl" name="">{FullName} <button
            onClick={() => handleEditUser(id)}
            className="font-medium text-blue-600 hover:text-blue-500 dark:hover:text-gray-300 dark:text-blue-300 ml-2 ">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
            fill="currentColor" className="w-4 h-4 mr-2 bi bi-pencil-square"
            viewBox="0 0 16 16">
            <path
                d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
            <path fillRule="evenodd"
                d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                 </svg>
            </button></div>
                    <div className="mb-3 text-gray-500">
                        <span className="text-base">{Email}</span>
                    </div>
                    <div className="grid grid-cols-3 gap-x-2">

                        <div className="flex flex-col">
                            <span className="block mb-0 text-sm text-gray-600 font-normal"> {t("Date of Birth")} </span>
                            <span className="font-bold">{ADToBS(moment(DOB).format("YYYY-MM-DD"))}</span>
                        </div>

                        <div className="flex flex-col">
                            <span className="block mb-0 text-sm text-gray-600 font-normal"> {t("Phone")} </span>
                            <span className="mb-5 text-base font-semibold text-gray-800"> {Phone ? Phone : "--"} </span>
                        </div>

                        <div className="flex flex-col">

                            <span className="block mb-0 text-sm text-gray-600 font-normal"> {t("Cellphone")} </span>
                            <span className="mb-5 text-base font-semibold text-gray-800"> {Cellphone ? Cellphone : "--"}</span>
                        </div>
                    </div>

                    <div className="flex items-center justify-between">
                        <div className="flex items-center">
                            <div className={`rounded-full w-3 h-3 bg-green-700 mr-2 ${Status === "Active" ? "bg-green-700" : "bg-red-700"}`}></div>
                            <span className="text-sm"> {Status} </span>
                        </div>

                        {
                            Status === "Inactive" && (
                                <button onClick={() => handleShowDeleteModal(_id)} className="inline-block flex items-center px-4 py-2.5 bg-red-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-red-600 mb-5">
                                    {t("Delete")}
                                </button>
                            )
                        }
                    </div>

                </div>
            </div>

            <div className="shadow bg-white border-gray-500 p-8 rounded mt-20">
                <h2 className="font-bold text-lg mt-15 mb-5"> {t("Personal Information")} </h2>
                <div className="flex flex-wrap ">
                    <DataView label={t("Username")} value={UserName} />
                    <DataView label={t("Alias Name")} value={AliasName} />
                    <DataView label={t("Role")} value={Role} />
                    <DataView label={t("UserType")} value={UserType} />
                    <DataView label={t("Address")} value={Address} />
                    <DataView label={t("Remarks")} value={Remarks} />
                    <DataView label={t("Gender")} value={Gender} />
                </div>
            </div>


            {
                showModal && <DeleteModal handleDelete={handleDeleteUser} close={setShowModal} />
            }
        </div>
    )
}

export default ViewUser