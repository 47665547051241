import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import APIServices from "../../../httpServices/httpServices";
import { LIMIT } from "../../../utilities/constant";
import CustomTable from "../../common/CustomTable";
import DeleteModal from "../../common/modals/DeleteModal";
import TableSkeleton from "../../common/skeleton/TableSkeleton";
import TableEntry from "../../common/table/TableEntry";
import TablePagination from "../../common/table/TablePagination";
import { emitSuccessToast } from "../../common/toast/ToastContainer";

const HealthCenterList = () => {
  const {t}= useTranslation();
  

  const navigate = useNavigate();
  //eslint-disable-next-line
  const [tableHeading, setTableHeading] = useState([
    "Name",
    "Slug",
    "DevnagariName",
    "Status",
    "Action",
  ]);
  const [healthCenterList, setHealthCenterList] = useState([]);
  //eslint-disable-next-line
  const [selectedHealthCenterId, setSelectedHealthCenterId] = useState(null);
  const [showModal, setShowModal] = useState(false);

  //PAGINATION STATE
  const [searchTxt, setsearchTxt] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const limit = LIMIT
  const [numberOfTotalData, setNumberOfTotalData] = useState(0);
  const [totalPagesNumber, setTotalPageNumber] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    let timeout;
    if (!!searchTxt) {
      timeout = setTimeout(() => {
        getListOfHealthCenters();
      }, 1500);
    } else {
      getListOfHealthCenters();
    }
    return () => clearTimeout(timeout);
    //eslint-disable-next-line
  }, [searchTxt, currentPage]);

  const getListOfHealthCenters = async () => {
    const { data, success, total, totalPages } = await new APIServices(
      "health-center/list"
    ).post({
      page: currentPage,
      limit: limit,
      search: searchTxt,
    });

    if (success) {
      setIsLoading(false);
      setHealthCenterList(data);
      setTotalPageNumber(totalPages || 0);
      setNumberOfTotalData(total || 0);
    }
  };
  // useEffect(() => {
  //   let timeout;
  //   if (!!searchdist) {
  //     timeout = setTimeout(() => {
  //       getListOfHealthdist();
  //     }, 1500);
  //   } else {
  //     getListOfHealthdist();
  //   }
  //   return () => clearTimeout(timeout);
  // }, [searchdist, currentPage]);

  // const getListOfHealthdist = async () => {
  //   const { data, success, total, totalPages } = await new APIServices(
  //     "health-center/list"
  //   ).post({
  //     page: currentPage,
  //     limit: limit,
  //     search: searchdist,
  //   });

  //   if (success) {
  //     setIsLoading(false);
  //     setHealthCenterList(data);
  //     setTotalPageNumber(totalPages || 0);
  //     setNumberOfTotalData(total || 0);
  //   }
  // };

  // const handleShowDeleteModal = (id) => {
  //   setSelectedHealthCenterId(id);
  //   setShowModal(true);
  // };

  const handleDelete = async () => {
    const { message, success } = await new APIServices(
      `health-center/${selectedHealthCenterId}`
    ).delete();
    if (success) {
      setShowModal(false);
      emitSuccessToast(message);
      getListOfHealthCenters();
    }
  };

  const handleEdit = (id) => {
    navigate(`/health-center/edit/${id}`);
  };

  const handleView = (id) => {
    navigate(`/health-center/view/${id}`);
  };

  const navigateToAddNew = () => {
    navigate("/health-center/add");
  };

  const handleTableSearch = (val) => {
    setsearchTxt(val);
  };
  // const handleTableSearchD = (val) => {
  //   setsearchdist(val);
  // };

  return (
    <section className="dark:bg-gray-800 ">
      <div className="max-w-6xl px-4 py-4 mx-auto lg:py-8 md:px-6">
        <div className="pt-4 rounded shadow bg-white dark:bg-gray-900">
          {isLoading ? (
            <TableSkeleton />
          ) : (
            <>
              <TableEntry
                title={t("List of Health Centers")}
                navigate={navigateToAddNew}
                handleTableSearch={handleTableSearch}
                
              />
              <CustomTable
                tableData={healthCenterList}
                tableHeadingData={tableHeading}
                handleDelete={false}
                handleEdit={handleEdit}
                handleView={handleView}
                actionField="_id"
              />
              <TablePagination
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                totalPagesNumber={totalPagesNumber}
                numberOfTotalData={numberOfTotalData}
                limit={limit}
              />
            </>
          )}
        </div>
      </div>
      {showModal && (
        <DeleteModal handleDelete={handleDelete} close={setShowModal} />
      )}
    </section>
  );
};

export default HealthCenterList;
