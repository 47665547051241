import React from 'react'
import TagTypeForm from '../../container/tagtype/TagTypeForm'

const AddTagType = () => {
    return (
        <div><TagTypeForm /></div>
    )
}

export default AddTagType
