import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import APIServices from '../../../httpServices/httpServices';
import RecoverPasswordValidationSchema from '../../../validation/RecoverPasswordValidation';
import FormikTextField from '../../common/form/FormikTextField';


const RecoverPassword = () => {
   
    const navigate = useNavigate()
    let { token } = useParams();
    const [count, setCount] = useState(5)
    const [msg, setMsg] = useState("")
    const [showRecoverPassword, setShowRecoverPassword] = useState(true)
    const [passwordChangeStatus, setPasswordChangeStatus] = useState(false)
    const [isMounted, setIsMounted] = useState(false)
    //eslint-disable-next-line
    const [initialValues, setinitialValues] = useState({
        Password: "",
        ConfirmPassword: ""
    })

    useEffect(() => {
        let timeout;
        let interval;
        if (isMounted) {
            timeout = setTimeout(() => {
                navigate("/auth/login")
            }, 5000);

            interval = setInterval(() => {
                setCount(prev => prev - 1)
            }, 1000);
        }
        return () => {
            clearTimeout(timeout)
            clearInterval(interval)

        }
        //eslint-disable-next-line
    }, [isMounted])

    const handleFormikFormSubmit = async (values, actions) => {
        const { success, message } = await new APIServices(`auth/recover-password/${token}`).post(values)
        if (success) {
            setPasswordChangeStatus(true)
        }
        setIsMounted(true)
        setShowRecoverPassword(false)
        setMsg(message)
    }

    return (
        <section className="bg-gray-100">
            <div className="mx-auto">
                {
                    showRecoverPassword ?
                        <div className="flex items-center justify-center h-screen">
                            <div className="bg-gray-100 p-5 rounded-lg lg:rounded-l-none">
                                <div className="px-8 mb-4 text-center">
                                    <h3 className="pt-4 mb-2 text-2xl">Recover Your Password?</h3>

                                </div>
                                <Formik
                                    initialValues={initialValues}
                                    validationSchema={RecoverPasswordValidationSchema}
                                    onSubmit={handleFormikFormSubmit}
                                    enableReinitialize
                                >
                                    {
                                        formik => (
                                            <Form>
                                                <div className="mb-6">
                                                    <FormikTextField label="Password *" name="Password" type="password" formik={formik} />
                                                </div>
                                                <div className="mb-6">
                                                    <FormikTextField label="Confirm Password *" name="ConfirmPassword" type="password" formik={formik} />
                                                </div>

                                                {/* Submit */}
                                                <div className="mb-6 text-center">
                                                    <button
                                                        className="w-full px-4 py-2 font-bold text-white bg-blue-600 rounded-full hover:bg-blue-500 focus:outline-none focus:shadow-outline"
                                                        type="submit"
                                                    >
                                                        Reset Password
                                                    </button>
                                                </div>
                                            </Form>
                                        )
                                    }
                                </Formik>

                            </div>
                        </div> :
                        passwordChangeStatus ?
                            <div className="relative flex min-h-screen flex-col items-center justify-center overflow-hidden py-6 sm:py-12">
                                <div className="max-w-md bg-white shadow rounded p-10 text-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="bi bi-check2-circle mx-auto mb-6 w-36 h-36 text-green-700" viewBox="0 0 16 16">
                                        <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0z" />
                                        <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z" />
                                    </svg>
                                    <h2 className="mb-1 text-2xl font-bold text-zinc-700"> Password reset sucessfully. Thank you! </h2>
                                    <p className="text-sm text-zinc-500">Redirecting to login page in {count} sec...</p>
                                </div>
                            </div> :
                            <div className="relative flex min-h-screen flex-col items-center justify-center overflow-hidden py-6 sm:py-12">
                                <div className="max-w-md bg-white shadow rounded p-10 text-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="bi bi-x-circle mx-auto mb-6 w-36 h-36 text-red-700" viewBox="0 0 16 16">
                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                    </svg>
                                    <h2 className="mb-1 text-2xl font-bold text-zinc-700"> Failed to reset password.{msg} Please try again, Thank you!</h2>
                                    <p className="text-sm text-zinc-500">Redirecting to login page in {count} sec...</p>
                                </div>
                            </div>
                }
            </div>
        </section>
    )
}

export default RecoverPassword