import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import APIServices from '../../../httpServices/httpServices';
import DataView from '../../common/viewDetail/DataView';

const ViewBabyNames = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { id } = useParams();
    const [babynamesData, setBabyNamesData] = useState([]);

    useEffect(() => {
        if (id) {
            getBabyNamesDetailView()
        }
        //eslint-disable-next-line 
    }, [])

    // Fetch User Data 
    const getBabyNamesDetailView = async () => {
        const { data } = await new APIServices(`babynames/${id}`).get()
        if (data) {
            setBabyNamesData(data)
        }
    }
    const handleEdit = (id) => {
        navigate(`/babynames/edit/${id}`);
    }

    const { Name, Detail, Ethnicity, NameFor } = babynamesData

    return (
        <div className="max-w-6xl px-4 py-4 mx-auto lg:py-8 md:px-6">
            <div className="flex justify-between mt-5 mb-4 items-end">
                <h2 className="font-bold text-lg"> {t("Baby Information")} <button
            onClick={() => handleEdit(id)}
            className="font-medium text-blue-600 hover:text-blue-500 dark:hover:text-gray-300 dark:text-blue-300 ml-1">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
            fill="currentColor" className="w-4 h-4 mr-2 bi bi-pencil-square"
            viewBox="0 0 16 16">
            <path
                d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
            <path fillRule="evenodd"
                d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                 </svg>
            </button></h2>
            </div>


            <div className="shadow bg-white border-gray-500 p-8 rounded">
                <div className="flex flex-wrap">
                    <DataView label={t("Name")} value={Name} />
                    <DataView label={t("Name For")} value={NameFor} />
                    <DataView label={t("Ethnicity")} value={Ethnicity} />

                </div>
                <div>
                    <DataView label={t("Detail")} value={Detail} />
                </div>

            </div>
            <br></br>
        </div>
    )
}

export default ViewBabyNames