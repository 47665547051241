import React from "react";
import { useTranslation } from "react-i18next";

const TableEntry = ({ title, navigate, handleTableSearch, searchTxt }) => {

    const { t } = useTranslation();

    return (
        <div className="flex flex-wrap items-center justify-between px-6 pb-4 border-b dark:border-gray-700">
            <h2 className="mb-4 text-xl font-bold md:mb-0 ">
                {t(title)}
            </h2>
            {handleTableSearch ? (
                <div className="flex flex-wrap items-center gap-6 ">
                    <div className="flex py-2 pl-2 pr-2 mb-4 border border-gray-600 rounded-md md:mb-0 dark:border-gray-400">
                        <input
                            type="text"
                            name="search"
                            value={searchTxt}
                            onChange={(e) => handleTableSearch(e.target.value)}
                            className="w-full pr-4 text-sm text-gray-700 placeholder-text-100 focus:border-none"
                            placeholder={`${t("search")} ....`}
                        />
                        <button className="flex items-center text-gray-700 dark:hover:text-blue-300 hover:text-blue-600">
                            <span className="mr-2 text-xs ">{t("Go")}</span>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="bi bi-arrow-right"
                                viewBox="0 0 16 16"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                                />
                            </svg>
                        </button>
                    </div>
                    {!!navigate && (
                        <div>
                            <button
                                onClick={navigate}
                                className=" flex items-center px-4 py-2.5 bg-primary text-gray-700 font-medium text-xs leading-tight uppercase rounded shadow-md hover:opacity-90"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="mr-1 bi bi-plus-lg"
                                    viewBox="0 0 16 16"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z"
                                    />
                                </svg>
                                {t("Add New")}
                            </button>
                        </div>
                    )}
                </div>
            ) : (
                <>
                    {!!navigate && (
                        <div>
                            <button
                                onClick={navigate}
                                className=" flex items-center px-4 py-2.5 bg-primary text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:opacity-90"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="mr-1 bi bi-plus-lg"
                                    viewBox="0 0 16 16"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z"
                                    />
                                </svg>
                                {t("Add New")}
                            </button>
                        </div>
                    )}
                </>
            )}
        </div>
        
        
    );
};

export default TableEntry;
