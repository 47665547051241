import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import APIServices from '../../../httpServices/httpServices';
import PostForm from '../../container/post/PostForm';
import moment from 'moment';
import  {ADToBS}  from "bikram-sambat-js"

const EditPost = () => {
    // The ID you get here is SLUG 
    const { id } = useParams()
    const [postDataToEdit, setPostDataToEdit] = useState([])
    useEffect(() => {
        if (id)
            getPostDetail()
        //eslint-disable-next-line
    }, [])

    const getPostDetail = async () => {
        const { data, success } = await new APIServices(`post/${id}`).get()
        if (success) {
            let payload = {
                ...data,
                Status: data.Status === "Active" ? true : false,
                IsVerified: data.IsVerified === "Yes" ? true : false,
                NextReviewDate:ADToBS(moment(data.NextReviewDate).format("YYYY-MM-DD"))
            }
            setPostDataToEdit({ ...payload })
        }
    }


    return (
        <>
            <PostForm data={postDataToEdit} id={id} />
        </>
    )
}

export default EditPost