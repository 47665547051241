import * as yup from 'yup';
const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/gif", "image/png"];
let TagValidationSchema = yup.object().shape({
    Status: yup.boolean(),
    Title: yup.string().max(40, 'Must be 40 characters or less').required('Required !'),
    Slug: yup.string().max(50, 'Must be 50 characters or less').required('Required !'),
    Image: yup.mixed()
        .notRequired()
        .nullable()
        .test('fileSize',
            'The file is too large', (value) => {
                if (!value) return true
                return value && value.size <= (1024 * 1024) * 2
            })
        .test('fileFormat',
            'Unsupported File Format', (value) => !value || (value && SUPPORTED_FORMATS.includes(value.type))),


    SpecialValue: yup.string().max(40, 'Must be 40 characters or less').required('Required !'),
    TagType: yup.string().required('Required !'),
    Detail: yup.string().required('Required !'),
    SeoTitle: yup.string(),
    SeoDescription: yup.string(),

});


export default TagValidationSchema