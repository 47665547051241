import { ADToBS } from "bikram-sambat-js";
import { Form, Formik } from 'formik';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import APIServices from '../../../httpServices/httpServices';
import FormikDateTimePickerField from '../../common/form/FormikDateTimePickerField';
import FormikImageField from '../../common/form/FormikImageField';
import FormikRadioField from '../../common/form/FormikRadioField';
import FormikSelectSingleField from '../../common/form/FormikSelectSingleField';
import FormikSwitchField from '../../common/form/FormikSwitchField';
import FormikTextField from '../../common/form/FormikTextField';
import { emitErrorToast, emitSuccessToast } from '../../common/toast/ToastContainer';
import AppUserValidationSchema from "../../../validation/AppUserValidationSchema";


const AppUserForm = ({ data, id }) => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const [provinceOptions, setProvinceOptions] = useState(null)
    const [districtOptions, setDistrictOptions] = useState(null)
    const [localBodiesOptions, setLocalBodiesOptionsptions] = useState(null)
    const [occupationOptions, setOccupationOptions] = useState([]);
    const [ethnicityOptions, setEthnicityOptions] = useState([]);
    const [educationOptions, setEducationOptions] = useState([]);
    const [maritalStatusOptions, setMaritalStatusOptions] = useState([]);
    const [initialValues, setInitialValues] = useState({
        Status: false,
        Name: "",
        Email: "",
        UserName: "",
        AliasName: "",
        UserPass: "",
        ProfileImage: "",
        DOB: "",
        MoodReminderIn: "",
        MotherBloodGroup: "",
        MotherCurrentWeight: "",
        ShowWeightIn: "",
        MotherHeight: "",
        Cellphone: "",
        Phone: "",
        Country: "Nepal",
        Province: "",
        District: "",
        LocalBodies: "",
        PermanentAddress: "",
        TemporaryAddress: "",
        CurrentStatus: "Pregnant",
        Language: "",
        Device: "",
        Ethnicity: "",
        MaritalStatus: "",
        Education: "",
        Occupation: "",
        Gravida: "",
        Para: "",
        GestationalAge: "",
    })

    const currentStatus = ["Pregnant", "Infant"]
    let currentStatusOptions = currentStatus.map((item) => ({ value: item, label: item }))

    const showWeightIn = ['Kg', 'lbs']
    let showWeightInOptions = showWeightIn.map((item) => ({ value: item, label: item }))


    const bloodGroup = ['A+', 'A-', 'B+', 'B-', 'O+', 'O-', 'AB+', 'AB-']
    let motherBloodGroupOptions = bloodGroup.map((item) => ({ value: item, label: item }))

    const sameAsPermanentAddress = (formik) => {
        const permanentAddress = formik.values?.PermanentAddress ? formik.values?.PermanentAddress : ""
        formik.setFieldValue("TemporaryAddress", permanentAddress);
    };


    useEffect(() => {
        if (data) {
            getDistrictByProvince(data.Province)
            getLocalBodiesByDistrict(data.District)
        }
    }, [data])


    useEffect(() => {
        getFormSelectFieldValues()
    }, [])

    useEffect(() => {
        if (data)
            setInitialValues({
                Status: data?.Status || false,
                Name: data?.Name || "",
                Email: data?.Email || "",
                UserName: data?.UserName || "",
                AliasName: data?.AliasName || "",
                UserPass: data?.UserPass || "",
                ProfileImage: data?.ProfileImage || "",
                DOB: ADToBS(moment(data?.DOB).format('YYYY-MM-DD')) || "",
                MoodReminderIn: ADToBS(moment(data?.MoodReminderIn).format('YYYY-MM-DD')) || "",
                MotherBloodGroup: data?.MotherBloodGroup || "",
                MotherCurrentWeight: data?.MotherCurrentWeight || "",
                ShowWeightIn: data?.ShowWeightIn || "",
                MotherHeight: data?.MotherHeight || "",
                Cellphone: data?.Cellphone || "",
                Phone: data?.Phone || "",
                Country: data?.Country || "Nepal",
                Province: data?.Province || "",
                District: data?.District || "",
                LocalBodies: data?.LocalBodies || "",
                PermanentAddress: data?.PermanentAddress || "",
                CurrentStatus: data?.CurrentStatus || "Pregnant",
                Language: data?.Language || "",
                Device: data?.Device || "",
            })
    }, [data])

    const handleFormikFormSubmit = async (values, actions) => {
        let { setSubmitting, resetForm } = actions
        let payload = {
            ...values,
            Status: values.Status ? "Active" : "Inactive",
        }
        let formData = new FormData();

        Object.keys(payload).forEach((key, index) => {
            formData.append(key, payload[key]);
        });
        const { success, data, message } = !!!id ? await new APIServices(`appuser`).post(formData) : await new APIServices(`appuser/${id}`).put(formData)
        if (success) {
            setSubmitting(false);
            resetForm();
            emitSuccessToast(message)
            navigate(`/appuser/view/${data._id}`, { replace: true })
        } else {
            emitErrorToast(message)
        }

    }

    const getFormSelectFieldValues = async () => {
        const [provinceObj, occupationObj, educationObj, ethnicityObj, maritalObj] = await Promise.all([
            new APIServices("province/list").post({}),
            new APIServices("setting/get/occupation").get(),
            new APIServices("setting/get/education").get(),
            new APIServices("setting/get/ethnicity").get(),
            new APIServices("setting/get/marital-status").get(),

        ]);
        if (provinceObj.success) {
            let provinceData = provinceObj.data.map((item) => ({ value: item.Name, label: item.Name }))
            setProvinceOptions(provinceData)
        }
        if (occupationObj.success) {
            const { SettingValue } = occupationObj.data
            let options = JSON.parse(SettingValue)?.map((item) => ({ value: item, label: item }))
            setOccupationOptions(options)
        }
        if (educationObj.success) {
            const { SettingValue } = educationObj.data
            let options = JSON.parse(SettingValue)?.map((item) => ({ value: item, label: item }))
            setEducationOptions(options)
        }
        if (ethnicityObj.success) {
            const { SettingValue } = ethnicityObj.data
            let options = JSON.parse(SettingValue)?.map((item) => ({ value: item, label: item }))
            setEthnicityOptions(options)
        }
        if (maritalObj.success) {
            const { SettingValue } = maritalObj.data
            let options = JSON.parse(SettingValue)?.map((item) => ({ value: item, label: item }))
            setMaritalStatusOptions(options)
        }

    }


    // District Options By Selected Province
    const getDistrictByProvince = async (Province) => {
        const { data, success } = await new APIServices(`appuser/get/districts?Province=${Province}`).get()
        if (success) {
            let districtOptions = data.map((item) => ({ value: item, label: item }))
            setDistrictOptions(districtOptions)
        }
    }

    // District Options By Selected Province
    const getLocalBodiesByDistrict = async (District) => {
        const { data, success } = await new APIServices(`appuser/get/localbodies?District=${District}`).get()
        if (success) {
            let localbodiesOptions = data.map((item) => ({ value: item, label: item }))
            setLocalBodiesOptionsptions(localbodiesOptions)
        }
    }

    const handleSelectSingle = (selectedOption, fieldName, setFieldValue) => {
        setFieldValue(fieldName, selectedOption.value)
        getDistrictByProvince(selectedOption.value)
    }

    const handleSelectSingleDistrict = (selectedOption, fieldName, setFieldValue) => {
        setFieldValue(fieldName, selectedOption.value)
        getLocalBodiesByDistrict(selectedOption.value)

    }
    const handleSelectSingleLocalBody = (selectedOption, fieldName, setFieldValue) => {
        setFieldValue(fieldName, selectedOption.value)
    }


    return (
        <section className="py-16 bg-gray-100 dark:bg-gray-800">
            <div className="max-w-5xl px-4 mx-auto ">
                <Formik
                    initialValues={initialValues}
                    validationSchema={AppUserValidationSchema}
                    onSubmit={handleFormikFormSubmit}
                    enableReinitialize
                >
                    {
                        formik => (
                            <Form>
                                <div className="">
                                    <div>
                                        <h3 className="mb-2 text-base font-medium leading-6 text-gray-900 dark:text-gray-300">{t("App User's Information")}
                                        </h3>
                                        <div className="p-6 mb-4 bg-white rounded-md shadow dark:border-gray-800 dark:bg-gray-900">

                                            <div className="grid w-full gap-6 mb-6 lg:grid-cols-2">
                                                <FormikTextField label={t("Full Name*")} name="Name" formik={formik} />
                                                <FormikTextField label={t("Alias Name")} name="AliasName" formik={formik} />
                                            </div>
                                            <FormikTextField label={t("Email*")} name="Email" formik={formik} />
                                            {!!id ? (
                                                <FormikTextField label={t("User Name*")} name="UserName" formik={formik} />
                                            ) : (
                                                <div className="grid w-full gap-4 mb-6 lg:grid-cols-2">
                                                    <FormikTextField label={t("User Name*")} name="UserName" formik={formik} />
                                                    <FormikTextField label={t("Password*")} name="UserPass" type="password" formik={formik} />
                                                </div>
                                            )}
                                            <FormikImageField label={t("Profile Image")} name="ProfileImage" formik={formik} />
                                            <div className="grid w-full gap-4 mb-6 lg:grid-cols-2">
                                                {/* <FormikTextField label="Date of Birth" name="DOB" type="date" formik={formik} />
                                                 */}
                                                <FormikDateTimePickerField label={t("Date of Birth")} name="DOB" formik={formik} />
                                                <FormikDateTimePickerField label="MoodReminderIn" name="MoodReminderIn" formik={formik} />
                                            </div>
                                            <FormikSelectSingleField label={t("Mother Blood Group")} name="MotherBloodGroup" options={motherBloodGroupOptions} handleSelectSingle={handleSelectSingle} />
                                            <div className="grid w-full gap-4 mt-2 mb-6 lg:grid-cols-3">
                                                <FormikTextField label="Mother Height (in cm)" name="MotherHeight" type="number" formik={formik} />
                                                <FormikTextField label={t("Mother Weight")} name="MotherCurrentWeight" type="number" formik={formik} />
                                                <FormikSelectSingleField label="Weight Unit" name="ShowWeightIn" options={showWeightInOptions} handleSelectSingle={handleSelectSingle} />
                                            </div>
                                            <div className="grid w-full gap-6 mb-6 lg:grid-cols-2">
                                                <FormikSelectSingleField
                                                    label={t("Caste/Ethnicity")}
                                                    name="Ethnicity"
                                                    options={ethnicityOptions}
                                                    handleSelectSingle={handleSelectSingle}
                                                />
                                                <FormikSelectSingleField
                                                    label={t("Occupation")}
                                                    name="Occupation"
                                                    options={occupationOptions}
                                                    handleSelectSingle={handleSelectSingle}
                                                />
                                                <FormikSelectSingleField
                                                    label="Education"
                                                    name="Education"
                                                    options={educationOptions}
                                                    handleSelectSingle={handleSelectSingle}
                                                />
                                                <FormikSelectSingleField
                                                    label={t("Marital Status")}
                                                    name="MaritalStatus"
                                                    options={maritalStatusOptions}
                                                    handleSelectSingle={handleSelectSingle}
                                                />
                                            </div>
                                            <div className="grid w-full gap-4 mb-6 lg:grid-cols-2">
                                                <FormikTextField label="Cellphone" name="Cellphone" type="number" formik={formik} />
                                                <FormikTextField label="Phone" name="Phone" type="number" formik={formik} />
                                            </div>
                                            <FormikRadioField label="Current Status" name="CurrentStatus" options={currentStatusOptions} formik={formik} />
                                            <FormikTextField label="Language" name="Language" formik={formik} />
                                            <FormikSwitchField label={t("Status")} name="Status" />
                                        </div>
                                        <h3 className="mb-2 text-base font-bold leading-6 text-gray-900 dark:text-gray-300">
                                            {t("Address section")}
                                        </h3>
                                        <div className="p-6 mb-4 bg-white rounded-md shadow dark:border-gray-800 dark:bg-gray-900">
                                            <FormikTextField label="Country" name="Country" formik={formik} />
                                            <FormikSelectSingleField label="Province" name="Province" options={provinceOptions} handleSelectSingle={handleSelectSingle} />
                                            <FormikSelectSingleField label="District" name="District" options={districtOptions} handleSelectSingle={handleSelectSingleDistrict} />
                                            <FormikSelectSingleField label={t("Local Bodies")} name="LocalBodies" options={localBodiesOptions} handleSelectSingle={handleSelectSingleLocalBody} />
                                            <FormikTextField label={t("Permanent Address")} name="PermanentAddress" formik={formik} />
                                            <div className=' mt-2 mb-2'>
                                                <button type="button" className="p-1 text-white font-medium text-xs leading-tight shadow-md rounded-md bg-blue-500" onClick={() => sameAsPermanentAddress(formik)}> {t("Same as Permanent Address")}</button>
                                            </div>
                                            <FormikTextField label={t("Temporary Address")} name="TemporaryAddress" formik={formik} />
                                        </div>

                                    </div>

                                </div>


                                {/* Submit */}
                                <div className="mt-7">
                                    <div className="flex justify-start space-x-2">
                                        <button type="submit"
                                            className="inline-block px-6 py-2.5 bg-blue-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-600">{t("Save")}</button>
                                    </div>
                                </div>
                            </Form>
                        )
                    }
                </Formik>
            </div>
        </section >
    )
}

export default AppUserForm