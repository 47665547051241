import React from 'react'
import RolesForm from '../../container/roles/RolesForm'

const AddRole = () => {

    return (
        <div><RolesForm /></div>
    )
}

export default AddRole