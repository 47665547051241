import { ADToBS } from "bikram-sambat-js";
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';
import APIServices from '../../../httpServices/httpServices';
import CustomImage from '../../common/CustomImage';
import CustomTableForAppUser from "../../common/CustomTableForAppUser";
import BmiModal from '../../common/modals/BmiModal';
import DeleteModal from '../../common/modals/DeleteModal';
import MobileModal from '../../common/modals/MobileModal';
import MoodModal from '../../common/modals/MoodModal';
import NoteModal from '../../common/modals/NoteModal';
import WeightModal from '../../common/modals/WeightModal';
import { emitErrorToast, emitSuccessToast } from '../../common/toast/ToastContainer';
const ViewAppUser = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { id } = useParams();
    const [showModal, setShowModal] = useState(false)
    const [showWeightModal, setShowWeightModal] = useState(false)
    const [showMoodModal, setShowMoodModal] = useState(false)
    const [showMobileModal, setShowMobileModal] = useState(false)
    const [showBmiModal, setShowBmiModal] = useState(false)
    const [showNoteModal, setShowNoteModal] = useState(false)
    const [appUserData, setAppUserData] = useState([]);
    const [babyData, setBabyData] = useState([]);
    const [emergencyData,setEmergencyData]=useState([]);
    const [appuserMoodData, setAppUserMoodData] = useState([]);
    const [appuserWeightData, setAppUserWeightData] = useState([]);
    const [appuserNoteData, setAppUserNoteData] = useState([]);
    const [clickedNoteData, setClickedNoteData] = useState(null);
    const tableHeadingforMood = ["Date", "Time","Mood"]
    const tableHeadingforBaby = ["Name", "Gender","BabyBornDate", "Action"]
    const tableHeadingforWeight = ["Week", "Date","Weight" ]
    const tableHeadingforEmergency = ["ContactName", "ContactMobile","ContactPhone", "Action"]
    useEffect(() => {
        if (id) {
            getItemDetail()
        }
        // eslint-disable-next-line
    }, [])


    // Fetch User Data 
    const getItemDetail = async () => {
        const { data } = await new APIServices(`appuser/${id}`).get()
        if (data) {
            setAppUserData(data.appUsers)
            setBabyData(data.babies)
            setAppUserMoodData(data.usermood)
            setAppUserWeightData(data.userweight)
            setAppUserNoteData(data.userNotes)
            setEmergencyData(data.emergencypurpose)
        }
    }

    const handleShowDeleteModal = () => {
        setShowModal(true)
    }
    const handleShowWeightModal = () => {
        setShowWeightModal(true)
    }
    const handleShowMoodModal = () => {
        setShowMoodModal(true)
    }
    const handleShowMobileModal = () => {
        setShowMobileModal(true)
    }
    const handleShowBmiModal = () => {
        setShowBmiModal(true)
    }

    const handleShowNoteModal = (noteId) => {
        const clickedNote = appuserNoteData.find(item => item._id === noteId);
        setShowNoteModal(true);
        setClickedNoteData(clickedNote);
    }

    const handleWeight = async (values, actions) => {
        let { setSubmitting, resetForm } = actions
        let payload = {
            ...values,
        }
        const { message, success } = await new APIServices(`appuser/weight/${id}`).post(payload)
        if (success) {
            setSubmitting(false);
            resetForm();
            setShowWeightModal(false)
            emitSuccessToast(message)
            navigate(`/appuser/view/${id}`, { replace: true });
        } else {
            setShowWeightModal(true)
            emitErrorToast(message)
        }
    }
    function getOrdinal(number) {
        const suffixes = ["th", "st", "nd", "rd"];
        const v = number % 100;
        return number + (suffixes[(v - 20) % 10] || suffixes[v] || suffixes[0]);
    }
    const handleMood = async (values, actions) => {
        let { setSubmitting, resetForm } = actions
        let payload = {
            ...values,
        }
        const { message, success } = await new APIServices(`appuser/mood/${id}`).post(payload)
        if (success) {
            setSubmitting(false);
            resetForm();
            setShowMoodModal(false)
            emitSuccessToast(message)
            navigate(`/appuser/view/${id}`, { replace: true });
        } else {
            setShowMoodModal(true)
            emitErrorToast(message)
        }
    }
    const handleMobile = async (values, actions) => {
        let { setSubmitting, resetForm } = actions
        let payload = {
            ...values,
        }
        const { message, success } = await new APIServices(`appuser/mobile/${id}`).post(payload)
        if (success) {
            setSubmitting(false);
            resetForm();
            setShowMobileModal(false)
            emitSuccessToast(message)
            navigate(`/appuser/view/${id}`, { replace: true });
        } else {
            setShowMobileModal(true)
            emitErrorToast(message)
        }
    }

    const handleNote = async (values, actions) => {
        const { _id: noteId } = values
        let { setSubmitting, resetForm } = actions
        let payload = {
            ...values,
        }

    
        const { message, success } = !!!noteId ? await new APIServices(`appuser/note/${id}`).post(payload) :
            await new APIServices(`appuser/update-note/${noteId}`).put(payload)
        if (success) {
            setSubmitting(false);
            resetForm();
            setShowNoteModal(false)
            emitSuccessToast(message)
            navigate(`/appuser/view/${id}`, { replace: true });
        } else {
            setShowNoteModal(true)
            emitErrorToast(message)
        }
     }
    const handleBmi = async (values, actions) => {
        let { setSubmitting, resetForm } = actions
        let payload = {
            ...values,
        }

        const { message, success } = await new APIServices(`appuser/bmi/${id}`).post(payload)
        if (success) {
            setSubmitting(false);
            resetForm();
            setShowBmiModal(false)
            emitSuccessToast(message)
            navigate(`/appuser/view/${id}`, { replace: true });
        } else {
            setShowBmiModal(true)
            emitErrorToast(message)
        }
    }
    const handleDelete = async () => {
        const { message, success } = await new APIServices(`appuser/${id}`).delete()
        if (success) {
            setShowModal(false)
            emitSuccessToast(message)
            navigate("/appuser");
        } else {
            setShowModal(true)
            emitErrorToast(message)
        }
    }
    const { _id, Address, AliasName, Cellphone,
        DOB, Email, Name, Phone, ProfileImage,
        Status, UserName, MotherBloodGroup, BMI, CurrentStatus, Country,
        Province, District, LocalBodies, Language, LoginIP, Device, LoginFrom, LoginFor, LastLogin, LastLoginIP,
        MotherCurrentWeight, ShowWeightIn, CreatedFrom, MotherHeight, MoodReminderIn, ExpectedDeliveryDate, LastPeriodDate, Phase } = appUserData

    const isExternalImage = ProfileImage && ProfileImage.startsWith('https://');

    return (
        <section className="bg-gray-100">
            <div className="container px-4 mx-auto">
                <div className="pt-8 pb-2">
                    <div className="flex items-center mb-3">
                        <Link to={`/appuser`} className="bg-gray-800 hover:bg-blue-700 text-white text-sm px-2 py-2 rounded" id="sidebar-open" >
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-left" viewBox="0 0 16 16">
                                <path fillRule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z" />
                            </svg>
                        </Link>
                        <p className="ml-4 text-xl font-bold"> {t("App User Detail")} </p>
                        <div className="text-sm italic font-normal text-gray-500 ml-5"> ( In order to delete this user. Make this user inactive. ) 
                        </div>
                        {
                            Status === "Inactive" && (
                                <button onClick={() => handleShowDeleteModal(_id)} className="font-medium text-blue-600 hover:text-blue-500 dark:hover:text-gray-300 dark:text-blue-300 ml-2">
                                 <svg className="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                                <path stroke="red" strokeLinejoin="round" strokeWidth="2" d="M8 8v1h4V8m4 7H4a1 1 0 0 1-1-1V5h14v9a1 1 0 0 1-1 1ZM2 1h16a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1Z"/>
                            </svg>
                                </button>
                            )
                        }
                    </div>
                    <div className="grid grid-cols-2 gap-4">
                        <div>
                            <div className="flex gap-x-4">
                                <div className="user-profile w-[180px] h-[180px]">
                                    {isExternalImage ?
                                        <img src={ProfileImage} alt="img"
                                            className="object-contain employee-avatar cover h-full w-full rounded-md" />
                                        :
                                        <CustomImage alt="" source={ProfileImage ? ProfileImage : ""} className="object-contain employee-avatar cover h-full w-full rounded-md" />
                                    }
                                </div>
                                <div className="flex-1">
                                    <div className="flex items-center">
                                        <div className="font-bold text-2xl" name="">
                                            {Name}
                                        </div>
                                        {Status === "Active" ? (
                                            <div className="rounded-full w-3 h-3 bg-green-700 ml-2"></div>
                                        ) : (
                                            <div className="rounded-full w-3 h-3 bg-red-700 ml-2"></div>
                                        )}
                                    </div>
                                    <div className="mb-3 text-gray-500">
                                        <span className="text-base">{Email}</span>
                                    </div>
                                    <div className="grid grid-cols-3 gap-x-2">

                                        <div className="flex flex-col">
                                            <span className="block mb-1 text-sm text-gray-600 font-normal"> {t("Blood Group")} </span>
                                            <span className="font-bold" blood-group="">{MotherBloodGroup} </span>
                                        </div>

                                        <div className="flex flex-col">
                                            <span className="block mb-0 text-sm text-gray-600 font-normal"> {t("Phone")} </span>
                                            <span className="mb-5 text-base font-semibold text-gray-800" blood-group="">{Phone}</span>
                                        </div>

                                        <div className="flex flex-col">
                                            <span className="block mb-0 text-sm text-gray-600 font-normal"> {t("Cellphone")}  <button type="button" onClick={() => handleShowMobileModal(_id)}  className="px-1 font-small text-blue-600 hover:text-blue-500 dark:hover:text-gray-300 dark:text-blue-300">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="9" height="9"
                                                                    fill="currentColor" className="w-4 h-4 mr-2 bi bi-pencil-square"
                                                                    viewBox="0 0 16 16">
                                                                    <path
                                                                        d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                                                    <path fillRule="evenodd"
                                                                        d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                                                                </svg>
                                            </button>

                                            {
                                                showMobileModal && <MobileModal data ={appUserData} handleMobile={handleMobile} close={setShowMobileModal} />
                                             } </span>
                                            <span className="mb-2 text-base font-semibold text-gray-800" blood-group="">{Cellphone} </span>
                                           
                                        </div>
                                        <div>
                                            
                                             </div>
                                    </div>
                                </div>
                            </div>
                            <div className="grid grid-cols-1 xl:grid-cols-2  gap-x-4">
                                <div>
                                    <div className="flex justify-between mt-5 mb-2 items-center">
                                        <h2 className="font-bold text-lg"> {t("Weight History")} </h2>
                                        <div>
                                            <button onClick={() => handleShowWeightModal(_id)} className="bg-green-600 hover:bg-blue-700 text-white text-xs px-1 py-1 rounded font-bold mr-1 ">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus-lg w-4 h-4 " viewBox="0 0 16 16">
                                                    <path fillRule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z" />
                                                </svg>
                                            </button>
                                            <Link to={`/appuser/view/weight/${id}`} className="bg-blue-600 text-white text-xs px-3 py-1 rounded "> {t("View More")} </Link>
                                            {
                                                showWeightModal && <WeightModal handleWeight={handleWeight} close={setShowWeightModal} />
                                            }
                                        </div> 
                                    </div>
                                     {
                        <>
                            <CustomTableForAppUser
                                tableData={appuserWeightData}
                                tableHeadingData={tableHeadingforWeight}
                            />
                        </>
                }
                                </div>

                                <div>
                                    <div className="flex justify-between mt-5 mb-2 items-center">
                                        <h2 className="font-bold text-lg"> {t("Mood History")} </h2>
                                        <div>
                                            <button onClick={() => handleShowMoodModal(_id)} className="bg-green-600 hover:bg-blue-700 text-white text-xs px-1 py-1 mr-1 rounded font-bold " >
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus-lg w-5 h-4" viewBox="0 0 16 16">
                                                    <path fillRule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z" />
                                                </svg>
                                            </button>

                                            <Link to={`/appuser/view/mood/${id}`} className="bg-blue-600 text-white text-xs px-3 py-1 rounded "> {t("View More")} </Link>
                                            {
                                                showMoodModal && <MoodModal handleMood={handleMood} close={setShowMoodModal} />
                                            }

                                        </div>
                                    </div>
                                      {
                        <>
                            <CustomTableForAppUser
                                tableData={appuserMoodData}
                                tableHeadingData={tableHeadingforMood}
                            />
                        </>
                }
                                </div>

                            </div>

                            <div>
                                <h2 className="font-bold text-xl mt-5 mb-2"> {t("BMI")} </h2>
                                <div className="shadow bg-white border-gray-500 rounded p-8">
                                    <div className="grid grid-cols-2 gap-x-2">
                                        <div className="flex flex-col justify-center items-center">
                                        {/* eslint-disable-next-line */}
                                            {(BMI!=0) ?
                                                <>
                                                    <div className="text-7xl font-bold">
                                                        {BMI}
                                                    </div>
                                                    <span className="mt-4 text-lg">
                                                        {(+BMI < 18) && "Underweight"}
                                                        {(+BMI >= 18.5 && +BMI <= 24.9) && "Normal"}
                                                        {(+BMI >= 25 && +BMI <= 29.9) && "Overweight"}
                                                        {(+BMI >= 30 && +BMI <= 34.9) && "Obese"}
                                                        {(+BMI >= 35) && "Extremely Obese"}
                                                    </span>
                                                </>
                                                :
                                                <>
                                                    <div className="text-md font-bold italic">{t("Mother Weight & Height is Not Set")} </div>
                                                    <div className="mt-2">
                                                        <button onClick={() => handleShowBmiModal(_id)} className="bg-blue-600 cursor-pointer text-white text-xs px-3 py-1 rounded"> {t("Add Weight/Height")} </button>
                                                    </div>
                                                    {
                                                        showBmiModal && <BmiModal data={appUserData} handleBmi={handleBmi} close={setShowBmiModal} />
                                                    }
                                                </>
                                            }
                                        </div>
                                        <div>
                                            <div className="flex justify-between mb-3">
                                                <div className="flex gap-x-1">
                                                    <svg className="w-7 h-7" viewBox="0 0 94 280" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M27.7192 278.2C27.7192 277.4 29.0192 276.3 30.7192 275.7C32.3192 275.1 33.8192 274.1 34.0192 273.5C34.4192 272.5 33.8192 268.2 28.2192 230.5C26.8192 220.6 25.2192 209.8 24.7192 206.5C22.1192 187.7 21.3192 183 20.7192 182C20.2192 181.2 18.4192 181.9 15.0192 184.4C8.41916 189.1 4.41916 189.3 2.01916 184.8C0.51916 182 0.21916 178.5 0.0191602 160.5C-0.0808398 149 0.21916 136.8 0.71916 133.5C1.21916 130.2 2.11916 123.5 2.81916 118.5C4.51916 104.8 8.61916 88.8 10.8192 86.7C11.8192 85.7 12.7192 84.5 12.7192 83.9C12.7192 82.4 24.4192 71 26.1192 71C26.9192 71 29.7192 71.9 32.4192 73.1C36.5192 74.9 38.7192 75.1 47.7192 74.8C55.1192 74.6 59.2192 73.9 61.6192 72.7C66.5192 70.2 67.8192 70.5 72.3192 75.2C84.4192 88.2 87.2192 96.2 91.2192 129.8C95.5192 165.4 93.4192 188.1 86.0192 187.3C82.3192 186.9 76.2192 183.7 74.1192 181.1C72.4192 179 71.8192 180.7 70.7192 190.7C70.2192 195.5 68.6192 206.7 67.3192 215.5C65.9192 224.3 63.9192 237.8 62.8192 245.5C61.6192 253.2 60.3192 262.3 59.7192 265.8C59.1192 269.3 58.9192 272.5 59.2192 273.1C59.6192 273.6 61.6192 275 63.9192 276.1C66.1192 277.2 67.6192 278.4 67.2192 278.8C66.8192 279.2 57.8192 279.5 47.1192 279.5C31.6192 279.5 27.7192 279.2 27.7192 278.2Z" fill="#53ABC7" />
                                                        <path d="M35.5192 68.7C22.9192 64 15.0192 55.2 12.0192 42.6C8.31922 26.6 15.6192 11.6 31.0192 3.6C37.2192 0.3 38.6192 0 45.8192 0C53.0192 0 54.6192 0.4 61.2192 3.6C69.4192 7.6 74.3192 12.4 78.5192 20.5C80.7192 24.8 81.1192 26.8 81.2192 34.5C81.2192 45.8 78.8192 52.1 71.4192 59.5C63.8192 66.9 58.1192 69.4 47.7192 69.7C42.4192 69.9 37.8192 69.5 35.5192 68.7Z" fill="#53ABC7" />
                                                    </svg>
                                                    <span> {t("Underweight")} </span>
                                                </div>
                                                <span> {`< 18 `}</span>
                                            </div>
                                            <div className="flex justify-between mb-3">
                                                <div className="flex gap-x-1">
                                                    <svg className="w-7 h-7" viewBox="0 0 104 281" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M28.9625 278.601C29.8625 277.701 32.0625 276.201 33.7625 275.401C36.6625 274.001 36.8625 273.501 36.6625 268.901C36.5625 262.901 31.4625 229.001 27.9625 210.901C26.6625 203.801 25.1625 194.601 24.7625 190.701C24.3625 186.601 23.6625 183.401 23.0625 183.401C22.4625 183.401 19.1625 184.801 15.7625 186.601C8.06248 190.601 4.96248 190.001 1.86248 183.901C0.0624752 180.301 -0.137525 178.101 0.0624753 159.401C0.262475 126.601 5.86248 94.3014 12.8625 85.4014C15.4625 82.1014 24.0625 73.8014 26.9625 71.7014L30.1625 69.4014L35.9625 72.5014C41.6625 75.6014 42.0625 75.7014 52.1625 75.1014C60.5625 74.7014 63.3625 74.1014 67.9625 71.9014C73.2625 69.3014 73.4625 69.3014 76.1625 71.0014C84.9625 76.8014 93.5625 88.9014 96.1625 99.4014C98.5625 108.601 100.462 119.201 101.462 127.201C101.962 131.801 102.562 136.001 102.862 136.501C103.162 136.901 103.462 147.401 103.462 159.801C103.562 184.101 103.262 185.501 97.8625 188.401C95.5625 189.601 94.7625 189.501 89.2625 186.601C85.9625 184.801 82.6625 183.401 82.0625 183.401C80.9625 183.401 79.9625 187.901 76.2625 209.401C73.5625 225.501 71.3625 240.601 71.3625 243.201C71.3625 244.901 70.9625 246.601 70.3625 246.901C69.8625 247.201 69.3625 249.001 69.3625 250.901C69.3625 252.801 68.9625 255.301 68.4625 256.601C67.9625 257.901 67.4625 262.301 67.2625 266.401L66.8625 273.801L70.7625 275.901C72.8625 277.001 74.5625 278.401 74.4625 278.901C74.3625 279.501 65.6625 280.001 50.8625 280.101C28.6625 280.301 27.3625 280.201 28.9625 278.601Z" fill="#58B40E" />
                                                        <path d="M40.8626 69.4013C34.0626 67.2013 25.9626 61.5013 22.6626 56.6013C21.4626 54.8013 19.4626 50.3013 18.2626 46.7013C15.4626 38.2013 15.8626 27.1013 19.2626 20.5013C22.6626 13.9013 29.7626 6.90129 36.6626 3.30129C42.0626 0.501293 43.8626 0.101293 50.8626 0.00129272C64.6626 -0.0987073 75.0626 5.60129 82.7626 17.7013C86.2626 23.2013 86.6626 24.5013 87.1626 32.3013C88.0626 48.8013 81.1626 60.8013 66.8626 67.8013C58.9626 71.7013 49.4626 72.3013 40.8626 69.4013Z" fill="#58B40E" />
                                                    </svg>
                                                    <span> {t("Normal")} </span>
                                                </div>
                                                <span> 18.5 - 24.9 </span>
                                            </div>
                                            <div className="flex justify-between mb-3">
                                                <div className="flex gap-x-1">
                                                    <svg className="w-7 h-7" viewBox="0 0 121 280" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M32.848 279.259C32.848 278.459 33.548 277.659 37.648 274.559L39.948 272.859L38.348 264.359C37.448 259.659 36.348 253.459 35.848 250.459C35.348 247.459 33.748 238.959 32.348 231.459C30.848 223.959 28.248 209.959 26.448 200.359C23.248 183.059 23.248 182.859 20.848 183.159C19.448 183.359 17.948 184.059 17.348 184.759C15.748 186.859 12.148 188.359 8.94796 188.359C1.64796 188.359 -0.85204 175.059 0.24796 141.359C1.14796 114.259 5.04796 93.7587 10.548 87.2587C11.248 86.4587 11.848 85.1587 11.848 84.4587C11.848 83.8587 12.548 83.0587 13.348 82.7587C14.148 82.4587 16.748 80.4587 19.048 78.2587C21.448 76.1587 25.348 73.3587 27.848 72.1587C30.348 70.8587 32.848 69.2587 33.448 68.4587C34.448 67.1587 35.948 67.6587 43.148 71.3587L51.748 75.6587L61.448 75.0587C69.348 74.5587 72.448 73.8587 77.648 71.3587C84.548 68.1587 87.748 67.6587 89.648 69.5587C90.248 70.1587 92.648 71.6587 94.748 72.7587C101.548 76.2587 112.448 87.6587 113.748 92.5587C114.448 94.9587 115.448 98.4587 115.948 100.359C118.748 109.859 119.948 121.559 120.548 144.159C121.248 172.759 120.148 181.759 115.648 186.559C112.548 189.859 110.548 189.759 105.148 185.759C103.448 184.459 100.948 183.259 99.648 183.159C97.748 182.859 97.348 183.359 97.248 185.859C97.148 189.359 94.248 206.159 93.448 208.359C93.148 209.159 92.448 212.759 91.848 216.359C91.248 219.959 90.148 226.459 89.348 230.859C88.548 235.259 87.148 242.459 86.448 246.859C85.648 251.259 84.248 258.659 83.348 263.259C82.448 267.959 81.948 272.259 82.248 272.959C82.548 273.659 83.948 274.759 85.548 275.559C87.048 276.259 88.348 277.559 88.348 278.359C88.348 279.659 84.448 279.859 60.648 279.959C45.348 280.059 32.848 279.759 32.848 279.259Z" fill="#DEE20D" />
                                                        <path d="M50.348 69.4588C44.648 67.9588 32.848 60.4588 32.848 58.3588C32.848 57.8588 32.248 56.8588 31.548 56.1588C30.948 55.4588 29.248 52.3588 27.848 49.3588C25.648 44.6588 25.348 42.7588 25.548 34.1588C25.748 25.1588 26.048 23.8588 28.848 18.8588C32.348 12.6588 39.548 5.8588 45.948 2.5588C49.448 0.758803 52.248 0.258803 59.448 0.0588028C68.248 -0.141197 68.748 -0.0411972 76.448 3.8588C87.648 9.5588 93.848 17.9588 96.048 30.5588C97.348 37.8588 96.148 43.6588 91.748 52.4588C88.148 59.7588 81.848 65.1588 72.948 68.6588C64.848 71.7588 59.348 71.9588 50.348 69.4588Z" fill="#DEE20D" />
                                                    </svg>
                                                    <span> {t("Overweight")} </span>
                                                </div>
                                                <span> 25 - 29.9 </span>
                                            </div>
                                            <div className="flex justify-between mb-3">
                                                <div className="flex gap-x-1">
                                                    <svg className="w-7 h-7" viewBox="0 0 137 281" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M37.3374 278.822C37.6374 278.022 37.9374 277.122 37.9374 276.922C37.9374 276.722 38.5374 276.522 39.2374 276.522C39.8374 276.522 41.4374 275.722 42.7374 274.722C45.2374 272.822 45.1374 271.122 41.1374 257.022C38.7374 248.722 34.3374 232.122 29.5374 213.522C24.0374 192.022 21.7374 184.122 20.7374 182.822C20.0374 181.922 18.8374 182.522 16.3374 185.022C11.8374 189.622 7.93736 189.722 4.93736 185.522C2.43736 182.022 0.237363 169.922 0.0373633 158.522C-0.462637 135.822 4.13736 101.822 8.93736 92.522C11.2374 88.022 20.6374 78.322 24.9374 76.122C26.3374 75.422 29.9374 73.422 32.9374 71.622C35.9374 69.922 39.2374 68.522 40.1374 68.522C41.0374 68.522 41.9374 68.022 42.1374 67.422C42.3374 66.822 45.0374 67.922 48.2374 69.822C55.6374 74.322 62.3374 75.922 71.4374 75.322C77.4374 74.922 80.2374 74.122 86.8374 70.822C91.2374 68.622 95.2374 67.122 95.6374 67.522C96.0374 67.922 98.4374 69.022 100.937 69.922C107.437 72.422 120.937 81.822 124.337 86.122C132.537 96.522 137.737 127.022 136.737 158.722C136.137 177.022 134.337 184.422 129.837 187.722C126.837 189.922 123.637 189.122 118.537 184.922L115.537 182.322L114.737 185.122C112.137 194.022 95.2374 260.422 93.9374 266.522C93.5374 268.422 92.9374 270.722 92.5374 271.522C92.1374 272.522 93.2374 273.822 95.9374 275.522C98.1374 276.822 99.9374 278.422 99.9374 279.122C99.9374 280.022 91.9374 280.322 68.3374 280.322C41.1374 280.322 36.9374 280.122 37.3374 278.822Z" fill="#E4730A" />
                                                        <path d="M56.8373 69.2219C47.0373 65.1219 39.5373 57.7219 35.2373 48.0219C33.1373 43.1219 32.6373 24.4219 34.7373 23.7219C35.3373 23.5219 35.9373 22.7219 35.9373 21.9219C35.9373 19.4219 40.5373 13.0219 45.4373 8.72191C56.9373 -1.37809 73.9373 -2.87809 87.5373 5.12191C101.037 13.0219 107.237 31.1219 101.937 47.5219C100.637 51.5219 99.0373 54.9219 98.2373 55.2219C97.5373 55.5219 96.9373 56.3219 96.9373 57.0219C96.9373 59.0219 90.5373 64.1219 83.6373 67.6219C74.9373 72.0219 64.9373 72.6219 56.8373 69.2219Z" fill="#E4730A" />
                                                    </svg>
                                                    <span> {t("Obese")} </span>
                                                </div>
                                                <span> 30 - 34.9 </span>
                                            </div>
                                            <div className="flex justify-between mb-3">
                                                <div className="flex gap-x-1">
                                                    <svg className="w-7 h-7" viewBox="0 0 156 281" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M40.9883 278.013C41.5883 276.813 42.7883 275.313 43.6883 274.613C44.9883 273.713 45.0883 272.913 44.0883 269.113C43.3883 266.813 40.0883 254.213 36.6883 241.313C33.3883 228.413 28.8883 211.513 26.7883 203.813C24.6883 196.113 22.6883 188.313 22.2883 186.613C21.4883 182.513 20.4883 182.513 16.0883 186.313C12.3883 189.613 11.9883 189.713 8.48827 188.413C1.68827 185.813 -1.51173 164.913 0.688269 136.313C1.98827 118.913 3.48827 110.313 7.08827 98.8134C8.98827 92.8134 10.1883 90.9134 15.7883 85.3134C22.4883 78.6134 33.8883 71.9134 45.5883 67.7134C50.8883 65.9134 50.9883 65.9134 54.0883 67.8134C62.9883 73.1134 67.1883 74.5134 75.4883 75.1134C82.4883 75.5134 84.7883 75.2134 89.9883 73.3134C93.4883 72.0134 97.8883 70.0134 99.7883 68.7134C101.688 67.4134 104.088 66.3134 104.988 66.3134C107.288 66.3134 123.088 73.1134 129.388 76.8134C136.988 81.3134 143.488 88.0134 146.788 94.7134C154.288 110.313 157.888 153.413 153.588 176.613C152.188 184.313 147.288 189.913 143.088 188.813C141.488 188.513 139.188 187.113 137.688 185.713C134.688 182.813 132.788 182.613 132.788 185.313C132.788 186.313 132.188 189.213 131.388 191.513C129.988 196.013 128.088 202.913 120.388 232.313C117.788 241.913 114.388 254.613 112.688 260.613C109.588 271.913 109.588 273.013 113.688 276.713C114.788 277.713 115.488 278.913 115.288 279.313C114.988 279.713 97.8883 280.113 77.2883 280.113H39.7883L40.9883 278.013Z" fill="#BF0E0E" />
                                                        <path d="M68.0883 69.8134C57.4883 66.5134 48.4883 58.3134 44.6883 48.4134C41.3883 40.0134 41.4883 27.6134 44.9883 20.8134C49.2883 12.4134 54.7883 7.01337 62.8883 3.31337C69.0883 0.413373 71.1883 0.0133729 78.1883 0.0133729C85.3883 -0.0866271 86.9883 0.313373 93.0883 3.31337C100.888 7.11337 103.988 10.0134 108.588 17.6134C110.888 21.5134 112.288 25.3134 112.988 30.2134C113.988 36.7134 113.788 38.0134 111.488 45.6134C109.688 51.4134 107.988 54.7134 105.788 56.8134C104.188 58.4134 102.788 60.2134 102.788 60.9134C102.788 62.8134 91.0883 68.9134 84.9883 70.2134C78.1883 71.6134 73.3883 71.5134 68.0883 69.8134Z" fill="#BF0E0E" />
                                                    </svg>
                                                    <span> {t("Extremely Obese")} </span>
                                                </div>
                                                <span> {`35 >`} </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                           
                            
                            <div>
                                <div className="flex justify-between mt-5 mb-2 items-center">
                                    <h2 className="font-bold text-lg mt-5 mb-2"> {t("Baby Information")} </h2>
                                    <div className="mt-4">
                                <Link to={`/baby/add/${id}`} className="bg-blue-600 text-white text-xs px-3 py-1 rounded">
                                    {babyData.length === 0 ? t("First Baby") : `${getOrdinal(babyData.length + 1)} ${t("Baby")}`}
                                </Link>
                            </div>
                                </div>
                      </div>
                      {
                        
                            
                            <>
                                <CustomTableForAppUser
                                    tableData={babyData}
                                    tableHeadingData={tableHeadingforBaby}
                                    handleDelete={false}
                                    ForEdit={`baby`}
                                    ForView={`baby`}
                                    actionField="_id"
                                />
                            </>
                    }
                            <div>
                                <div className="flex justify-between mt-5 mb-2 items-center">
                                    <div className="flex items-center gap-2 mt-5 mb-2">
                                    <h2 className="font-bold text-lg  "> {t("Emergency Information")} </h2>
                                    <div>
                                    <Link to={`/emergency/list/${id}`} className="bg-green-600 text-white text-xs px-3 py-1 rounded">
                                  {t("View More")}
                                </Link>
                                </div>
                                </div>
                                    <div className="mt-5 mb-1">
                                <Link to={`/emergency/add/${id}`} className="bg-blue-600 text-white text-xs px-3 py-1 rounded">
                                  {t("Add Emergency")}
                                </Link>
                            </div>
                                </div>
                                {
                        
                            
                        <>
                            <CustomTableForAppUser
                                tableData={emergencyData}
                                tableHeadingData={tableHeadingforEmergency}
                                handleDelete={false}
                                ForEdit={`emergency`}
                                ForView={`emergency`}
                                actionField="_id"
                            />
                        </>
                }  
                                <div className="mt-4">
                            </div>
                            </div>
                        </div>
                        <div>
                            <div className="flex justify-between mt-5 mb-2 items-center">
                                <h2 className="font-bold text-lg mt-5 mb-2"> {t("Personal Information")} </h2>
                                <div>
                                    <Link to={`/appuser/edit/${id}`} className="bg-blue-600 cursor-pointer text-white text-xs px-3 py-1 rounded"> {t("Update Personal Info")} </Link>
                                </div>
                            </div>
                            <div className="shadow bg-white border-gray-500 p-8 rounded">
                                <div className="flex flex-wrap">
                                    <div className="w-1/2">
                                        <label className="block mb-0 text-base text-gray-600 font-normal">
                                            {t("User Name")}
                                        </label>
                                        <h2 className="mb-5 text-lg font-semibold text-gray-800">
                                            {UserName}
                                        </h2>
                                    </div>
                                    <div className="w-1/2">
                                        <label className="block mb-0 text-base text-gray-600 font-normal">
                                            {t("Alias Name")}
                                        </label>
                                        <h2 className="mb-5 text-lg font-semibold text-gray-800">
                                            {AliasName}
                                        </h2>
                                    </div>
                                    <div className="w-1/2">
                                        <label className="block mb-0 text-base text-gray-600 font-normal">
                                            {t("Current Status")}
                                        </label>
                                        <h2 className="mb-5 text-lg font-semibold text-gray-800">
                                            {CurrentStatus}
                                        </h2>
                                    </div>
                                    <div className="w-1/2">
                                        <label className="block mb-0 text-base text-gray-600 font-normal">
                                            {t("Country")}
                                        </label>
                                        <h2 className="mb-5 text-lg font-semibold text-gray-800">
                                            {Country}
                                        </h2>
                                    </div>
                                    <div className="w-1/2">
                                        <label className="block mb-0 text-base text-gray-600 font-normal">
                                            DOB
                                        </label>
                                        <h2 className="mb-5 text-lg font-semibold text-gray-800">
                                            {ADToBS(moment(DOB).format("YYYY-MM-DD"))}
                                        </h2>
                                    </div>
                                    <div className="w-1/2">
                                        <label className="block mb-0 text-base text-gray-600 font-normal">
                                            {t("Province")}
                                        </label>
                                        <h2 className="mb-5 text-lg font-semibold text-gray-800">
                                            {Province}
                                        </h2>
                                    </div>
                                    <div className="w-1/2">
                                        <label className="block mb-0 text-base text-gray-600 font-normal">
                                            {t("District")}
                                        </label>
                                        <h2 className="mb-5 text-lg font-semibold text-gray-800">
                                            {District}
                                        </h2>
                                    </div>
                                    <div className="w-1/2">
                                        <label className="block mb-0 text-base text-gray-600 font-normal">
                                            {t("Local Body")}
                                        </label>
                                        <h2 className="text-lg font-semibold text-gray-800">
                                            {LocalBodies}
                                        </h2>
                                    </div>
                                    <div className="w-1/2">
                                        <label className="block mb-0 text-base text-gray-600 font-normal">
                                            {t("Address")}
                                        </label>
                                        <h2 className="text-lg font-semibold text-gray-800">
                                            {Address}
                                        </h2>
                                    </div>
                                    <div className="w-1/2">
                                        <label className="block mb-0 text-base text-gray-600 font-normal">
                                            {t("Language")}
                                        </label>
                                        <h2 className=" text-lg font-semibold text-gray-800">
                                            {Language}
                                        </h2>
                                    </div>
                                </div>
                            </div>
                            <h2 className="font-bold text-lg mt-5 mb-2"> {t("Login Information")} </h2>
                            <div className="shadow bg-white border-gray-500 p-8 mb-4 rounded">
                                <div className="flex flex-wrap">
                                    <div className="w-1/2">
                                        <label className="block mb-0 text-base text-gray-600 font-normal">
                                            {t("Login IP")}
                                        </label>
                                        <h2 className="mb-5 text-lg font-semibold text-gray-800">
                                            {LoginIP}
                                        </h2>
                                    </div>
                                    <div className="w-1/2">
                                        <label className="block mb-0 text-base text-gray-600 font-normal">
                                            {t("Device")}
                                        </label>
                                        <h2 className="mb-5 text-lg font-semibold text-gray-800">
                                            {Device}
                                        </h2>
                                    </div>
                                    <div className="w-1/2">
                                        <label className="block mb-0 text-base text-gray-600 font-normal">
                                            {t("Login From")}
                                        </label>
                                        <h2 className="mb-5 text-lg font-semibold text-gray-800">
                                            {LoginFrom}
                                        </h2>
                                    </div>
                                    <div className="w-1/2">
                                        <label className="block mb-0 text-base text-gray-600 font-normal">
                                            {t("Login For")}
                                        </label>
                                        <h2 className="mb-5 text-lg font-semibold text-gray-800">
                                            {LoginFor}
                                        </h2>
                                    </div>
                                    <div className="w-1/2">
                                        <label className="block mb-0 text-base text-gray-600 font-normal">
                                            {t("Last Login")}
                                        </label>
                                        <h2 className="text-lg font-semibold text-gray-800">
                                            {ADToBS(moment(LastLogin).format('YYYY-MM-DD'))}
                                        </h2>
                                    </div>
                                    <div className="w-1/2">
                                        <label className="block mb-0 text-base text-gray-600 font-normal">
                                            {t("Last Login IP")}
                                        </label>
                                        <h2 className="text-lg font-semibold text-gray-800">
                                            {LastLoginIP}
                                        </h2>
                                    </div>
                                    <div className="w-1/2">
                                        <label className="block mb-0 text-base text-gray-600 font-normal">
                                            {t("Created From")}
                                        </label>
                                        <h2 className="text-lg font-semibold text-gray-800">
                                            {CreatedFrom}
                                        </h2>
                                    </div>

                                </div>
                            </div>
                            <div>
                                <div className="grid grid-cols-1 xl:grid-cols-2  gap-x-4">
                                    <div>
                                        <div className="flex justify-between mt-5 mb-2 items-center">
                                            <h2 className="font-bold text-lg "> {t("Notes History")} </h2>
                                            <div>
                                                <button onClick={() => handleShowNoteModal()} className="bg-green-600 hover:bg-blue-700 text-white text-xs px-1 py-1 rounded font-bold  ">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="9" height="9" fill="currentColor" className="bi bi-plus-lg w-4 h-4 " viewBox="0 0 16 16">
                                                        <path fillRule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z" />
                                                    </svg>
                                                </button>
                                                <Link to={`/appuser/view/note/${id}`} className="bg-blue-600 text-white text-xs mb-5 mx-2 px-3 py-1 rounded "> {t("View More")} </Link>
                                                {
                                                    showNoteModal && <NoteModal handleNote={handleNote} close={setShowNoteModal} />
                                                }
                                                <div className="flex items-center justify-between">
                                                    <div className="flex items-center">
                                                    </div>
                                                    {
                                                        showModal && <DeleteModal handleDelete={handleDelete} close={setShowModal} />}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="shadow bg-white border-gray-500 rounded">
                                    <table className="w-full table-auto">
                                        <tbody>
                                            {(appuserNoteData.length > 0) ?
                                                appuserNoteData.slice(0, 3).map((item,index) => (
                                                    <tr key={index} className=" border-b border-gray-200">
                                                        <td className="px-6 py-3 text-sm font-medium"> {ADToBS(moment(item?.Date).format("YYYY-MM-DD"))}  </td>
                                                        <td className="px-6 py-3 text-sm font-medium"> {item?.Message} </td>
                                                        <td className="px-6 py-3">
                                                            <button onClick={() => handleShowNoteModal(item?._id)} className="inline-block mr-2">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18"
                                                                    fill="currentColor" className="bi mx-9 object-bottom bi-pencil-square fill-blue-500"
                                                                    viewBox="0 0 16 16">
                                                                    <path
                                                                        d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                                                    <path fillRule="evenodd"
                                                                        d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                                                                </svg>
                                                            </button>
                                                            {

                                                                showNoteModal && <NoteModal handleNote={handleNote} close={setShowNoteModal} noteData={clickedNoteData} />
                                                            }
                                                        </td>
                                                    </tr>
                                                )) :
                                                <tr>
                                                <td colSpan="3" className="px-6 py-3 text-sm font-medium">{t("No Record Found")}</td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <br></br>
                            <div className="shadow bg-white border-gray-500 p-8 rounded">
                                <div className="flex flex-wrap">
                                    <div className="w-1/2">
                                        <label className="block mb-0 text-base text-gray-600 font-normal">
                                            {t("Mother Current Weight")}
                                        </label>
                                        <h2 className="mb-5 text-lg font-semibold text-gray-800">
                                            {MotherCurrentWeight} <span className="text-mds italic font-normal text-gray-500">{ShowWeightIn}</span>
                                        </h2>
                                    </div>
                                    <div className="w-1/2">
                                        <label className="block mb-0 text-base text-gray-600 font-normal">
                                            {t("Mother Height")} (in cm)
                                        </label>
                                        <h2 className="mb-5 text-lg font-semibold text-gray-800">
                                            {MotherHeight}
                                        </h2>
                                    </div>
                                    <div className="w-1/2">
                                        <label className="block mb-0 text-base text-gray-600 font-normal">
                                            {t("Mood Reminder In")}
                                        </label>
                                        <h2 className="mb-5 text-lg font-semibold text-gray-800">
                                            {ADToBS(moment(MoodReminderIn).format('YYYY-MM-DD'))}
                                        </h2>
                                    </div>
                                    <div className="w-1/2">
                                        <label className="block mb-0 text-base text-gray-600 font-normal">
                                            {t("Expected Delivery Date")}
                                        </label>
                                        <h2 className="mb-5 text-lg font-semibold text-gray-800">
                                            {ADToBS(moment(ExpectedDeliveryDate).format("YYYY-MM-DD"))}
                                        </h2>
                                    </div>
                                    <div className="w-1/2">
                                        <label className="block mb-0 text-base text-gray-600 font-normal">
                                            {t("Last Period Date")}
                                        </label>
                                        <h2 className="mb-5 text-lg font-semibold text-gray-800">
                                            {ADToBS(moment(LastPeriodDate).format("YYYY-MM-DD"))}
                                        </h2>
                                    </div>
                                    <div className="w-1/2">
                                        <label className="block mb-0 text-base text-gray-600 font-normal">
                                            {t("Phase")}
                                        </label>
                                        <h2 className="mb-5 text-lg font-semibold text-gray-800">
                                            {Phase}
                                        </h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section >
    )
}

export default ViewAppUser;