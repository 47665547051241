import * as yup from 'yup';
const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/gif", "image/png","image/webp" ];
let PostValidationSchema = yup.object().shape({
    Status: yup.boolean(),
    Title: yup.string().required('Required !'),
    Slug: yup.string().required('Required !'),
    PageType: yup.string().required('Required !'),
    FeaturedImage: yup.mixed().notRequired()
        .nullable()
        .test('fileSize',
            'The file is too large', (value) => {
                if (!value) return true
                return !value || (value && value.size <= (1024 * 1024) * 2)
            })
        .test('fileFormat',
            'Unsupported File Format', (value) => !value || (value && SUPPORTED_FORMATS.includes(value.type))),

    PublishedOn: yup.string(),
    NextReviewDate: yup.string(),
    Tags: yup.array().required('Required!'),
    RedirectTO: yup.string(),
    Remark: yup.string(),
    IsVerified: yup.boolean(),
    OrderBy: yup.string(),
    Detail: yup.string(),
    Summary: yup.string(),
    SeoTitle: yup.string(),
    SeoDescription: yup.string(),

});


export default PostValidationSchema