import { Field } from "formik";
import React from "react";
import SingleFileUpload from "../SingleFileUpload";
import { useTranslation } from "react-i18next";

const FormikImageField = ({ label, name }) => {
  const { t } = useTranslation();
  return (
    <div className="mb-6">
      <label
        className="block mb-2 text-sm font-medium dark:text-gray-400"
        htmlFor={label}
      >
        {t(label)}
      </label>
      <Field name={name}>
        {({ field, form, meta }) => {
          return <SingleFileUpload field={field} form={form} meta={meta} />;
        }}
      </Field>
    </div>
  );
};

export default FormikImageField;
