import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import APIServices from '../../../httpServices/httpServices';
import DeleteModal from '../../common/modals/DeleteModal';
import { emitSuccessToast } from '../../common/toast/ToastContainer';
import DataView from '../../common/viewDetail/DataView';
const ViewRole = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const { id } = useParams();
    const [showModal, setShowModal] = useState(false)
    const [roleData, setRoleData] = useState([]);

    useEffect(() => {
        if (id) {
            getRoleDetailView()
        }
        //eslint-disable-next-line 
    }, [])

    // Fetch User Data 
    const getRoleDetailView = async () => {
        const { data } = await new APIServices(`role/${id}`).get()
        if (data) {
            setRoleData(data)
        }
    }

    const handleShowDeleteModal = (id) => {
        setShowModal(true)
    }

    const handleDeleteRole = async () => {
        const { message, success } = await new APIServices(`role/${id}`).delete()
        if (success) {
            setShowModal(false)
            emitSuccessToast(message)
            navigate("/setting/role");
        }
    }

    const { _id, Title, UniqueName, Type, Status, PermissionValue } = roleData
    const handleEditRole = (id) => {
        navigate(`/setting/role/edit/${id}`);
    }
    return (
        <div className="max-w-6xl px-4 py-4 mx-auto lg:py-8 md:px-6">
            <div className="flex justify-between mt-5 mb-4 items-end">
                <h2 className="font-bold text-lg"> {t("Role Information")}   
                <button
            onClick={() => handleEditRole(id)}
            className="font-medium text-blue-600 hover:text-blue-500 dark:hover:text-gray-300 dark:text-blue-300 ml-2 ">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
            fill="currentColor" className="w-4 h-4 mr-2 bi bi-pencil-square"
            viewBox="0 0 16 16">
            <path
                d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
            <path fillRule="evenodd"
                d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                 </svg>
            </button></h2>
                {
                    Status === "Inactive" && (
                        <button onClick={() => handleShowDeleteModal(_id)} className="inline-block flex items-center px-4 py-2.5 bg-red-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-red-600">
                            {t("Delete")}
                        </button>
                    )
                }
            </div>
            <div className="shadow bg-white border-gray-500 p-8 rounded">
                <div className="flex flex-wrap">
                    <DataView label={t("Title")} value={Title} />
                    <DataView label={t("UniqueName")} value={UniqueName} />
                    <DataView label={t("Status")} value={Status} />
                    <DataView label={t("Type")} value={Type} />
                    <div className="w-full">
                        <label className="block mb-0 text-sm text-gray-600 font-normal"> {t("Role Value")} </label>
                        <div className="flex flex-wrap gap-2 items-center pt-2 font-medium">
                            {PermissionValue && JSON.parse(PermissionValue)?.map((val, idx) => {
                                return (
                                    <span key={idx} className="inline-block px-2 text-xs py-1 text-blue-700 bg-blue-100 rounded dark:bg-gray-800 dark:text-gray-400">{val}</span>
                                )
                            })}
                        </div>
                    </div>

                </div>
            </div>
            {
                showModal && <DeleteModal handleDelete={handleDeleteRole} close={setShowModal} />
            }
            
        </div>
        
    )
}

export default ViewRole