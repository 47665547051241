import { call, put } from 'redux-saga/effects';
import { get } from "../../httpServices";
import { setProfile } from "../features/user/userSlice";


export function* getUserProfile({ type, payload }) {
    try {
        const { data } = yield call(get, payload)
        yield put(setProfile(data));
    } catch (error) {
        yield put(setProfile({ data: null }));
    }
}

