import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from 'react-router-dom';
import APIServices from '../../../httpServices/httpServices';
import { ADToBS } from "bikram-sambat-js";
import NoteModal from "../../common/modals/NoteModal";
import { emitErrorToast, emitSuccessToast } from "../../common/toast/ToastContainer";

const ViewNote = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { id } = useParams();
    // eslint-disable-next-line no-unused-vars
     const [noteData, setNotedata] = useState({});
    const [showNoteModal, setShowNoteModal] = useState(false)
    useEffect(() => {
        if (id)
            handleShownote()
        // eslint-disable-next-line
    }, [id])
    const handleShownote = async () => {
        const { success, data } = await new APIServices(`appuser/get-note/${id}`).get()
        if (success) {
            
           
            setNotedata(data)
            navigate(`/appuser/view/note/${id}`, { replace: true });
        }
    };
    const handleShowNoteModal = () => {
       
        setShowNoteModal(true);
      
    }
    const handleNote = async (values, actions) => {
        const { _id: noteId } = values
        let { setSubmitting, resetForm } = actions
        let payload = {
            ...values,
        }


        const { message, success } =
            await new APIServices(`appuser/update-note/${noteId}`).put(payload)
        if (success) {
            setSubmitting(false);
            resetForm();
            setShowNoteModal(false)
            emitSuccessToast(message)
            navigate(`/appuser/view/${id}`, { replace: true });
        } else {
            setShowNoteModal(true)
            emitErrorToast(message)
        }
     }
    return (
        <>
        <div className="shadow bg-gray border-gray-500 rounded">
            <div className="overflow-y-auto max-h-96" style={{ maxHeight: '40rem' }}>
                        <div className="grid grid-cols-2 gap-4 p-4">
                            {(noteData.usernotes?.length > 0) ?
                                noteData.usernotes?.map((item,index) => (
                                        <div key={index} className="flex flex-wrap justify-center">
                                            <div className="w-full">
                                                <div className="p-5 bg-white rounded-b shadow dark:bg-gray-700">                                          
                                                    <a href=" " className="px-1 py-1 text-sm text-green-600 rounded-full dark:text-gray-400 dark:bg-gray-600 bg-green-50">{ADToBS(moment(item?.Date).format("YYYY-MM-DD"))}</a>
                                                    <button onClick={() => handleShowNoteModal()} className="py-2 mb-2" >
                                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" className="bi mx-9 object-bottom bi-pencil-square fill-green-500" viewBox="0 0 16 16"><path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"></path><path fillRule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"></path></svg>
                                                </button>
                                                
                                                {
                                                    showNoteModal&&  <NoteModal handleNote={handleNote} close={ setShowNoteModal} noteData={item} />
                                                }
                                                
                                                    <p className="mb-3 leading-loose text-gray-500 dark:text-gray-400">{item?.Message}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                      
                                )) :
                                <div colSpan="3" className="px-6 py-3 text-sm font-medium">{t("No Record Found")}</div>
                            }
                        </div>
            </div>
        </div>
        </>
    )
}
export default ViewNote;