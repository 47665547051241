import React, { useEffect, useState } from 'react'
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { TextError } from '../../common/TextError';
import APIServices from '../../../httpServices/httpServices';
import { emitSuccessToast } from '../../common/toast/ToastContainer';
import slugify from 'slugify'
import FormikTextField from '../../common/form/FormikTextField';

import FormikTextAreaField from '../../common/form/FormikTextAreaField';
// import FormikDateField from '../../common/form/FormikDateField';
import FormikSwitchField from '../../common/form/FormikSwitchField';
import FormikNumberField from '../../common/form/FormikNumberField';
import FormikImageField from '../../common/form/FormikImageField';
import FormikRichTextEditor from '../../common/form/FormikRichTextEditor';
import { useLocation, useNavigate } from 'react-router-dom';
import PostValidationSchema from '../../../validation/PostValidationSchema';
import FormikSelectSingleField from '../../common/form/FormikSelectSingleField';
// import FormikDateTimePickerField from '../../common/form/FormikDateTimePickerField';
import FormikCreatableSelect from '../../common/form/FormikCreatableSelect';
import { useTranslation } from 'react-i18next';
import FormikDateTimePickerField from '../../common/form/FormikDateTimePickerField';
import moment from 'moment';
import { ADToBS } from 'bikram-sambat-js';
const PostForm = ({ data: postData, id }) => {
    const { t } = useTranslation()
    const { state } = useLocation();
    const { Pagetype } = state;
    const navigate = useNavigate()
    const [pageTypeOptions, setPageTypeOptions] = useState(null)
    const [toggleSlug, setToggleSlug] = useState(false)
    const [tagListOptions, setTagListOptioins] = useState(null)
    const [scrapedFeaturedImage, setScrapedFeaturedImage] = useState(""); // Added state for scraped featuredImage
    const [initialValues, setInitialValues] = useState({
        Status: true,
        Title: "",
        Slug: "",
        PageType: Pagetype,
        FeaturedImage: "",
        PublishedOn: ADToBS(moment().format("YYYY-MM-DD")),
        NextReviewDate: ADToBS(moment().format("YYYY-MM-DD")),
        Tags: [],
        Categories: [],
        RedirectTo: "",
        Remark: "",
        IsVerified: "",
        OrderBy: "",
        Summary: "",
        Detail: "",
        SeoTitle: "",
        SeoDescription: "",
        RemoveMultiInsertList: [],
        Url: "",
        Video: ""
    })

    useEffect(() => {
        getFormSelectFieldValues()
    }, [])

    useEffect(() => {
        if (!!!id) {
            setToggleSlug(true)
        }
    }, [id])

    useEffect(() => {
        if (postData)
            setInitialValues((prev) => ({
                ...prev,
                ...postData,
            }));
    }, [postData]);

    const handleFormikFormSubmit = async (values, actions) => {
        let oldTags = postData?.Tags
        let tagArrToRemove = []
        let oldCategories = postData?.Categories
        let categoryArrToRemove = []
        let { setSubmitting, resetForm } = actions
        let { RemoveMultiInsertList, RemoveMultiInsertCategoryList, ...others } = values
        let payload = {}
        if (scrapedFeaturedImage !== undefined && scrapedFeaturedImage) {
            payload = {
                ...others,
                Status: values.Status ? "Active" : "Inactive",
                IsVerified: values.IsVerified ? "Yes" : "No",
                FeaturedImage: scrapedFeaturedImage

            }
        } else {
            payload = {
                ...others,
                Status: values.Status ? "Active" : "Inactive",
                IsVerified: values.IsVerified ? "Yes" : "No",
                PublishedOn: values.PublishedOn ? moment(values.PublishedOn).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD"),
                NextReviewDate: values.NextReviewDate ? moment(values.NextReviewDate).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD"),
            }
        }
        const { Tags, Categories, SeoDescription, SeoTitle, ...rest } = payload
        tagArrToRemove = oldTags?.filter((item) => !Tags.includes(item))
        categoryArrToRemove = oldCategories?.filter((item) => !Categories.includes(item))

        let formData = new FormData();
        formData.append("Tags", JSON.stringify([...new Set(Tags)]))
        formData.append("Categories", JSON.stringify([...new Set(Categories)]))
        formData.append("SeoTitle", SeoTitle || payload.Title)
        formData.append("SeoDescription", SeoDescription || payload.Summary || payload.Title)
        // formData.append("ModifiedOn", moment().format())
        Object.keys(rest).forEach((key, index) => {
            formData.append(key, rest[key]);
        });
        const { success, data, message } = !!!id ? await new APIServices(`post`).post(formData) : await new APIServices(`post/${id}`).put(formData)
        if (success) {
            insertTags([...new Set(Tags)], [...new Set(tagArrToRemove)])
            insertCategories([...new Set(Categories)], [...new Set(categoryArrToRemove)])
            setSubmitting(false);
            resetForm();
            emitSuccessToast(message)
            navigate(`/post/view/${data.Slug}`, { replace: true })

        }
    }

    const getFormSelectFieldValues = async () => {
        const [pageTypeObj, tagObj] = await Promise.all([
            new APIServices("setting/get/page-type").get(),
            new APIServices("tag/list").post(),
            new APIServices("category/list").post(),

        ]);
        if (pageTypeObj.success) {
            const { SettingValue } = pageTypeObj.data
            let options = JSON.parse(SettingValue)?.map((item) => ({ value: item, label: item.charAt(0).toUpperCase() + item.substring(1) }))
            setPageTypeOptions(options)
        }
        if (tagObj.success && !!tagObj?.data?.length) {
            let options = tagObj?.data?.map((item) => ({ value: item.Title, label: item.Title }))
            setTagListOptioins(options)
        }

    }
    const handleTitleChange = (e, field, form) => {
        form.setFieldValue(field.name, e.target.value)
        if (toggleSlug) {
            form.setFieldValue("Slug", slugify(e.target.value, { lower: true }));
        }

    }

    const handleToggleSlug = () => {
        setToggleSlug(!toggleSlug)
    }

    const insertTags = async (tagArr, tagArrToRemove = []) => {
        let tagTitleList = [];
        let tagToInsert = [];
        let alreadyHaveTag = [];
        const { data, success } = await new APIServices("tag/list").post()
        if (success) {
            tagTitleList = data.map((item) => item.Title)

            tagArr.forEach((item) => {
                if (tagTitleList.includes(item)) {
                    alreadyHaveTag.push(item)
                } else {
                    tagToInsert.push(item)
                }
            })

        }

        if (tagToInsert.length) {
            const promises = tagToInsert.map(async item => {
                let payload = {
                    Status: "Inactive",
                    Title: item,
                    Slug: slugify(item, { lower: true }),
                    Image: "",
                    SpecialValue: "",
                    TagType: null,
                    Detail: "",
                    SeoTitle: "",
                    SeoDescription: "",
                    PostIds: [postData?._id]
                }
                return await new APIServices("tag").post(payload)
            }
            )
            await Promise.all(promises)
        }

        if (alreadyHaveTag.length) {
            const promises = alreadyHaveTag.map(async item => {
                return await new APIServices("tag/postIds/add").post({ tagTitle: item, postId: postData?._id })
            }
            )
            await Promise.all(promises)
        }
        if (tagArrToRemove.length) {
            const promises = tagArrToRemove.map(async item => {
                return await new APIServices("tag/postIds/remove").post({ tagTitle: item, postId: postData?._id })
            }
            )
            await Promise.all(promises)
        }


    }

    const insertCategories = async (categoryArr, categoryArrToRemove = []) => {
        let categoryTitleList = [];
        let categoryToInsert = [];
        let alreadyHaveCategory = [];
        const { data, success } = await new APIServices("category/list").post()
        if (success) {
            categoryTitleList = data.map((item) => item.Title)

            categoryArr.forEach((item) => {
                if (categoryTitleList.includes(item)) {
                    alreadyHaveCategory.push(item)
                } else {
                    categoryToInsert.push(item)
                }
            })

        }

        if (categoryToInsert.length) {
            const promises = categoryToInsert.map(async item => {
                let payload = {
                    Status: "Inactive",
                    Title: item,
                    Slug: slugify(item, { lower: true }),
                    SVGImage: "",
                    ShortTitle: "",
                    OrderBy: null,
                    SeoTitle: "",
                    SeoDescription: "",
                    PostIds: [postData?._id]
                }
                return await new APIServices("category").post(payload)
            }
            )
            await Promise.all(promises)
        }

        if (alreadyHaveCategory.length) {
            const promises = alreadyHaveCategory.map(async item => {
                return await new APIServices("category/postIds/add").post({ categoryTitle: item, postId: postData?._id })
            }
            )
            await Promise.all(promises)
        }
        if (categoryArrToRemove.length) {
            const promises = categoryArrToRemove.map(async item => {
                return await new APIServices("category/postIds/remove").post({ categoryTitle: item, postId: postData?._id })
            }
            )
            await Promise.all(promises)
        }


    }

    const handleSelectMulti = (selectedOption, fieldName, setFieldValue) => {
        let val = selectedOption.map((opt) => {
            let str = opt.value
            return str[0].toUpperCase() + str.slice(1)
        })
        setFieldValue(fieldName, val)
    }
    const handleSelectSingle = (selectedOption, fieldName, setFieldValue) => {
        setFieldValue(fieldName, selectedOption.value)
    }

    // Function to handle scraping
    const handleScrap = async (formik) => {
        const url = formik.values.Url;

        const { success, data } = await new APIServices(`post/get/scrap`).post({ url: url });
        if (success) {

            const { title, detail, featuredImage } = data

            // Update scrapedFeaturedImage state
            setScrapedFeaturedImage(featuredImage || "");
            // Update form values with the scraped data
            formik.setValues({
                ...formik.values,
                Title: title || "",
                Slug: slugify(url),
                Detail: detail || "",
            });

        }
    };
    // Function to handle scraping
    const handleVideoScrap = async (formik) => {
        const video = formik.values.Video;
        const { success, data } = await new APIServices(`post/get-video/scrap`).post({ video: video });
        if (success) {
            const { title, description, featuredImage } = data
            // Update scrapedFeaturedImage state
            setScrapedFeaturedImage(featuredImage || "");
            // Update form values with the scraped data
            formik.setValues({
                ...formik.values,
                Title: title || "",
                Slug: slugify(video),
                Detail: description || "",
            });

        }
    };

    return (
        <section className="py-16 bg-gray-100 dark:bg-gray-800">
            <div className="max-w-4xl px-4 mx-auto ">
                <Formik
                    initialValues={initialValues}
                    validationSchema={PostValidationSchema}
                    onSubmit={handleFormikFormSubmit}
                    enableReinitialize
                >
                    {
                        formik => (
                            <Form onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    e.preventDefault()
                                }
                            }}>
                                <h2 className="mb-6 text-xl font-bold leading-6 text-gray-900 dark:text-gray-300">{Pagetype.charAt(0).toUpperCase() + Pagetype.substring(1)} Details
                                </h2>
                                {/**/}
                                {Pagetype === "others-post" && (
                                    <>
                                        <div className="flex items-center gap-4">
                                            <div className="p-6 mb-4  w-[80%] bg-white rounded-md shadow">
                                                <FormikTextField type="url" label="Add News Url" name="Url" formik={formik} />
                                            </div>

                                            {/* Scrap */}
                                            <div className="w-[20%]">
                                                <button type="button"
                                                    onClick={() => handleScrap(formik)}
                                                    className="inline-block px-6 py-3 bg-blue-500 text-white font-medium text-sm leading-tight uppercase rounded shadow-md hover:bg-blue-600">Scrap</button>
                                            </div>

                                        </div>
                                    </>
                                )}
                                {(Pagetype === "others-video" || Pagetype === "video") && (
                                    <>
                                        <div className="flex items-center gap-4">
                                            <div className="p-6 mb-4 w-[80%] bg-white rounded-md shadow">
                                                <FormikTextField type="url" label="Add Video Url" name="Video" formik={formik} />
                                            </div>
                                            {/* Scrap */}
                                            <div className="w-[20%]">
                                                <button type="button"
                                                    onClick={() => handleVideoScrap(formik)}
                                                    className="inline-block px-6 py-3 bg-blue-500 text-white font-medium text-sm leading-tight uppercase rounded shadow-md hover:bg-blue-600">Scrap</button>
                                            </div>
                                        </div>
                                    </>
                                )}

                                <div className="grid grid-cols-[70%,1fr] gap-4">

                                    <div>
                                        <div className="p-6 mb-6 bg-white rounded-md shadow dark:border-gray-800 dark:bg-gray-900">
                                            {/* Title  */}
                                            <div className='mb-6'>
                                                <label className="block mb-2 text-sm font-medium dark:text-gray-400" htmlFor="">
                                                    {t("Title*")} *
                                                </label>
                                                <Field type="text" name="Title" placeholder="Enter a Title" >
                                                    {({ field, form, meta }) => {
                                                        return <input
                                                            name="Title"
                                                            type="Text"
                                                            placeholder="Enter a Title"
                                                            value={field.value || ""}
                                                            onChange={e => handleTitleChange(e, field, form)}
                                                            onBlur={form.handleBlur}
                                                            className={`block w-full px-4 py-3 text-sm placeholder-gray-500 bg-white border rounded dark:text-gray-400 dark:placeholder-gray-500 dark:border-gray-800 dark:bg-gray-800 ${formik.touched.Title && formik.errors.Title ? "border-red-600" : null}`}
                                                        />
                                                    }}
                                                </Field>
                                                <ErrorMessage name="Title" component={TextError} />
                                            </div>

                                            <div className='mb-6'>
                                                <label className="mb-2 text-sm font-medium dark:text-gray-400 flex justify-between items-end" htmlFor="">
                                                    {t("Slug*")}
                                                    <div>
                                                        <button type="button"
                                                            onClick={handleToggleSlug}
                                                            className="inline-block px-2 py-2 bg-blue-500 text-white font-medium text-xs leading-tight rounded shadow-md hover:bg-blue-600">{t("Change Slug")}
                                                        </button>
                                                    </div>
                                                </label>
                                                <Field type="text" name="Slug" placeholder="Enter a Title" >
                                                    {({ field, form, meta }) => {
                                                        return toggleSlug ? <input
                                                            name="Slug"
                                                            type="Text"
                                                            value={field.value}
                                                            readOnly
                                                            className={`block w-full px-4 py-3 text-sm placeholder-gray-500 bg-white border rounded dark:text-gray-400 dark:placeholder-gray-500 dark:border-gray-800 dark:bg-gray-800 ${formik.touched.Title && formik.errors.Title ? "border-red-600" : null}`}
                                                        /> : <input
                                                            name="Slug"
                                                            type="Text"
                                                            value={field.value || ""}
                                                            onChange={e => { form.setFieldValue("Slug", slugify(e.target.value, { lower: true })); form.setFieldValue(field.name, e.target.value) }}
                                                            onBlur={form.handleBlur}
                                                            className={`block w-full px-4 py-3 text-sm placeholder-gray-500 bg-white border rounded dark:text-gray-400 dark:placeholder-gray-500 dark:border-gray-800 dark:bg-gray-800 ${formik.touched.Title && formik.errors.Title ? "border-red-600" : null}`}
                                                        />
                                                    }}
                                                </Field>
                                                <ErrorMessage name="Title" component={TextError} />
                                            </div>
                                            <div className="mb-6">
                                                <FormikTextAreaField label={t("Summary")} name="Summary" formik={formik} />
                                            </div>
                                            <div className="mb-6">
                                                <FormikRichTextEditor label={t("Detail *")} name="Detail" />
                                            </div>
                                        </div>
                                        <div className="p-6 mb-4 bg-white rounded-md shadow">
                                            <div className="mb-6">
                                                <FormikTextField label="Seo Title" name="SeoTitle" type="text" formik={formik} />
                                            </div>
                                            <div className="">
                                                <FormikTextAreaField label="Seo Description" name="SeoDescription" formik={formik} />
                                            </div>

                                        </div>
                                    </div>
                                    <div className="">
                                        <div className="p-6 mb-4 bg-white rounded-md shadow">
                                            <FormikSwitchField label="Status" name="Status" formik={formik} />
                                        </div>
                                        <div className="p-6 mb-4 bg-white rounded-md shadow">
                                            <FormikSwitchField label="Is Verified" name="IsVerified" formik={formik} />
                                        </div>
                                        <div className="p-6 mb-4 bg-white rounded-md shadow">
                                            {
                                                !!!id && <div className="mb-4">
                                                    {/* <FormikDateTimePickerField label="Published On *" name="PublishedOn" formik={formik} /> */}
                                                    <FormikDateTimePickerField
                                                        label={t("Published On*")}
                                                        name="PublishedOn"
                                                        type="date"
                                                        formik={formik}
                                                    />
                                                </div>
                                            }

                                            <div className="mb-4">
                                                {/* <FormikDateField label="Next Review Date *" name="NextReviewDate" formik={formik} minDate={new Date()} /> */}
                                                <FormikDateTimePickerField
                                                    label={t("Next Review Date*")}
                                                    name="NextReviewDate"
                                                    type="date"
                                                    formik={formik}
                                                />
                                            </div>

                                            <div className="mb-4">
                                                <FormikSelectSingleField label={t("Page Type*")} name="PageType" options={pageTypeOptions} handleSelectSingle={handleSelectSingle} />
                                            </div>
                                            <FormikNumberField label={t("Order By*")} name="OrderBy" formik={formik} />
                                        </div>
                                        <div className="p-6 mb-4 bg-white rounded-md shadow">
                                            <FormikImageField label="Featured Image" name="FeaturedImage" />
                                        </div>
                                        <div className="p-6 mb-4 bg-white rounded-md shadow">
                                            <FormikCreatableSelect label={t("Tags*")} name="Tags" options={tagListOptions} handleSelectOption={handleSelectMulti} />
                                        </div>
                                        <div className="p-6 mb-4 bg-white rounded-md shadow">
                                            <div className="mb-4">
                                                <FormikTextField type="text" label="Redirect To" name="RedirectTo" formik={formik} />
                                            </div>
                                            <FormikTextAreaField label="Remark" name="Remark" formik={formik} />


                                        </div>
                                    </div>


                                </div>

                                {/* Submit */}
                                <div className="mt-7">
                                    <div className="flex justify-start space-x-2">
                                        <button type="submit"
                                            className="inline-block px-6 py-2.5 bg-blue-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-600">{t("Save")}</button>
                                    </div>
                                </div>
                            </Form>
                        )
                    }
                </Formik>
            </div>
        </section >
    )
}

export default PostForm
