import * as yup from 'yup';
const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/gif", "image/png", "image/webp"];
// const ChildSchema = yup.object().shape({
//     // Define validation for child schema properties here
//     FullName: yup.string().required('Required!')
// });
let HealthCentreValidationSchema = yup.object().shape({
    Name: yup.string().required('Required !'),
    ContactNo: yup.string().required('Required !'),
    Image: yup.mixed().notRequired()
        .nullable()
        .test('fileSize',
            'The file is too large', (value) => {
                if (!value) return true
                return !value || (value && value.size <= (1024 * 1024) * 2)
            })
        .test('fileFormat',
            'Unsupported File Format', (value) => !value || (value && SUPPORTED_FORMATS.includes(value.type))),
    // MainPerson: ChildSchema,
    // ResponsiblePerson : ChildSchema
})

export default HealthCentreValidationSchema