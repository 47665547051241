import * as yup from "yup";
const regex = "^[A-Za-z0-9_]{7,20}$";

let LocalBodyUserValidation = yup.object().shape({
  UserName: yup
    .string()
    .matches(regex, "Invalid username")
    .max(20, "Must be 20 characters or less")
    .required("Required !"),
  UserPass: yup.string().required("Required !"),
  UserType: yup.string().required("Required !"),
  Role: yup.string().required("Required !"),
});

export default LocalBodyUserValidation;
