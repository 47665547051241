import { login, logout } from "../features/auth/authSlice";
import { call, put } from 'redux-saga/effects'
import { post } from "../../httpServices"
import { setProfile } from "../features/user/userSlice";

export function* doLogin({ type, payload }) {
    const { setFieldError, ...rest } = payload
    try {
        const { data } = yield call(post, rest)
        yield put(login(data))
        if (data.message === "User not found") {
            setFieldError("Email", "The email you entered is incorrect.")
        }
        if (data.message === "Incorrect Password") {
            setFieldError("UserPass", "The password you entered is incorrect. ")
        }

    } catch (error) {
      
        yield put(login({ result: false }));

    }
}

export function* doLogout({ type, payload }) {
    try {
        const { data } = yield call(post, payload)
        yield put(logout(data));
        yield put(setProfile({ data: null }));
    } catch (error) {
  
        yield put(setProfile({ data: null }));
        yield put(login({ result: true }));
    }

}