import { ErrorMessage, Field } from "formik";
import React, { useState } from "react";
import PasswordToggler from "../PasswordToggler";
import { TextError } from "../TextError";
import { useTranslation } from "react-i18next";

const FormikTextField = ({
  label,
  focus = false,
  name,
  type = "text",
  formik,
  readOnly = false,
  ...props
}) => {
  const { t } = useTranslation();
  const [fieldType, setFieldType] = useState(type);
  const fieldValue = formik.values[name] || '';
  return (
    <div>
      <label
        className="block mb-2 text-sm font-medium dark:text-gray-400"
        htmlFor={label}
      >
        {t(label)}
      </label>
      <div className="relative">
        <Field
          {...props}
          autoComplete="off"
          type={fieldType}
          name={name}
          autoFocus={focus}
          readOnly={readOnly}
          value={fieldValue} 
          className={`block w-full px-4 py-2 text-sm placeholder-gray-500 bg-white border rounded dark:text-gray-400 dark:placeholder-gray-500 dark:border-gray-800 dark:bg-gray-800 ${
            formik?.touched?.[name] && formik?.errors?.[name]
              ? "border-red-600"
              : null
          }`}
        />
        {type === "password" && (
          <PasswordToggler
            val={formik.values?.[name]}
            changeFieldType={setFieldType}
          />
        )}
      </div>
      <ErrorMessage name={name} component={TextError} />
    </div>
  );
};

export default FormikTextField;
