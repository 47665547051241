// import { ADToBS } from "bikram-sambat-js";
// import { isAfter } from 'date-fns';
import * as yup from 'yup';
let BabyValidationSchema = yup.object().shape({
  Name: yup.string().required('Required !'),
  // BabyBornDate: yup
  //     .date()
  //     .max(ADToBS(new Date()), 'Date must be in the past!')
  //     .transform((value, originalValue) => {
  //       if (originalValue) {
  //         console.log("originalvalue",originalValue)
  //         console.log("first",ADToBS(new Date()))
  //         if (isAfter(originalValue, ADToBS(new Date()))) {
  //           return ADToBS(new Date()); // Replace with desired behavior, e.g. throw error or set default
  //         }
  //       }
  //       return value;
  //     }),  
  Gender: yup.string().required('Required !'),
  BabyBornIn: yup.string().required('Required !'),
  Birth: yup.string().required('Required !'),
  ExactBornTime: yup.string().required('Required !'),
});


export default BabyValidationSchema