import { ErrorMessage } from 'formik'
import React, { useEffect, useRef, useState } from 'react'
import { TextError } from './TextError'

const SingleFileUpload = ({ field, form, meta }) => {
    const fileRef = useRef(null)
    const [uploadedFile, setuploadedFile] = useState(null)
    const [preview, setPreview] = useState("")

    useEffect(() => {
        if (!!field.value && typeof (field.value) === "string") {
            form.setFieldValue(field.name, "")
            setPreview(`${process.env.REACT_APP_IMAGE_BASE_URL}${field.value}`)
        }
        //eslint-disable-next-line
    }, [field.value])

    useEffect(() => {
        if (!uploadedFile)
            return

        // create the preview
        const objectUrl = URL.createObjectURL(uploadedFile)
        setPreview(objectUrl)
        return () => URL.revokeObjectURL(objectUrl)
    }, [uploadedFile])

    const handleFileChange = (e) => {
        setuploadedFile(e.target.files[0])
        form.setFieldValue(field.name, e.target.files[0])
    }

    const handleUpload = () => {
        fileRef.current.click()
    }


    const handleCancel = (e) => {
        e.preventDefault()
        setuploadedFile(null)
        setPreview("")
        form.setFieldValue(field.name, "")
    }
    return (
        <div>
            <div className='relative w-64 shadow rounded overflow-hidden'>
                {(uploadedFile || preview) && <img src={preview} alt="" className="w-full" />}
                {uploadedFile &&
                    <button onClick={handleCancel}
                        className="absolute top-2 right-2 text-white bg-red-500 hover:bg-red-700 rounded p-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16"><path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"></path></svg>
                    </button>
                }
            </div>
            <div className="flex w-full bg-grey-lighter mt-3 relative" >
                <label onClick={handleUpload} className="w-64 flex flex-col items-center px-4 py-6 bg-white text-blue rounded-lg shadow-lg  uppercase border border-blue-300 cursor-pointer hover:bg-blue-300 hover:text-white">
                    <svg className="w-8 h-8" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                        <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
                    </svg>
                    <span className="mt-2 text-base leading-normal">{uploadedFile ? 'Replace Image' : 'Upload Image'}</span>

                </label>
                <input
                    name={field.name}
                    ref={fileRef}
                    type="file"
                    onChange={handleFileChange}
                    onBlur={form.handleBlur}
                    className={`hidden`}
                />
            </div>
            <ErrorMessage name={field.name} component={TextError} />
        </div>
    )
}

export default SingleFileUpload