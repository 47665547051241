import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import APIServices from '../../../httpServices/httpServices';
import FormikTextField from '../../common/form/FormikTextField';
import { emitErrorToast, emitSuccessToast } from '../../common/toast/ToastContainer';

import FormikTextAreaField from '../../common/form/FormikTextAreaField';
// import FormikDateField from '../../common/form/FormikDateField';
import { useNavigate, useParams } from 'react-router-dom';
// import FormikDateTimePickerField from '../../common/form/FormikDateTimePickerField';
import { useTranslation } from 'react-i18next';

const EmergencyForm = ({ data,  emergencyId }) => {
    const { t } = useTranslation()
    const {id}= useParams();
    const navigate = useNavigate()
    const [initialValues, setInitialValues] = useState({
        ContactName:"",
       ContactMobile: "",
        ContactPhone:"",
        Purpose:"",
            })
    useEffect(() => {
        if (data)
            setInitialValues((prev) => ({
                ...prev,
                ...data,
            }));
    }, [data]);
    const handleFormikFormSubmit = async (values, actions) => {
        let { setSubmitting, resetForm } = actions
        let payload = {
            ...values,
        }
      
        const { success,data,  message } = !!emergencyId ?  await new APIServices(`emergency/${emergencyId}`).put(payload):await new APIServices(`emergency/add/${id}`).post(payload)
        if (success) {
            setSubmitting(false);
            resetForm();
            emitSuccessToast(message)
            navigate(`/appuser/view/${data.AppUserId}`, { replace: true })
     
        } else {
            emitErrorToast(message)   
        }

    }

    return (
        <section className="py-16 bg-gray-100 dark:bg-gray-800">
            <div className="max-w-4xl px-4 mx-auto ">
                <Formik
                    initialValues={initialValues}
                    // validationSchema={PostValidationSchema}
                    onSubmit={handleFormikFormSubmit}
                    enableReinitialize
                >
                    {
                formik => (
                            <Form>
                                <div>
                                    <h3 className="mb-2 text-base font-medium leading-6 text-gray-900 dark:text-gray-300">{t("Emergency Information")}
                                    </h3>
                                    <div className="p-6 mb-4 bg-white rounded-md shadow dark:border-gray-800 dark:bg-gray-900">
                                        <FormikTextField label={t("Name")} name="ContactName"  formik={formik} />
                                        <FormikTextField label={t("Mobile Number")} name="ContactMobile" type="Number" formik={formik} />
                                        <FormikTextField label={t("Phone Number")} name="ContactPhone" type="Number" formik={formik} />
                                        <FormikTextAreaField label={t("Purpose")} name="Purpose" formik={formik} />
                                </div>
                                {/* Submit */}
                                <div className="mt-7">
                                    <div className="flex justify-start space-x-2">
                                        <button type="submit"
                                            className="inline-block px-6 py-2.5 bg-blue-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-600">{t("Save")}</button>
                                    </div>
                                </div>
                                </div>
                            </Form>
                    )
                    }
                </Formik>
            </div>
        </section >
    )
}
 export default EmergencyForm;
