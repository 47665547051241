import React from 'react'
import PostForm from '../../container/post/PostForm'

const AddPost = () => {
    return (
        <div><PostForm /></div>
    )
}

export default AddPost
