import React from "react";
import { ErrorMessage, Field } from "formik";
import Select from "react-select";
import { TextError } from "../TextError";
import { useTranslation } from "react-i18next";

const FormikSelectSingleField = ({
  name,
  label,
  options,
  handleSelectSingle,
}) => {
  const { t } = useTranslation();

  return (
    <div>
      <label
        className="block mb-2 text-sm font-medium dark:text-gray-400"
        htmlFor=""
      >
        {t(label)}
      </label>
      <Field type="text" name={name}>
        {({ field, form, meta }) => {
          return !!field.value ? (
            <Select
              name={name}
              options={options}
              classNamePrefix="select"
              onBlur={form.handleBlur}
              value={
                field.value
                  ? options?.filter((item) => item?.value === field?.value)
                  : ""
              }
              onChange={(selectedOption) =>
                handleSelectSingle(
                  selectedOption,
                  field.name,
                  form.setFieldValue,
                  form
                )
              }
              className={`${
                meta.touched && meta.error ? "error-select" : null
              }`}
            />
          ) : (
            <Select
              name={name}
              options={options}
              classNamePrefix="select"
              onBlur={form.handleBlur}
              onChange={(selectedOption) =>
                handleSelectSingle(
                  selectedOption,
                  field.name,
                  form.setFieldValue,
                  form
                )
              }
              className={`${
                meta.touched && meta.error ? "error-select" : null
              }`}
            />
          );
        }}
      </Field>
      <ErrorMessage name={name} component={TextError} />
    </div>
  );
};

export default FormikSelectSingleField;
